import React, { Component } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import CustomScrollBar from './CustomScrollBar.web';
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData } from "../../framework/src/Utilities";
import { IBlock } from "../../framework/src/IBlock";
import { I18n } from "../../components/src/languageJson/i18n";
import {
    userImgIcon, user, logout, payment, Back, BackArabic, inventory, organ, Survey
    , Appoin, help, peopl, role, service, notifi, desk, helpPlusImage, organ_selected, Appoin_selected, service_selected, peopl_selected, desk_unselected
} from "./imgsrc"
import { apiCalling } from './constant';

export interface Props  {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
    // Customizable Area Start
    selectedItem: string,
    userName: string,
    userCompany: string,
    userProfileImg: string,
    fetchUserProfileCallId:string,
    isActive:boolean,
    currentLanguage: string
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }

class AppNavigation extends Component<Props, S, SS> {
    logoutApiCallId : string = ""
    subScribedMessages: string[];
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          // Customizable Area Start
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionResponseMessage),
          // Customizable Area End
        ];
    
        this.state = {
          // Customizable Area Start
          selectedItem: this.getSelectedItemFromStorage(),
          userName: '',
          userCompany: '',
          userProfileImg: '',
          fetchUserProfileCallId:'',
          isActive:false,
          currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    
        // Customizable Area Start
        // Customizable Area End
      }

    getSelectedItemFromStorage() {
        return sessionStorage.getItem('selectedItem') || 'Dashboard';
    }

    handleRemoveSelectedItem = () => {
        sessionStorage.removeItem('selectedItem');
    }; 

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
        
              if(apiRequestCallId == this.state.fetchUserProfileCallId && 
                responseJson !== undefined &&
                responseJson.data
              ) {
                localStorage.setItem('merchantName', responseJson.data.attributes?.user_name);
                localStorage.setItem('merchantImg', Array.isArray(responseJson.data.attributes?.photo_image) && responseJson.data.attributes?.photo_image.length > 0
                && responseJson.data.attributes?.photo_image[0]?.url)
                this.setState({
                    userName: responseJson.data.attributes?.user_name,
                    userCompany: responseJson.data.attributes?.business_name,
                    userProfileImg: 
                        Array.isArray(responseJson.data.attributes?.photo_image) && responseJson.data.attributes?.photo_image.length > 0
                        ? responseJson.data.attributes?.photo_image[0]?.url || userImgIcon
                        : userImgIcon
                });
              }

              if(apiRequestCallId === this.logoutApiCallId) {
                 if(responseJson && responseJson.message) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                 }
              }
        }
      }

    async fetchUserProfile(token:string) {
        try {
            const header = {
                token: token as string,
                "Content-Type": "application/json",
                Accept: "application/json",
            };
    
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.setState({fetchUserProfileCallId: requestMessage.messageId})
    
            // Set endpoint
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'bx_block_profile/profile/show'
            );
    
            // Set headers
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
            // Set HTTP method
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );
    
            // Send the request
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
            console.error('Failed to load user profile:', error);
        }
    }

    logOutUser = async () => {
        const tokenvalue = await getStorageData('token')
        const header = {
            'Content-Type': 'application/json',
            token: tokenvalue,
        }

        this.logoutApiCallId = apiCalling({
            header: JSON.stringify(header),
            method: "POST",
            endPoint:"bx_block_login/logout" ,
        })
    } 
    

    handleMenuItemClick = (item: string) => {
        this.setState({ selectedItem: item });
        sessionStorage.setItem('selectedItem', item);
    };

    async componentDidMount() {
        let tokenvalue = await getStorageData('token')
        this.fetchUserProfile(tokenvalue);
        const appNav = document.querySelector(".app-navigation"); 
        if (appNav && appNav.parentElement) {
            const siblings = Array.from(appNav.parentElement.children).filter(
                (child) => child !== appNav
            );
            siblings.forEach((sibling) => {
                if (sibling instanceof HTMLElement) { 
                    sibling.style.marginLeft = "325px"; 
                }
            });
        }
    }
    
    componentWillUnmount() {
        const appNav = document.querySelector(".app-navigation");
        if (appNav && appNav.parentElement) {
            const siblings = Array.from(appNav.parentElement.children).filter(
                (child) => child !== appNav
            );
            siblings.forEach((sibling) => {
                if (sibling instanceof HTMLElement) { 
                    sibling.style.marginLeft = "0"; 
                }
            });
        }
    }

    getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
        return this.state.currentLanguage === "ar" ? firstValue : secondValue;
    };

    render() {
        return (
            <Box className="app-navigation" sx={{}} style={{position:'fixed', zIndex:999, width: 325, height: '100vh', backgroundColor: '#fff', paddingTop: 20, borderRight: '1px solid #E2E8F0', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"') }}>
                <Box style={{ 
                    padding: 20,
                    zIndex:999, width:300, height:'100vh'
                }}>
                    
                    <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 16, borderBottom: '1px solid #E2E8F0', justifyContent:this.getValueBasedOnLanguage('end', 'normal')}}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>

                            <img src={helpPlusImage} alt="Help Plus"
                                style={{
                                    width: '184px',
                                    height: '91px'
                                }} />
                        </Typography>
                    </Box>
                    {/* Menu Items */}
                    <List>
                    <ListItem button style={{ padding: '8px 12px 8px 12px',
                        flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                        textAlign: this.getValueBasedOnLanguage('right', 'left'),
                     }}>
                        <img src={
                            this.state.currentLanguage == 'ar' ? BackArabic : Back
                        } alt=""
                            style={{ width: '24px',
                                paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                             }} />
                        <ListItemText style={{fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left'),}} primary={I18n.t("back")} />
                    </ListItem>
                    </List>
                    <CustomScrollBar height="calc(100vh - 196px)">
                        <List>
                            <List style={{ borderBottom: '1px solid #E2E8F0' }}>
                                <ListItem 
                                    button
                                    style={{
                                        color: this.state.selectedItem === 'Dashboard' ? '#527FE2' : 'inherit', 
                                        fontFamily: '"Poppins", sans-serif',
                                        flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                        textAlign: this.getValueBasedOnLanguage('right', 'left'),
                                    }}
                                    onClick={() => this.handleMenuItemClick('Dashboard')} 
                                    component={Link} to="/dashboard"
                                >
                                    <img src={this.state.selectedItem === 'Dashboard' ? desk : desk_unselected} alt=""
                                        style={{ width: '24px',  
                                            paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                            paddingLeft: this.getValueBasedOnLanguage('15px', '0'), }} />
                                    <ListItemText 
                                        primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Dashboard' ? 1000 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left'),} }}
                                        primary={I18n.t("dashboard")}
                                        
                                    />
                                </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Payment Admin' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Payment Admin' ? '"Poppins", sans-serif' : 'inherit',
                                    flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                    textAlign: this.getValueBasedOnLanguage('right', 'left'),
                                }}
                                onClick={() => this.handleMenuItemClick('Payment Admin')} 
                            >
                                <img src={payment} alt=""
                                    style={{ width: '24px',
                                        paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                        paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                     }} />

                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Payment Admin' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("paymentAdmin")} />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Email and Notification' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Email and Notification' ? '"Poppins", sans-serif' : 'inherit',
                                    flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                    textAlign: this.getValueBasedOnLanguage('right', 'left'),
                                }}
                                onClick={() => this.handleMenuItemClick('Email and Notification')} 
                            >
                                <img src={notifi} alt=""
                                    style={{ width: '24px',
                                        paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                        paddingLeft: this.getValueBasedOnLanguage('15px', '0')
                                     }} />
                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Email and Notification' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("emilAndNotification")} />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Role and Permission' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Role and Permission' ? '"Poppins", sans-serif' : 'inherit',
                                    flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                    textAlign: this.getValueBasedOnLanguage('right', 'left'),
                                }}
                                onClick={() => this.handleMenuItemClick('Role and Permission')} 
                            >
                                <img src={role} alt=""
                                    style={{ width: '24px',
                                        paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                        paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                     }} />
                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Role and Permission' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("roldeAndPermission")} />
                            </ListItem>
                        </List>



                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Service Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Service Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Service Management')}
                            component={Link} to="/Catalogue"
                        >
                            <img src={this.state.selectedItem === 'Service Management' ? service_selected : service} alt=""
                                style={{ width: '24px', 
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                 }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Service Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("serviceMan")} />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'People Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'People Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('People Management')}
                            component={Link} to="/AccountGroups"
                        >
                            <img src={this.state.selectedItem === 'People Management' ? peopl_selected : peopl} alt=""
                                style={{ width: '24px', 
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'People Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("people")} />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Request Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Request Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Request Management')}
                            component={Link} to="/RequestManagement"
                        >
                            <img src={this.state.selectedItem === 'Request Management' ? peopl_selected : peopl} alt=""
                                style={{ width: '24px',  paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Request Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("request")} />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Help AI Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Help AI Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Help AI Management')}
                        >
                            <img src={help} alt=""
                                style={{ width: '24px',
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                 }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Help AI Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("helpAI")} />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Survey Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Survey Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Survey Management')}
                        >
                            <img src={Survey} alt=""
                                style={{ width: '24px',
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Survey Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("survey")} />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Appointment Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Appointment Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Appointment Management')}
                            component={Link} to="/AppointmentManagement"
                        >
                            <img src={this.state.selectedItem === 'Appointment Management' ? Appoin_selected : Appoin} alt=""
                                style={{ width: '24px', 
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                 }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Appointment Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("appointment")}  />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Organization Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Organization Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Organization Management')}
                            component={Link} to="/OrganisationHierarchy"
                        >
                            <img src={this.state.selectedItem === 'Organization Management' ? organ_selected : organ} alt=""
                                style={{ width: '24px',
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Organization Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("organization")} />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Inventory Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Inventory Management' ? '"Poppins", sans-serif' : 'inherit',
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                            onClick={() => this.handleMenuItemClick('Inventory Management')}
                        >
                            <img src={inventory} alt=""
                                style={{ width: '24px', 
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Inventory Management' ? 700 : 'normal', fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), textAlign: this.getValueBasedOnLanguage('right', 'left') } }} primary={I18n.t("inventory")} />
                        </ListItem>

                        <ListItem button onClick={this.logOutUser}
                            style={{
                                flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                                textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            }}
                        >
                            <img src={logout} alt=""
                                style={{ width: '24px', 
                                    paddingRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    paddingLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                 }} />
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"') } }} primary={I18n.t("logout")} style={{ color: '#ff0000', textAlign: this.getValueBasedOnLanguage('right', 'left') }} />
                        </ListItem>
                        </List>

                    {/* User Profile */}
                    <Link 
                    onClick={() => {
                        this.handleRemoveSelectedItem();
                        this.handleMenuItemClick('Profile')
                    }} to="/EducationalUserProfile" 
                    style={{
                        textDecoration:"none",
                        color:"inherit",
                        display:'flex',
                        justifyContent: this.getValueBasedOnLanguage('flex-end', 'flex-start'), 
                        }}>
                        <Box style={{ display: 'flex', alignItems: 'center', padding: 16, position: 'absolute', cursor:'pointer', textDecoration:"none", color:"inherit",
                            flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                            textAlign: this.getValueBasedOnLanguage('right', 'left'),
                         }}>
                            <Box
                                style={{
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    overflow: 'hidden', 
                                    backgroundColor: '#f0f0f0',
                                    marginRight: this.getValueBasedOnLanguage('0', '15px'), 
                                    marginLeft: this.getValueBasedOnLanguage('15px', '0'), 
                                }}
                            >
                                <img
                                    src={this.state.userProfileImg || userImgIcon}
                                    alt="User Profile"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                        
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" style={{fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), fontWeight: 'bold', color: this.state.selectedItem === 'Profile' ? '#527FE2' : 'inherit', textAlign: this.getValueBasedOnLanguage('right', 'left')}}>{this.state.userName || ''}</Typography>
                                <Typography variant="body2" color="textSecondary" style={{fontFamily: this.getValueBasedOnLanguage('"Noto Sans Arabic", "sans-serif"', '"Poppins", "sans-serif"'), color: this.state.selectedItem === 'Profile' ? '#527FE2' : '', textAlign: this.getValueBasedOnLanguage('right', 'left')}}>{this.state.userCompany || ''}</Typography>
                            </Box>
                        </Box>
                    </Link>
                    </CustomScrollBar>
                </Box>
            </Box>
        );
    }
}

export default AppNavigation;