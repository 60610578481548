import React from "react";
// Customizable Area Start
import { Box, Button, Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow,
  Typography,Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem, Dialog } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { DeleteOutline, Edit, FilterList, SearchOutlined } from "@material-ui/icons";
import AppNavigation from '../../../components/src/AppNavigation.web';
import EditServiceModal from '../../../components/src/EditServiceModal.web';
import EditServiceSuccessModal from '../../../components/src/EditServiceSuccessModal.web';
import EditServiceFailModal from '../../../components/src/EditServiceFailModal.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { cancelIcon, checkMark, noData } from "../../accountgroups/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Pagination } from "@material-ui/lab";
import { calendarIcon } from "./assets";
import { getValueBasedOnLanguage as applyCSS, SearchField } from "../../../components/src/constant";
import DeleteModal from "../../../components/src/DeleteModal.web";
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  adornmentStyledCSS = {
    order: this.getValueBasedOnLanguage(1, 0),
    marginRight: this.getValueBasedOnLanguage(8, 0),
    marginLeft: this.getValueBasedOnLanguage(0, 8),
  };

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }

  renderTableContent = () => {
    if (this.state.isLoading) {
      return null; // or a loading spinner/component if necessary
    }
  
    if (this.state.arrayHolder.length <= 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} style={{ border: "none"}}>
            <NoData className="testingClass">
              <img src={noData} alt="no_data" />
              <Typography style={{fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")}} className="noEmpText">
                {applyCSS(this.state.messageInfo.msg_ar, this.state.messageInfo.msg_en)}
              </Typography>
            </NoData>
          </TableCell>
        </TableRow>
      );
    }
    return this.state.arrayHolder.map((row: any, index: any) => (
      <TableRow style={useStyles.tableRow} key={index}>
        <TableCellBody style={{ width: "16.6%" }} data-test-id={`name-${row.id}`}>
          {row.attributes.name}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {row.attributes.price}$
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {this.toDateFromMoment(row.attributes.created_at)}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {this.toDateFromMoment(row.attributes.updated_at)}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }} align="center">
          <Edit
            data-test-id={`edit-${row.id}`}
            onClick={() => this.editService(row.id)}
            style={{ color: "#527FE2", cursor:'pointer', marginRight: applyCSS(0,15) }} />
          <DeleteOutline
            data-test-id={`delete-${row.id}`}
            onClick={() => this.successDeleteOpen(row.id)}
            style={{ color: "#DC2626", cursor: "pointer", marginRight: applyCSS(15,0) }}
          />
        </TableCellBody>
      </TableRow>
    ));
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex', flexDirection: this.getValueBasedOnLanguage('row-reverse', "row") }}>
        <AppNavigation />
        <div className={this.getValueBasedOnLanguage("arabic-text", "default-text")} style={{
          flex:1, 
          backgroundColor:"#F1F5FC", 
          padding:'45px 20px', 
          paddingBottom:'0', 
          minHeight:'100vh',
          boxSizing:'border-box',
          marginRight:this.getValueBasedOnLanguage("325px", undefined)
          }}>
          <ThemeProvider theme={theme}>
          <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("serviceManagement")]}/>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom:"20px" }}>
              <div style={{ display: "flex", justifyContent:"center", width: "80%", borderRadius: "0.5rem", flexDirection: applyCSS("row-reverse","row") }}>
                  <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%",flexDirection: applyCSS("row-reverse","row")  }}>
                    <SearchField
                      type="search"
                      id="search"
                      data-test-id="searchinput"
                      onChange={this.handleSearchInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={this.adornmentStyledCSS}>
                            <SearchOutlined  style={{color:"#94A3B8"}}/>
                          </InputAdornment>
                        ),
                      
                      }}
                      placeholder={I18n.t("search")}
                      variant="outlined"
                      style={{ width: "100%",backgroundColor:"#FFFFFF", borderRadius: applyCSS("0px 25px 25px 0px", "25px 0 0 25px") }}
                    />
                  </div>
                  <Button 
                      onClick={this.openFilter} 
                      data-test-id="searchField" 
                      variant="outlined" 
                      style={{ 
                        fontSize: '18px', 
                        fontWeight: 700, 
                        textTransform: "none",
                        backgroundColor:"#527FE2",
                        borderRadius: applyCSS("25px 0 0 25px", "0 25px 25px 0") ,
                        fontFamily: applyCSS("Noto Sans Arabic", "Poppins")
                      }}
                    > 
                      <FilterList style={{color:"ffffff"}}/>
                   </Button>
              </div>
            </div>
            <CardBox className="addclassss" >
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "1.5rem", direction: applyCSS("rtl", "ltr") }}>
                <Table style={{ tableLayout: 'fixed', width: '100%' }} >

                  <TableHead>
                    <TableRow style={useStyles.tableRow}>
                      <TableCellHead style={{ borderRadius: applyCSS("0 0 24px 0", "0 0 0 24px") }}>{I18n.t("serviceName")}</TableCellHead>
                      <TableCellHead >{I18n.t("servicePrice")}</TableCellHead>
                      <TableCellHead  >{I18n.t("serviceCreationDate")}</TableCellHead>
                      <TableCellHead  >{I18n.t("serviceModificationDate")}</TableCellHead>
                      <TableCellHead  ></TableCellHead>
                    </TableRow>
                  </TableHead>
                </Table>
                <div className={this.state.arrayHolder.length > 0 ? "scrollBox" : ""}>
                  <Table>
                    <TableBody>
                      {this.renderTableContent()}
                    </TableBody>
                  </Table>
                </div>
              </div>
              {
                this.state.arrayHolder.length != 0 && (
                  <div className="setPagination">
                    <Pagination
                      count={Math.ceil(this.state.setCount / 12)}
                      boundaryCount={1}
                      siblingCount={1}
                      data-test-id='pagination_id'
                      page={this.state.page} 
                      onChange={this.handlePageChange} 
                    />
                    <div style={{ textAlign: applyCSS("start", 'end') }}>
                    <Typography className={`setCount ${applyCSS("leftAlignText", "rightAlignText")}`}>
                      {`${Math.min((this.state.page - 1) * 12 + 1, this.state.setCount)}-${Math.min(this.state.page * 12, this.state.setCount)} of ${this.state.setCount} ${I18n.t("resultsLength")}`}
                    </Typography>
                    </div>
                  </div>
                )
              }
            </CardBox>
            <Box style={{ display: "flex", justifyContent: "center", margin: "3rem" }}>
              <AddServiceBtn data-test-id="goToAddService" onClick={this.goToAddService}>
                {I18n.t("addNewServiceBtn")}
              </AddServiceBtn>
            </Box>
          </ThemeProvider>


          <FilterDialog className="setDynamic" dir={applyCSS("rtl", "ltr")} onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen}>
            <div className="mainFilter">
              <div>
                <Typography className="filterText">{I18n.t("filtersText")}</Typography>
              </div>
              <div className="innerFilter">
                <div>
                  <Typography data-test-id='clearTextTestId' className={`clearText ${this.state.isClearClicked ? "clicked" : ""}`} 
                  onClick={() => {
                    this.setState({ isClearClicked: true }, () => {
                      setTimeout(() => this.setState({ isClearClicked: false }), 150); 
                    });
                    this.clearFilter();
                  }}
                    >{I18n.t("clearAllText")}</Typography>
                </div>
                <div onClick={this.handleClose}>
                  <img src={cancelIcon} alt="cancel_icon" />
                </div>
              </div>
            </div>

            <AccordionBox className="setAccroedion">
              <Accordion key={1} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">{I18n.t("serviceCreationDate")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label=""
                        value={this.state.selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputAdornmentProps={{
                          position: 'end',
                        }}
                        InputProps={{
                          style: { width: '100%' },
                          // classes: {
                          //   root: 'outline_change'
                          // }
                        }}
                        keyboardIcon={<img src={calendarIcon} />}
                      />
                  </MuiPickersUtilsProvider>
                </AccordionDetails>
              </Accordion>

              <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">{I18n.t("serviceModificationDate")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label=""
                        value={this.state.selectedUpdateDate}
                        onChange={this.handleUpdateDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputAdornmentProps={{
                          position: 'end',
                        }}
                        InputProps={{
                          style: { width: '100%' },
                          // classes: {
                          //   root: 'outline_change'
                          // }
                        }}
                        keyboardIcon={<img src={calendarIcon} />}
                      />
                  </MuiPickersUtilsProvider>
                </AccordionDetails>
              </Accordion>

            </AccordionBox>

            <div className="setApplyBtn">
              <button data-test-id='applyFilterTestId' className={`applyBtn ${this.state.isApplyClicked ? "clicked" : ""}`} 
               onClick={() => {
                this.setState({ isApplyClicked: true }, () => {
                  setTimeout(() => this.setState({ isApplyClicked: false }), 150); 
                });
                this.applyFilter();
              }}>{I18n.t("applyBtn")}</button>
            </div>

          </FilterDialog>
        </div>

        <SuccessModal
          open={this.state.successUpdateDialog}
          handleClose={this.handleUpdateClose}
          msg_ar={I18n.t("deleteServiceModalMagHeader")}
          msg_en={I18n.t("deleteServiceModalMagHeader")}
          data-test-id="successServiceModal"
        />

        <DeleteModal
          open={this.state.successDialog}
          handleClose={this.successDelete}
          handleDelete={this.deleteEmployeeCall}
          msgTitle={I18n.t("deleteServiceMsgHeader")}
          data-test-id="deleteServiceModal"
        />

        <ErrorModal 
            open={this.state.errorModal}
            handleClose={this.closeErrorModal}
            msg_ar={I18n.t("serviceDeleteErrorMsg")}
            msg_en={I18n.t("serviceDeleteErrorMsg")}
            data-test-id="deleteServiceErrorModal"
        />

        <EditServiceModal
          open={this.state.isModalOpen}
          handleClose={this.handleCloseModal}
          serviceDetails={this.state.serviceDetails}
          onSave={this.handleSave}
          data-test-id="editServiceModal"
        />
        
        {
          this.state.isEditSuccessModalOpen && (
            <EditServiceSuccessModal
              open={this.state.isEditSuccessModalOpen}
              handleClose={this.handleEditSuccessClose}
            />
          )
        }

        {
          this.state.isEditFailModalOpen && (
            <EditServiceFailModal
              open={this.state.isEditFailModalOpen}
              handleClose={this.handleEditFailClose}
            />
          )
        }
        
      </div>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const useStyles = {
  tableRow: {
      margin: "5px 0 5px 0",
      height:'auto'
  }
}
const CardBox = styled(Card)({
   backgroundColor:"fffffff", border:"1 px solid #CBD5E1",
   width:"100%",margin:"auto"
   ,borderRadius:"1.5rem",
  "& .scrollBox": {
    overflowY: "auto",
    maxHeight: '450px',
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#527FE2',
      borderRadius: '10px',
      border: '2px solid transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '10px',
      width: '6px'
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    }, 
   },
   
   "@media (max-width:560px)": {
   overflow:"scroll",
   display:"flex",
   marginTop:"20px",
  },
  '& .setPagination':{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '10px',
    position: 'relative',
    width: "96%",
    direction: applyCSS("rtl", "ltr"),
    '@media (max-width: 600px)': {
        flexDirection: 'column',
      },
    '& .setCount': {
      position: 'absolute',
      top: '50%',
      color: '#64748B',
      transform: 'translateY(-50%)',
      direction: "ltr",
      '@media (max-width: 600px)': {
        position: 'static',
        marginTop: '10px',
        transform: 'none',
        textAlign: 'center'
      }
    },
    "& .MuiPagination-ul li:last-child": {
      transform: applyCSS("rotate(180deg)", "rotate(0deg)")
    },
    "& .MuiPagination-ul li:first-child": {
      transform: applyCSS("rotate(180deg)", "rotate(0deg)")
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      fontWeight: 700,
      fontSize: "12px",
      fontFamily: "Poppins",
      color: "#527FE2",
      textDecoration: "none",
      background: "white"
    },
    "& .MuiPaginationItem-page": {
      textDecoration: "underline",
      fontWeight: 400,
      fontSize: "12px",
      fontFamily: "Poppins",
      color: "black",
    },
    "& .rightAlignText": {
      right: "70px"
    },
    "& .leftAlignText": {
      left: "70px"
    },
  },
 })

const TableCellHead = styled(TableCell)({
  fontWeight: 700,
  border: "none",
  backgroundColor: "#527FE2",
  color: "ffffff",
  fontFamily: applyCSS("Noto Sans Arabic", "Poppins"),
  fontSize: "16px",
  padding: "20px 24px 20px 24px",
  minHeight: "64px"
})

const TableCellBody = styled(TableCell)({
 border:"none",
 minHeight: '44px',
 fontFamily: applyCSS("Noto Sans Arabic", "Poppins"),
 fontSize: "16px",
 padding:"20px 24px 20px 24px"
})

const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white", backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",padding: "10px 16px 10px 16px",
  },
  
});

const NoData = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  '& .noEmpText':{
    fontWeight: 700,
    textAlign: 'center',
    fontSize: "18px"
  }
});

const FilterDialog = styled(Dialog)({
  position: 'relative',
  '& .applyBtn':{
    color: 'white',
    minWidth: "150px",
    background: '#527FE2',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 30px',
    fontFamily: applyCSS("Noto Sans Arabic", "Poppins"),
    fontWeight: 700,
    fontSize : "16px",
    '&.clicked': {
      transform: 'scale(0.95)', 
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', 
    },
  },
  '& .setApplyBtn': {
    padding: '20px',
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  '& .innerFilter': {
    gap: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  '& .mainFilter':{
    borderBottom: '1px solid #dddad6',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    display: 'flex',
    },
  '& .filterText':{
    fontFamily: applyCSS("Noto Sans Arabic", "Poppins"),
    fontWeight: 700,
    fontSize : "24px"
  },
  '& .clearText': {
    fontFamily:applyCSS("Noto Sans Arabic", "Poppins"),
    textDecoration: 'underline',
    cursor: 'pointer',
    color:'#527FE2',
    fontWeight: 400,
    fontSize: "16px",
    '&.clicked': {
      transform: 'scale(0.95)', 
      color: '#405ac6', 
    },
  },
  '& .MuiDialog-container':{
    position: 'absolute',
    right: applyCSS('auto', '0'),
    left: applyCSS('0', 'auto'),
    '& .MuiPaper-root':{
      margin: '0',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      width: '444px',
      '@media (max-width: 500px)': {
        width: '350px',
      },
    },
  }
});

const AccordionBox = styled(Box)({
  width: '100%',
  '& .accText':{
    fontWeight: 700,
    color:'#64748B',
    fontSize: "18px",
    fontFamily: applyCSS("Noto Sans Arabic", "Poppins"),
  },
  '& .MuiPaper-root':{
    '& .MuiButtonBase-root':{
      padding: '0px 20px !important',
      '& .MuiIconButton-label':{
        color: 'black'
      }
    },
    '& .MuiCollapse-root':{
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper':{
        '& .MuiCollapse-wrapperInner':{
          '& .MuiAccordionDetails-root':{
            padding: '0px 28px',
            background: '#F8FAFC',
            '& .MuiFormControl-root':{
              marginTop: '0px',
              marginBottom: '0px'
            }
          }
        }
      }
    }
  }
});

const DatePicker = styled(KeyboardDatePicker)({
  '& .MuiInputBase-root':{
    borderRadius: '12px',
    paddingRight: '0px',

    '&:hover fieldset': {
      borderColor: '#527FE2 !important', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#527FE2 !important', // Border color on focus
    },
  },
  '& .MuiSvgIcon-root':{
    color: 'gray',
  },
  '& .MuiButtonBase-root':{
    '&:hover': {
      background: 'transparent', // Change to the desired hover color
    },
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#527FE2 !important', // Ensure hover works properly
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#527FE2 !important',
  },
})

// Customizable Area End
