import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from ".../../../packages/framework/src/Utilities";


// Customizable Area Start
import { apiCalling, truthyValue } from "../../../components/src/constant"
import { ServiceFormFields, ServiceFormErrorFields } from "../../../components/src/types";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  PickupChecked:boolean;
  serviceFormFields : ServiceFormFields
  serviceErrorFields : ServiceFormErrorFields
  editableServiceId : string
  successModal : boolean
  errorModal : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddServiceController extends BlockComponent<Props, S, SS> {
      // Customizable Area Start
 
  getServiceApiCallId: string = "";
  addServiceApiCallId: string = "";
  updateServiceApiCallId: string = "";

   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

      this.state = {
          // Customizable Area Start
          arrayHolder: [],
          token: "",
          PickupChecked: false,
          serviceFormFields : this.initialServiceFormFields,
          serviceErrorFields : this.initialServiceErrorFields,
          editableServiceId : "",
          errorModal: false,
          successModal : false
          // Customizable Area End
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const receivedId = this.props.navigation.getParam("id")
    if(receivedId) {
       this.setState({ editableServiceId : receivedId})
    }
  
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.editableServiceId !== this.state.editableServiceId) {
       this.getServiceData()
    }
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.addServiceApiCallId:
          return this.handleAddServiceResponse(responseJson)
        case this.getServiceApiCallId:
          return this.handleGetServiceResponse(responseJson)
        case this.updateServiceApiCallId:
          return this.handleEditServiceResponse(responseJson)
      }

    }
  }

  handleAddServiceResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
       this.openSuccessModal()
    } else {
      this.openErrorModal()
    }
  }

  handleEditServiceResponse = (responseJson: any) => {
    if (responseJson && responseJson.message && responseJson.data) {
        this.openSuccessModal()
    } else {
      this.openErrorModal()
    }
  }

  handleGetServiceResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const receivedObject = responseJson.data
      const serviceObject = truthyValue(receivedObject.attributes)
      const serviceEditObject = {
        service_name_en: truthyValue(serviceObject.name),
        service_name_ar: truthyValue(serviceObject.arabic_name),
        service_desc_en: this.handleNullifyValues(truthyValue(serviceObject.description)),
        service_desc_ar: this.handleNullifyValues(truthyValue(serviceObject.description_ar)),
        service_price: truthyValue(serviceObject.price).toString(),
        service_pick_up_fees: this.handleNullifyValues(truthyValue(serviceObject.pickup_and_delivery_fees)),
      }

      const checkBox = truthyValue(serviceObject.pickup_and_delivery)

      this.setState({ serviceFormFields: serviceEditObject, PickupChecked : checkBox })
    } else {
       this.setState({ serviceFormFields: this.initialServiceFormFields, PickupChecked : false})
    }
  }

  handleNullifyValues = (key : any) => {
     return key && key !==null ? key.toString() : ""
  }

  handleCHeckPrice=()=>{
   this.setState({PickupChecked:!this.state.PickupChecked})
   
  }

  goToCatalogue=()=>{
    this.props.navigation.navigate("Catalogue")
  }

  handleAllServiceValidation = () => {
    let errorFields = {
      service_name_en: false,
      service_name_ar: false,
      service_desc_en: false,
      service_desc_ar: false,
      service_price: false,
      service_pick_up_fees: false
    }
     
    if (this.state.serviceFormFields.service_name_en.trim() === "") {
      errorFields.service_name_en = true;
    }
    if (this.state.serviceFormFields.service_name_ar.trim() === "") {
      errorFields.service_name_ar = true;
    }
    if (this.state.serviceFormFields.service_desc_en.trim() === "" ) {
      errorFields.service_desc_en = true;
    }
    if (this.state.serviceFormFields.service_desc_ar.trim() === "") {
      errorFields.service_desc_ar = true;
    }
    if (this.state.serviceFormFields.service_price.trim() === "" || isNaN(Number(this.state.serviceFormFields.service_price))) {
      errorFields.service_price = true;
    }
    if (this.state.PickupChecked && this.state.serviceFormFields.service_pick_up_fees.trim() === "" || isNaN(Number(this.state.serviceFormFields.service_pick_up_fees))) {
      errorFields.service_pick_up_fees = true;
    }


    this.setState({ serviceErrorFields: errorFields})

    return Object.values(errorFields).every((value) => value === false);
    
  }

  getServiceData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue,
    }

    this.getServiceApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.productAPiEndPoint}/${this.state.editableServiceId}`,
    })
  }

  saveServiceData = async () => {
    if (!this.handleAllServiceValidation()) {
      return false;
    }
    
    const formData = new FormData();

    formData.append("name", this.state.serviceFormFields.service_name_en);
    formData.append("arabic_name", this.state.serviceFormFields.service_name_ar);
    formData.append("description", this.state.serviceFormFields.service_desc_en);
    formData.append("description_ar", this.state.serviceFormFields.service_desc_ar);
    formData.append("price", this.state.serviceFormFields.service_price);
    formData.append("pickup_and_delivery", this.state.PickupChecked ? "true" : "false");
    this.state.PickupChecked && formData.append("pickup_and_delivery_fees", this.state.serviceFormFields.service_pick_up_fees);

    this.state.editableServiceId !== "" ? this.updateServiceData(formData) : this.addServiceData(formData)
    
  }

  addServiceData = async (httpBody: FormData) => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue,
    }

    this.addServiceApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.productAPiEndPoint,
      body: httpBody
    })
  } 

  updateServiceData = async (updatedhttpBody : FormData) => {
    const token = await getStorageData('token')
    const header = {
      token: token,
    }

    this.updateServiceApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePatch,
      endPoint: `${configJSON.productAPiEndPoint}/${this.state.editableServiceId}`,
      body: updatedhttpBody
    })
  }

  initialServiceFormFields : ServiceFormFields = {
    service_name_en : "",
    service_name_ar : "",
    service_desc_en : "",
    service_desc_ar : "",
    service_price : "",
    service_pick_up_fees : "",
  }

  initialServiceErrorFields : ServiceFormErrorFields = {
    service_name_en : false,
    service_name_ar : false,
    service_desc_en : false,
    service_desc_ar : false,
    service_price : false,
    service_pick_up_fees : false
  }

  handleInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    if(value.trim() === "") {
      this.setState({ serviceErrorFields: {...this.state.serviceErrorFields, [name] : true}})
    } else {
      this.setState({ serviceErrorFields: {...this.state.serviceErrorFields, [name] : false}})
    }

    this.setState({ serviceFormFields : {...this.state.serviceFormFields, [name]: value}})
  }

  getLayoutValues = (addServiceLayout : string, editServiceLayout : string ) => {
    return this.state.editableServiceId === "" ? addServiceLayout : editServiceLayout 
  }

  openSuccessModal = () => {
    this.setState({ successModal : true})
  }

  closeSuccessModal = () => {
    this.setState({ successModal : false}, () => this.goToCatalogue())
  }

  openErrorModal = () => {
    this.setState({ errorModal : true})
  }

  closeErrorModal = () => {
    this.setState({ errorModal : false})
  }

  // Customizable Area End
}
