import React from "react";

// Customizable Area Start
import {  Box, Typography, Tab, Card, Button } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { I18n } from "../../../components/src/languageJson/i18n";
import { ProfileImg, card, home, vat, email, 
  taxino, authId, attatchfile, branch,auth, iban, categories ,ophours,location,pdfIcon,topBackImg,
  pencil,
  branchCode,
  branchContactNumber} from "./assets";
import AppNavigation from '../../../components/src/AppNavigation.web';
import DocumentViewerPopup from "../../../components/src/DocumentViewerPopup.web";
import { EducationalQualifications } from "./components/EducationalQualifications.web";
import { Projects } from "./components/Projects.web";
import { Awards } from "./components/Awards.web";
import { PublicationPatents } from "./components/PublicationPatents.web";

import { Project } from "./model/Project";
import { Award } from "./model/Award";
import { PublicationPatent } from "./model/PublicationPatent";
import { styled } from "@material-ui/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const HeadBox = styled(Box)({
  display:"flex",
  "@media (max-width:990px )": {
    flexDirection:"column"
  },
              
});
const SubHeadBox = styled(Box)({
  width:"50%",
  "@media (max-width:990px )": {
    width:"100%",
  },
              
});

const EditBtn = styled(Button)({
  maxWidth: "42.5%",
  position:"absolute",
  height:"44px",
  padding:"10px 16px",
  backgroundColor:"#60A5FA",
  top:"40px",
  right:"24px",
  "@media (max-width:1050px )": {
    fontSize: "14px ",
    padding: "12px"
  },
  "@media (max-width:899px )": {
    fontSize: "12",
    padding: "8px"
  },

  gap: "8px",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontFamily: "Poppins ",
  fontSize: "1em",
  fontWeight: 700,
  lineHeight: "24px",
  cursor: "pointer",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#60A5FA",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#60A5FA",
    boxShadow: 'none',
  }

});

const UpdateBtn = styled(Button)({
  width: "196px",
  height: "44px",
  backgroundColor:"#527FE2",
  "@media (max-width:1050px )": {
    fontSize: "14px",
    padding: "12px",
    margingLeft: "auto",
  },
  "@media (max-width:899px )": {
    fontSize: "12",
    padding: "10px",
    minWidth: "84px",
  },

  gap: "10px",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontFamily: "Poppins ",
  fontSize: "1em",
  fontWeight: 700,
  lineHeight: "24px",
  cursor: "pointer",
  textTransform: "none",
'&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  }

});

const DescrText = styled(Typography)({
  fontFamily:"Poppins",
  fontSize:"16px",
  fontWeight:400,
  paragraph:"8px",
  color:"#0F172A",
  marginTop:"8px",
  "@media (max-width:500px )": {
    fontSize:"12px",
  },
});

const SubFieldsText = styled(Typography)({
  fontFamily:"Poppins",
    fontSize:"16px",
    fontWeight:700,
    paragraph:"8px",
    color:"#64748B",
  "@media (max-width:500px )": {
    fontSize:"12px",
  },
});

const ProfileCatText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"1rem",
  color:"#334155",
  display:"flex",
  alignItems:"center",
  columnGap:"8px",
  "@media (max-width:500px )": {
    fontSize:"12px",
  },})

  const ProfileName = styled(Typography)({
    fontFamily:"Poppins",
    fontWeight:700,
    fontSize:"2rem",
    letterSpacing:"-1%",
    "@media (max-width:500px )": {
      fontSize:"1.5rem",
    },
  });

  const ImageProfile = styled('img')({
    width:"130px",
    height:"130px",
    borderRadius:"150px",
    "@media (max-width:500px )": {
      width:"100px",
      height:"100px",
      borderRadius:"50%"
    },
  });

  const TabButton = styled(Tab)({
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform:"none",
    opacity:1,
    marginRight:"40px",
    "@media (max-width:620px )": {
      fontSize: '12px',
      marginRight:"20px",
    },
  });


  const TabDive = styled(Box)({
      display:"flex",
    "@media (max-width:465px )": {
      flexDirection:"column"
    },
  });

  const CustomBox = styled(Box)({
  maxWidth: "100vw",
  minHeight: "auto",
  background: "#F1F5FC",
  position: "relative",
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
});
// Customizable Area End

import EducationalUserProfileController, {
  Props,
  Tab as PageTab,
  configJSON,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const SubFieldRows = styled(Box)({
      marginTop:"1rem",
      display:"flex",
      alignItems:"center",
      gap:"5px",
      flexWrap:"wrap",
      flexDirection:this.getValueBasedOnLanguage('row-reverse', "row")            
    });

    const webStyle:any = {
      imageStyle: {
        width: 100,
      },
      qualificationBlock: {
        marginTop: 40,
      },
    
      cardStle: {
        minHeight:"100vh",
        margin:"2rem 0",
        marginBottom: "3rem",
        borderRadius:"1.5rem",
        position:"relative",
        top: "-150px",
        boxSizing:"border-box",
        padding:"1.5rem",
      },
      imageBOx:{
        padding:"0 1.5rem 0 1.5rem",
        borderRadius:"1.5rem",
        display:"flex",
        gap:"1rem",
        alignItems:"center",
        marginBottom:"40px",
        flexDirection:this.getValueBasedOnLanguage('row-reverse', 'row')
      },
      imageStyle1:{
        width:"130px",
        height:"130px",
        borderRadius:"150px"
      },
      profileName:{
        fontFamily:"Poppins",
        fontWeight:700,
        fontSize:"2rem",
        letterSpacing:"-1%"
      },
      profileCat:{
        fontFamily:"Poppins",
        fontWeight:400,
        fontSize:"1rem",
        color:"#334155",
        display:"flex",
        alignItems:"center",
        columnGap:"8px"
      },
      formHeading:{
        fontFamily:"Poppins",
        fontSize:"20px",
        fontWeight:700,
        paragraph:"12px",
        color:"#334155"
      },
      descText:{
        fontFamily:"Poppins",
        fontSize:"16px",
        fontWeight:400,
        paragraph:"8px",
        color:"#0F172A",
        marginTop:"8px",
      },
      subFields:{
        fontFamily:"Poppins",
        fontSize:"16px",
        fontWeight:700,
        paragraph:"8px",
        color:"#64748B"
      },
      branchName:{
        fontFamily:"Poppins",
        fontSize:"16px",
        fontWeight:700,
        paragraph:"8px",
        color:"#334155",
        marginTop:"1rem"
      }
    };

    const getFileName = (fileField: any) => {
      return fileField?.[0]?.name || "";
    };
   
    const {profileData, coverImageSrc} =this.state

    const renderCoverImage = () => {
      if (coverImageSrc) {
        return (
          <img
            src={coverImageSrc}
            alt="Cover Image"
            style={{ height: "318px", width: "100%", objectFit: "cover" }}
          />
        );
      }
    
      return (
        <div
          style={{
            height: "318px",
            width: "100%",
            background: "#f0f0f0 center/20px url('https://i.gifer.com/ZKZg.gif') no-repeat",
          }}
        />
      );
    };

    return (
      <div style={{display:'flex',background : "#F1F5FC",  flexDirection: this.getValueBasedOnLanguage('row-reverse', "row")}}>

        <AppNavigation />
        
        <div className={this.getValueBasedOnLanguage("arabic-text", "default-text")} style={{
          flex:1,
          marginRight: this.getValueBasedOnLanguage("325px", undefined)
        }}>
          <ThemeProvider theme={theme} >
          
            <CustomBox >
              <Box style={{ maxHeight: "318px", width: "100%", overflow: "hidden" }}>
              {renderCoverImage()}
              </Box>

              <EditBtn data-test-id="editcover" startIcon={<img src={pencil} alt="pecil"/>}  onClick={this.handleFileCoverClick}>{I18n.t("editCoverText")}</EditBtn>
              <input
                type="file"
                data-test-id="coverimg"
                ref={this.state.uploadCover}
                style={{ display: 'none' }}
                accept=".jpeg, .jpg, .png"
                onChange={this.handleFileCoverPhoto}
              />
            <Box style={{position: "relative", height: "auto", padding:"0 1.2rem"}}>
            <Box style={{ padding: "0", marginBottom: "4rem" }}>
            <Card style={webStyle.cardStle}>
              <Box style={webStyle.imageBOx}>
                <ImageProfile  
                  data-test-id="profileclick" 
                  src={
                    this.state.uploadImg 
                    ? URL.createObjectURL(this.state.uploadImg) 
                    : (this.state.profileData.attributes.photo_image as { url: string; name: string }[] | null)?.[0]?.url || ProfileImg
                  } 
                  alt="profile"  onClick={this.handleFileInputClick4}
                />
                <input
                        type="file"
                        ref={this.state.uplodaProfile}
                        style={{ display: 'none' }}
                        accept=".jpeg, .jpg, .png"
                        onChange={this.handleFileChange4}
                        data-test-id="profileimg"
                      />
                <Box 
                  style={{
                    direction:this.getValueBasedOnLanguage('rtl', 'ltr')
                  }}
                >
                  <ProfileName > {profileData.attributes.business_name}</ProfileName>
                  <ProfileCatText >{profileData.attributes.category}</ProfileCatText>
                </Box>
              </Box>

              <TabContext value={this.state.activeTab}>
                <Box sx={{ 
                  padding: "0 24px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    xs: "column",
                    md: this.getValueBasedOnLanguage('row-reverse', 'row'), 
                  },
                }}>
                  <TabDive aria-label="lab API tabs example"
                    style={{
                      flexDirection:this.getValueBasedOnLanguage('row-reverse', 'row')
                    }}
                  >
                    <TabButton
                      label={I18n.t("orgManagement")}
                      data-test-id="organizationDetails"
                      onClick={() => this.setActiveTab("1")}
                      style={{
                        borderBottom: this.state.activeTab =="1" ? '2px solid #527FE2' : 'none',
                        color: this.state.activeTab == "1" ? '#527FE2' : '#64748B', 
                        transition: 'border-bottom 0.3s ease',
                        opacity: "1",
                      }}
                    />
                    <TabButton
                      label={I18n.t("managementAndDep")}
                      disabled
                      style={{
                        color: '#64748B', 
                        opacity: "1",
                      }}
                    />
                  </TabDive>
                  <UpdateBtn onClick={this.handleNavigateUserProfileBasicBlock}
                    
                  >
                    <span
                      style={{
                        display:'flex',
                        flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row')
                      }}
                    >
                      <span>{I18n.t("updateText")}</span>
                      <img src={pencil} alt="pencil icon" style={{ width: 24, height: 24, 
                        marginLeft: this.getValueBasedOnLanguage('0', '6px'),
                        marginRight: this.getValueBasedOnLanguage('6px', '0'),
                      }} />
                    </span>
                    
                  </UpdateBtn>

                </Box>
                  <TabPanel value="1">
                    <Box >
                      <Typography style={{...webStyle.formHeading, direction: this.getValueBasedOnLanguage('rtl', 'ltr')}}>{I18n.t("description")}</Typography>
                      <DescrText
                        style={{
                          direction: this.getValueBasedOnLanguage('rtl', 'ltr')
                        }}
                      >
                      {profileData.attributes.business_description}
                      </DescrText>
                    </Box>

                    <Box style={{ marginTop: "1.5rem" }}>
                      <Typography style={{
                        ...webStyle.formHeading,
                        direction: this.getValueBasedOnLanguage('rtl', 'ltr')
                      }}>{I18n.t("generalInfo")}</Typography>
                      <HeadBox style={{ display: "flex", flexDirection: this.getValueBasedOnLanguage('row-reverse', "row") }}>
                        <SubHeadBox >
                          <SubFieldRows>
                            <img src={card} alt="user icon" style={{ width: 20, height: 16}} />
                            <SubFieldsText >{I18n.t("nationalIdTxt")}</SubFieldsText>
                            <ProfileCatText >{profileData.attributes.national_id}</ProfileCatText>

                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={home} alt="user icon" style={{ width: 16, height: 18 }} />
                            <SubFieldsText >{I18n.t("nationaAddressTxt")}</SubFieldsText>
                            <ProfileCatText >{profileData.attributes.address}</ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>

                        <SubHeadBox >
                          <SubFieldRows >
                            <img src={vat} alt="user icon" style={{ width: 20, height: 18 }} />
                            <SubFieldsText >{I18n.t("vatRegNoTxt")}</SubFieldsText>
                            <ProfileCatText >{profileData.attributes.vat_registration_number}</ProfileCatText>

                          </SubFieldRows>
                          <SubFieldRows>
                            <img src={email} alt="user icon" style={{ width: 20, height: 16 }} />
                            <SubFieldsText >{I18n.t("supportEmailTxt")}</SubFieldsText>
                            <ProfileCatText>{profileData.attributes.support_email}</ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>
                      </HeadBox>
                    </Box>

                    <Box style={{ marginTop: "1.5rem" }}>
                      <Typography style={{
                        ...webStyle.formHeading,
                        direction: this.getValueBasedOnLanguage("rtl", "ltr")
                      }}>{I18n.t("companyInfo")}</Typography>
                      <HeadBox style={{ display: "flex" }}>
                        <SubHeadBox >
                          <SubFieldRows >
                            <img src={vat} alt="user icon" style={{ width: 20, height: 18 }} />
                            <SubFieldsText >{I18n.t("businessNameTxt")}</SubFieldsText>
                            <ProfileCatText >{profileData.attributes.business_name}</ProfileCatText>

                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={taxino} alt="user icon" style={{ width: 14, height: 18 }} />
                            <SubFieldsText >{I18n.t("vatTaxIdentifierTxt")}</SubFieldsText>
                            <ProfileCatText 
                              data-test-id="vat-tax-identifier"
                              onClick={() =>
                                this.showFilePopup(
                                  // @ts-ignore
                                  this.state.profileData.attributes.vat_tax_identifier_image?.[0].url,
                                  getFileName(this.state.profileData.attributes.vat_tax_identifier_image)
                                )
                              }
                              style={{ cursor: "pointer", }}

                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.vat_tax_identifier_image)}
                            </ProfileCatText>
                            {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}

                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={authId} alt="user icon" style={{ width: 18, height: 14 }} />
                            <SubFieldsText >{I18n.t("authIdCardTxt")}</SubFieldsText>
                            <ProfileCatText
                              data-test-id="authorized-id-card"
                              onClick={() =>
                                this.showFilePopup(
                                  // @ts-ignore
                                  this.state.profileData.attributes.authorized_id_card_image?.[0].url,
                                  getFileName(this.state.profileData.attributes.authorized_id_card_image)
                                )
                              }
                              style={{ cursor: "pointer", }}
                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.authorized_id_card_image)}
                            </ProfileCatText>
                            {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}
                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={attatchfile} alt="user icon" style={{ width: 11, height: 22 }} />
                            <SubFieldsText >{I18n.t("otherDocTxt")}</SubFieldsText>
                            <ProfileCatText
                              data-test-id="other-doc"
                               onClick={() => {
                                const documentImage = this.state.profileData.attributes.other_document_image;
                                if (Array.isArray(documentImage) && documentImage.length > 0) {
                                  this.showFilePopup(documentImage[0].url, getFileName(this.state.profileData.attributes.other_document_image));
                                }
                              }}
                              style={{
                                cursor:
                                Array.isArray(this.state.profileData.attributes.other_document_image) &&
                                this.state.profileData.attributes.other_document_image.length > 0
                                  ? "pointer"
                                  : undefined,
                              }}
                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.other_document_image)}</ProfileCatText>
                              {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}

                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={branch} alt="user icon" style={{ width: 18, height: 21 }} />
                            <SubFieldsText >{I18n.t("branchSelectionTxt")}</SubFieldsText>
                            <ProfileCatText >
                              {profileData.attributes.branch_selection === 'multiple_branches' 
                                ? 'Multiple Branches' 
                                : 'Individual Branch'}
                            </ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>

                        <SubHeadBox >
                          <SubFieldRows >
                            <img src={card} alt="user icon" style={{ width: 20, height: 16 }} />
                            <SubFieldsText >{I18n.t("tradeLicenseTxt")}</SubFieldsText>
                            <ProfileCatText 
                              data-test-id="trading-license"
                              onClick={() =>
                                this.showFilePopup(
                                  // @ts-ignore
                                  this.state.profileData.attributes.trading_license_image?.[0].url,
                                  getFileName(this.state.profileData.attributes.trading_license_image)
                                )
                              }
                              style={{ cursor: "pointer", }}
                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.trading_license_image)}</ProfileCatText>
                              {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}
                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={auth} alt="user icon" style={{ width: 24, height: 24 }} />
                            <SubFieldsText >{I18n.t("authCertificateTxt")}</SubFieldsText>
                            <ProfileCatText 
                              data-test-id="authorization-certificate"
                              onClick={() =>
                                this.showFilePopup(
                                  // @ts-ignore
                                  this.state.profileData.attributes.authorization_certificate_image?.[0].url,
                                  getFileName(this.state.profileData.attributes.authorization_certificate_image)
                                )
                              }
                              style={{ cursor: "pointer", }}
                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.authorization_certificate_image)}</ProfileCatText>
                              {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}
                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={iban} alt="user icon" style={{ width: 22, height: 18 }} />
                            <SubFieldsText >{I18n.t("ibanCertificateTxt")}</SubFieldsText>
                            <ProfileCatText 
                              data-test-id="iban-certificate"
                              onClick={() =>
                                this.showFilePopup(
                                  // @ts-ignore
                                  this.state.profileData.attributes.iban_certificate_image?.[0].url,
                                  getFileName(this.state.profileData.attributes.iban_certificate_image)
                                )
                              }
                              style={{ cursor: "pointer", }}
                            >
                              <img src={pdfIcon} style={{ width: "24px", height: "24px" }} />
                              {getFileName(this.state.profileData.attributes.iban_certificate_image)}</ProfileCatText>
                              {this.state.isFilePopupVisible && (
                            <DocumentViewerPopup
                              fileUrl={this.state.selectedFileUrl}
                              documentName={this.state.selectedDocumentName}
                              onClose={this.hideFilePopup}
                            />
                            )}
                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={categories} alt="user icon" style={{ width: 19, height: 20 }} />
                            <SubFieldsText >{I18n.t("categoriesTxt")}</SubFieldsText>
                            <ProfileCatText>{profileData.attributes.category}</ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>
                      </HeadBox>
                    </Box>

                    <Box style={{ 
                        marginTop: "1.5rem" 
                      }}>
                      <Typography style={{
                        ...webStyle.formHeading, flexDirection:this.getValueBasedOnLanguage('row-reverse', "row"), display:'flex'
                      }}>{I18n.t("branchDetails")}</Typography>
                      {profileData.attributes.branches?.map((item:any,index:any)=>
                      <>
                      <Typography style={{
                          ...webStyle.branchName,
                          direction: this.getValueBasedOnLanguage('rtl', 'ltr')
                        }}>{item.branch_name}</Typography>
                      <HeadBox style={{ display: "flex" }}>
                        <SubHeadBox >
                          <SubFieldRows >
                            <img src={branchCode} alt="user icon" style={{ width: 24, height: 24 }} />
                            <SubFieldsText >{I18n.t("branchCodeTxt")}</SubFieldsText>
                            <ProfileCatText >{item.branch_code}</ProfileCatText>

                          </SubFieldRows>
                          <SubFieldRows >
                            <img src={branchContactNumber} alt="user icon" style={{ width: 24, height: 24 }} />
                            <SubFieldsText >{I18n.t("branchContactNumTxt")}</SubFieldsText>
                            <ProfileCatText >{item.mobile_number}</ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>

                        <SubHeadBox >
                          <SubFieldRows >
                            <img src={location} alt="user icon" style={{ width: 16, height: 20 }} />
                            <SubFieldsText >{I18n.t("branchLocationTxt")}</SubFieldsText>
                            <ProfileCatText>{item.location_link}</ProfileCatText>

                          </SubFieldRows>
                          <SubFieldRows>
                            <img src={ophours} alt="user icon" style={{ width: 18, height: 21 }} />
                            <SubFieldsText >{I18n.t("branchOperatingHrsTxt")}</SubFieldsText>
                            <ProfileCatText >{item.business_hours}</ProfileCatText>

                          </SubFieldRows>
                        </SubHeadBox>
                      </HeadBox>
                      </>)}
                    </Box>
                  </TabPanel>
            
          
          </TabContext>
            </Card>
            </Box>
            </Box>

            </CustomBox>
          </ThemeProvider>
        </div>
      </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}