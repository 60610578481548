import moment from "moment";
import "moment/locale/ar"; // Arabic locale
import "moment/locale/en-gb"; // English locale

export const getYear = (date: string) => {
    moment.locale("en");
    const receivedYear = moment(date).format("YYYY");
    return receivedYear;
};

export const getDate = (date: string) => {
    moment.locale("en");

    const receiveddate = moment(date).format("DD");
    return receiveddate;
};

export const getMonth = (date: string, mode: "ar" | "en") => {
    const monthIndex = moment(date).month(); // Get month index (0-11)

    const monthNamesEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const monthNamesAr = [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
    ];

    return mode === "ar" ? monthNamesAr[monthIndex] : monthNamesEn[monthIndex];
};

export const getTime = (date: string, mode: "ar" | "en") => {
    moment.locale(mode === "ar" ? "ar" : "en"); // Set locale
    let time = moment(date).format("hh:mm A"); // Format time

    if (mode === "ar") {
      // Convert Arabic numerals back to English numerals
      time = time.replace(
        /[٠-٩]/g,
        (d) => "0123456789"["٠١٢٣٤٥٦٧٨٩".indexOf(d)]
      );
    }

    return time;
  };