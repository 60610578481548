import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {createRef } from "react";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18n } from "../../../components/src/languageJson/i18n";
import { truthyValue } from "../../../components/src/constant";

interface IRequestDetails {
  id: string;
  type: string;
  attributes: {
    sender_id: string;
    photo:string;
    name:string;
    title: string;
    createdAt: string;
    status: string;
    category: string;
    description: string;
    attachment?: string;
  };
}

interface IRequestCommentsDetails {
  id: string;
  attributes: {
    commentable_id: string;
    comment: string;
    created_at: string; 
    updated_at: string;
    account: {
      role_id: string;
    };
    attachments: {
      url:string
      size:string
    }[]
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentLanguage: string;
  allocatedTo: string;
  selectedBrand:string;
  selectedBrandId:string;
  selectedTime:string;
  selectedTicketID: string;
  selectedTicket: IRequestDetails | null;
  selectedTicketComments: IRequestCommentsDetails[] | null;
  selectedUser:string;
  selectedUserId:string;
  selectedDepartment:string;
  departments:{id:string, name:string}[];
  users:{id: string; name: string;}[];
  brands:{id:string; name:string;}[];
  isAllocationDropdownOpen: boolean;
  isBrandDropdownOpen:boolean;
  isTimeDropdownOpen:boolean;
  getTicketDetailsCallId:string;
  getDepartmentsCallId:string;
  getEmployeesCallId:string;
  getBrandsCallId:string;
  updateUserApiCalId:string;
  updateBrandApiCalId:string;
  updateTimeApiCalId:string;
  updateReadApiCalId:string;
  postCommentApiCalId:string;
  markAsDoneApiCalId:string;
  newComment: string;
  currentlyAssignedTo:string;
  searchQuery:string;
  attachments: File[];
  isFilePopupVisible: boolean;
  selectedFileUrl: string;
  selectedDocumentName: string;
  isReadValue:boolean;
  commentDate:string;
  statusText : string
  catogoryText : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private pollingInterval: NodeJS.Timeout | null = null;
  dropdownRef: React.RefObject<HTMLDivElement>; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      allocatedTo:"",
      selectedBrand:"",
      selectedBrandId:'',
      selectedTime:"",
      selectedTicketID: "",
      selectedTicket: null,
      selectedTicketComments: null,
      selectedDepartment: 'd1',
      selectedUser: '',
      selectedUserId:'',
      departments: [],
      users: [],
      brands:[],
      isAllocationDropdownOpen:false,
      isBrandDropdownOpen:false,
      isTimeDropdownOpen:false,
      getTicketDetailsCallId:'',
      getDepartmentsCallId:'',
      getEmployeesCallId:'',
      getBrandsCallId:'',
      updateUserApiCalId:'',
      updateBrandApiCalId:'',
      updateTimeApiCalId:'',
      updateReadApiCalId:'',
      postCommentApiCalId:'',
      markAsDoneApiCalId:'',
      newComment: '', 
      searchQuery:'',
      currentlyAssignedTo:'',
      attachments: [],
      isFilePopupVisible: false,
      selectedFileUrl: "",
      selectedDocumentName: "",
      isReadValue:true,
      commentDate:'24 Aug 2020 11:34 AM',
      statusText: "",
      catogoryText: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.dropdownRef = createRef(); 
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId == this.state.getDepartmentsCallId && 
        responseJson !== undefined &&
        Array.isArray(responseJson.data)
      ) {
          const mappedDepartments = responseJson.data.map((department: any) => ({
            id: department.attributes.id.toString(), 
            name: department.attributes.name, 
          }));
    
          this.setState({ departments: mappedDepartments });
      }

      if(apiRequestCallId == this.state.getEmployeesCallId &&
        responseJson.employees.data !== undefined &&
        Array.isArray(responseJson.employees.data)
      ) {
          const employee = responseJson.employees.data.map((emp: any) => ({
            id: emp.attributes.id.toString(), 
            name: emp.attributes.name, 
          }));
    
          this.setState({ users: employee }); 
      }

      if(apiRequestCallId == this.state.getBrandsCallId &&
        responseJson.manufacturers !== undefined &&
        Array.isArray(responseJson.manufacturers)
      ) {
          const brandsList = responseJson.manufacturers.map((brand: any) => ({
            id: brand.id.toString(), 
            name: brand.name, 
          }));
    
          this.setState({ brands: brandsList });
      }

      if(apiRequestCallId === this.state.getTicketDetailsCallId &&
        responseJson?.request?.data
      ) {
        const ticketDetails = this.extractTicketDetails(responseJson.request.data);
        const statusText = truthyValue(responseJson.request.data.attributes.status)
        const catogoryText = truthyValue(responseJson.request.data.attributes.request_type)
        this.setState(ticketDetails);
        this.setState({ statusText : statusText, catogoryText: catogoryText})
        this.markAsReviewing();
      
      }

      if(apiRequestCallId === this.state.getTicketDetailsCallId &&
        responseJson !== undefined &&
        responseJson.comments.data
      ) {
        const mappedComments = 
        responseJson.comments.data.length > 0 
          ? responseJson.comments.data.map((comment: any) => ({
            id: comment.id,
            attributes: {
              commentable_id: comment.attributes.commentable_id,
              comment: comment.attributes.comment,
              created_at: this.formatDateTime(comment.attributes.created_at),
              updated_at: this.formatDateTime(comment.attributes.updated_at),
              account: {
                role_id: String(comment.attributes.account.role_id),
              },
              attachments:comment.attributes.attachments
            },
          })) 
          : [];
      
        this.setState({ selectedTicketComments: mappedComments },);
        
      
      }

      if(apiRequestCallId == this.state.postCommentApiCalId &&
        !responseJson.errors){
          toast.success(I18n.t("commentAdded"), {
            position: "top-center",
            hideProgressBar: true,
            style: {
              width: "550px", 
              minHeight:'30px',
              maxHeight:"50px",
              borderRadius: "5px",
              backgroundColor:'#D1FAE5',
              color:'#059669',
              borderLeft:'5px solid #059669',
              textAlign: this.getValueBasedOnLanguage('right', 'left'),
              direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
            }
        });
      } else if (apiRequestCallId == this.state.postCommentApiCalId &&
        responseJson.errors){
        toast.error(I18n.t("unableToAddComment"), {
          position: "top-center",
          hideProgressBar: true,
          style: {
            width: "550px", 
            minHeight:'30px',
            maxHeight:"50px",
            borderRadius: "5px",
            backgroundColor:'#FEE2E2',
            color:'#DC2626',
            borderLeft:'5px solid #DC2626',
            textAlign: this.getValueBasedOnLanguage('right', 'left'),
            direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
          },
        });
      }

      if(apiRequestCallId == this.state.updateUserApiCalId) {
        console.log("user updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateBrandApiCalId) {
        console.log("brand updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateTimeApiCalId) {
        console.log("time updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateReadApiCalId) {
        console.log("read updated : ", responseJson)
        this.setState({ isReadValue: true },);
      }

      if(apiRequestCallId == this.state.markAsDoneApiCalId){
        this.navigateToTicketListingPage()
        this.markTicketAsRead()
      }

    }
    
  }

  private extractTicketDetails(data: any) {
    const manufacturerName = data.attributes.manufacturer_name || '';
    const alreadySelectedBrand = this.state.brands.find(
      (brand) => brand.name.toUpperCase() === manufacturerName.toUpperCase()
    )?.id || '';
  
    return {
      selectedTicket: {
        id: data.id,
        type: data.type,
        attributes: {
          sender_id: data.attributes.sender_id,
          name: data.attributes.sender_full_name,
          photo: data.attributes.sender_photo_url || '',
          title: data.attributes.request_text,
          createdAt: this.formatDateTime(data.attributes.created_at),
          status: this.capitalizedWord(data.attributes.status.replace("_", " ")),
          category: data.attributes.request_type,
          description: data.attributes.description,
          attachment: data.attributes.attachments?.[0] || '',
        },
      },
      selectedUser: data.attributes.assigned_to || '',
      selectedBrand: alreadySelectedBrand,
      selectedTime: data.attributes.time_description || '',
    };
  }
  
  
  componentDidMount = async () => {
    this.handleTicketDetails();
    this.getDepartmentList();
    this.getBrandsList();
    this.markTicketAsRead();
    this.getToken();
    document.addEventListener("mousedown", this.handleClickOutside);

    // Start polling for new comments every 8 seconds
    this.pollingInterval = setInterval(this.handleTicketDetails, 8000); // Poll every 8 seconds

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    if (this.isPlatformWeb() === false) {
        this.props.navigation.addListener("willFocus", () => {
            this.getToken();
        });
    }
  };

  async componentWillUnmount(): Promise<void> {
    // Clear the polling interval if it exists
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }

    document.removeEventListener("mousedown", this.handleClickOutside);

  }
  
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.selectedTicketComments !== this.state.selectedTicketComments) {
      const sortedComments =
      this.state.selectedTicketComments?.sort(
        (a, b) =>
          new Date(a.attributes.created_at).getTime() -
          new Date(b.attributes.created_at).getTime()
      ) || null;
  
      this.setState({ selectedTicketComments: sortedComments });
    }
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  handleClickOutside = (event:any) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isAllocationDropdownOpen: false }); 
    }
  };

  handleSearchChange = (event:any) => {
    this.setState({ searchQuery: event.target.value });
  };

  showFilePopup = (fileURL: string, documentName: string) => {
    this.setState({
      isFilePopupVisible: true,
      selectedFileUrl: fileURL,
      selectedDocumentName: documentName,
    });
  };

  hideFilePopup = () => {
    this.setState({isFilePopupVisible: false, selectedFileUrl: ""});
  };

  getFilteredUsers = () => {
    const { searchQuery, users } = this.state;
    if (!searchQuery.trim()) return users;
    return users.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  getMerchantName = () => {
    return localStorage.getItem('merchantName') || 'You';
  }

  getMerchantProfileImg = () => {
    return localStorage.getItem('merchantImg');
  }

  formatDateTime = (isoDateTime:string) => {
    const isoDate = isoDateTime;
    const date = new Date(isoDate);
    const formattedDate = `${date.getDate()} ${date.toLocaleString("en-US", { month: "short" })} ${date.getFullYear()} ${date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })}`;
    return formattedDate;
  }

  capitalizedWord = (status: string) => {
    return status
        .split(" ") 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join(" "); 
    };

  markTicketAsRead = async() => {
    const selectedTicketID = localStorage.getItem('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const formData:any = new FormData();
    formData.append("is_read", this.state.isReadValue);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateReadApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/is_read_update`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateToTicketListingPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RequestManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  markAsDone = async() => {
    const selectedTicketID = localStorage.getItem('selectedTicketID')
    this.setState({isReadValue:false})

    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ markAsDoneApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/completed`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  markAsReviewing = async() => {
    if(this.state.selectedTicket?.attributes?.status === "Pending")
    {
      const selectedTicketID = localStorage.getItem('selectedTicketID')
      this.setState({isReadValue:false})
  
      const header = {
        token: localStorage.getItem("token"),
      }; 
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      const endPoint = `bx_block_request_management/requests/${selectedTicketID}/review_status`
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       "PATCH"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true; 
    } 
    
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    this.setState((prevState) => {
      const updatedAttachments = [...prevState.attachments, ...files];
      return { attachments: updatedAttachments };
    });
  };

  
  handleRemoveAttachment = (index: number) => {
    this.setState((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments.splice(index, 1); 
      return { attachments: updatedAttachments };
    });
  };


  handleSendComment = () => {
    const { newComment } = this.state;
  
    // if (!newComment.trim() && this.state.attachments.length === 0) {
    //   return;
    // }

    if (!newComment.trim()) {
      return; 
    }
  
    this.postMerchantComment();

  };
   

  handleTicketDetails = async () => {
    const tokenL  =  await getStorageData('token')
    const ticketID  =  await getStorageData('selectedTicketID')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getTicketDetailsCallId: newCallId });
  
    const endPoint = `bx_block_request_management/requests/${ticketID}`;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getDepartmentList = async () => {
    const tokenL  =  await getStorageData('token')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getDepartmentsCallId: newCallId });
  
    const endPoint = 'bx_block_fullteamvisibility/departments/department_listing';

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getEmployeesList = async (depID:string) => {
    const tokenL  =  await getStorageData('token')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getEmployeesCallId: newCallId });
  
    const endPoint = `bx_block_fullteamvisibility/employees_in_department/${depID}`;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getBrandsList = async () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getBrandsCallId: newCallId });
  
    const endPoint = 'account_block/manufacturers/names';

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateSelectedUser = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateUserApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/assignee?assigned_to=${this.state.selectedUserId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSelectedBrand = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateBrandApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/select_manufacturer?manufacturer_id=${this.state.selectedBrandId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSelectedTime = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateTimeApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/select_time?data=${this.state.selectedTime}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postMerchantComment = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ postCommentApiCalId: newCallId });

    if (!this.state.newComment.trim()) {
      return false;
    }

    const fileNames = this.state.attachments.map((file) => ({
      url: file.name,
      size: (file.size / 1024 / 1024).toFixed(2), 
    }));

    const newCommentAdding = {
      id: `temp-${Date.now()}`,
      attributes: {
        commentable_id: selectedTicketID,
        comment: this.state.newComment.trim(),
        created_at: this.formatDateTime(new Date().toISOString()),
        updated_at: this.formatDateTime(new Date().toISOString()),
        account: { role_id: "1" }, 
        attachments: fileNames,
      },
    };

    const formdata = new FormData();
    this.state.attachments.forEach((file) => {
      formdata.append("attachments[]", file, file.name.toString());
    });

    const encodedMessage = encodeURIComponent(this.state.newComment);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/add_comment?comment=${encodedMessage}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    this.setState((prevState) => ({
      selectedTicketComments: [
        ...(prevState.selectedTicketComments || []),
        newCommentAdding, 
      ],
      newComment: "", 
      attachments: [], 
    }));
    return true;
  };

  handleBrandDropdownToggle = () => {
    this.setState((prevState) => ({
      isBrandDropdownOpen: !prevState.isBrandDropdownOpen,
    }));
  };

  handleTimeDropdownToggle = () => {
    this.setState((prevState) => ({
      isTimeDropdownOpen: !prevState.isTimeDropdownOpen,
    }));
  };

  handleDropdownToggle = () => {
    this.setState((prevState) => ({ isAllocationDropdownOpen: !prevState.isAllocationDropdownOpen }));
  };

  handleDepartmentSelect = (departmentId: string) => {
    if (this.state.selectedDepartment !== departmentId) {
      this.setState({
        selectedDepartment: departmentId,
        selectedUser: '', 
        selectedUserId: ''
      }, () => {
        this.getEmployeesList(departmentId);
      });
    } else {
      this.setState({
        selectedDepartment: '',
        users: [], 
      });
    }
  };

  handleUserSelect = (userId: string) => {
    const selectedUser = this.state.users.find(user => user.id === userId);
      if(selectedUser) {
        this.setState({
          selectedUserId: userId,
          selectedUser: selectedUser.name, 
          isAllocationDropdownOpen: false,
          searchQuery:''
        }, () => {
          this.updateSelectedUser(); 
        });
      } 

    toast.success(I18n.t("taskAssigned"), {
      position: "top-center",
      hideProgressBar: true,
      style: {
        width: "550px", 
        minHeight:'30px',
        maxHeight:"50px",
        borderRadius: "5px",
        backgroundColor:'#D1FAE5',
        color:'#059669',
        borderLeft:'5px solid #059669',
        textAlign: this.getValueBasedOnLanguage('right', 'left'),
        direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
      },
    })
   
  };

  handleAllocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({allocatedTo: event.target.value as string });
  };

  handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({selectedBrand:event.target.value as string, selectedBrandId: event.target.value as string, isBrandDropdownOpen: false }, () => {
      this.updateSelectedBrand();
    });
    toast.success(I18n.t("brandAssigned"), {
      position: "top-center",
      hideProgressBar: true,
      style: {
        width: "550px", 
        minHeight:'30px',
        maxHeight:"50px",
        borderRadius: "5px",
        backgroundColor:'#D1FAE5',
        color:'#059669',
        borderLeft:'5px solid #059669',
        textAlign: this.getValueBasedOnLanguage('right', 'left'),
        direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
      },
    })
  };
  
  handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({selectedTime: String(event.target.value)}, () => {
      this.updateSelectedTime();
    });
    toast.success(I18n.t("timeAssigned"), {
      position: "top-center",
      hideProgressBar: true,
      style: {
        width: "550px", 
        minHeight:'30px',
        maxHeight:"50px",
        borderRadius: "5px",
        backgroundColor:'#D1FAE5',
        color:'#059669',
        borderLeft:'5px solid #059669',
        textAlign: this.getValueBasedOnLanguage('right', 'left'),
        direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
      },
    })
  };

  getStatusText = (statusText : string | undefined) => {
    const reqStatusData = [ 
      { title :`${I18n.t("pendingStatusText")}`, value: "Pending"},
      { title: `${I18n.t("progressStatusText")}`, value: "In_Progress"}, 
      { title :`${I18n.t("finishedStatusText")}`, value: "Finished"}, 
      { title : `${I18n.t("reviewingStatusText")}`, value : "Reviewing"}
    ]

    let statusVal = ""
    const findVal = reqStatusData.find(item => statusText && item.value === statusText)
    if(findVal) {
      statusVal = findVal.title
    }

    return statusVal
  }

  getCatogoryText = (catogoryText : string) => {
    let catVal = ""
    const reqCatogoriesData = [ 
      { key :`${I18n.t("service")}`, value: "service"}, 
      { key: `${I18n.t("suggestion")}`, value: "suggestion"}, 
      { key :`${I18n.t("complaint")}`, value: "complaint"}
    ]
    const findVal = reqCatogoriesData.find(item => item.value === catogoryText)
    if(findVal) {
      catVal = findVal.key
    }
    return catVal
  }

  // Customizable Area End
}
