import React, { createRef } from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
  Card,
  Modal,
  Select, 
  MenuItem,
  InputAdornment
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18n } from "../../../components/src/languageJson/i18n";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { clockIcon2, errorDatePickerIcon, errorClockIcon, errorDropdownPersonIcon, errorAttendeesIcon, datePickerIcon, modalErrorIcon, modalSuccessIcon, dropdownPersonIcon, dropdownArrow, attendeesIcon2 } from "./assets";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import DateFnsUtils from '@date-io/date-fns';
import "dayjs/locale/ar";
import { Formik, FormikProps } from 'formik';
import { getValueBasedOnLanguage } from "../../../components/src/constant"
import AppNavigation from '../../../components/src/AppNavigation.web';
import * as Yup from "yup";
import moment from "moment"
// Customizable Area End

import AddAppoinmentsControllerweb, {
  Props,
} from "./AddAppoinmentControllerweb";
import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { DatePicker } from "@material-ui/pickers/DatePicker/DatePicker";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface FormValues {
  date: Date | null;
  time: string;
  attendee: string;
  notes: string;
  customer: string;
  personToMeet: string;
}

export default class AddAppointments extends AddAppoinmentsControllerweb {
  handleIconClick: any;
  datePickerRef: React.RefObject<unknown>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.datePickerRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  useStyles = {
    ButtonStyles: {
      marginTop: '2rem',
      backgroundColor: '#527FE2',
      border: 'none',
      borderRadius: '8px',
      fontWeight: 700,
      fontSize: '14px',
      padding: '12px',
      fontFamily: "Poppins",
      color: '#ffff',
      width: '300px',
    },
    ModalStyles: {
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: '#ffff',
      top: "50%",
      border: "none",
      borderRadius: '8px',
      boxShadow: 24,
      padding: '16px 24px',
    },
    Cancelbtn: {
      marginTop: "16px",
      fontWeight: 600,
      fontFamily: 'Poppins',
      backgroundColor: '#527FE2',
      padding: '12px',
      width: '400px',
      color: '#ffff',
      border: "none",
      borderRadius: "8px"
    },
    DropdownStyle: {
      padding: "10px",
      borderBottom: "1px solid #E2E8F0",
      fontSize: "16px",
      fontFamily: "Poppins",
      cursor: "pointer",
      transition: "background-color 0.2s ease",
    },
    navLinks: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      color: "#000000",
      textDecoration: "none",
    },
    errorText: {
      color: "#FF4D4F",
      fontSize: "12px",
      fontFamily: "Poppins",
      marginTop: "1px",
      position: "absolute" as const, 
      width: "100%"
    }
  }

  CardBox = styled(Card)({
    backgroundColor: "fffffff",
    width: "100%",
    borderBottomLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    borderBottomRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderTopRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderTopLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:560px)": {
      display: "flex"
    },
  });
  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex',
    flexWrap: 'nowrap',
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });
  
  validationSchema = Yup.object({
    date: Yup.date().required(I18n.t("dateRequired")).test("is-future-date", I18n.t("dateCannotBePast"), (value) => {
      return value ? moment(value).isSameOrAfter(moment(), "day") : false;
    }), 
    time: Yup.string()
    .required(I18n.t("timeRequired"))
    .test("is-future-time", I18n.t("timeError"), function (value) {
      const selectedDate = this.parent.date; 
      if (!selectedDate) return true; 

      const selectedDateTime = moment(selectedDate).set({
        hour: moment(value, "HH:mm").hour(),
        minute: moment(value, "HH:mm").minute(),
        second: 0,
      });

      return selectedDateTime.isSameOrAfter(moment()); 
    }),
    attendee: Yup.string().required(I18n.t("attendeeRequired")),
    notes: Yup.string().required(I18n.t("notesRequired")),
    customer: Yup.string().required(I18n.t("customerRequired")),
    personToMeet: Yup.string().required(I18n.t("personToMeetRequired")),
  });

  addScreenDate = (formik: FormikProps<FormValues>): JSX.Element => {
    const hasError = !!(formik.touched.date && formik.errors.date);

    return (
      <>
      <StyledComponents className={`notes-input wrapper ${this.state.currentLanguage === "ar" ? "rtl" : ""}`}>
        <img className="icon" src={hasError ? errorDatePickerIcon : datePickerIcon} alt="Date Picker Icon" />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            inputRef={this.datePickerRef}
            InputProps={{ disableUnderline: true }}
            className="date-picker"
            data-empty={!formik.values.date}
            variant="inline"
            format={this.state.currentLanguage === "ar" ? "yyyy MMM dd" : "dd MMM yyyy"}
            id="date-picker-inline"
            data-test-id="dateInput"
            label=""
            value={formik.values.date}
            onChange={(date) => formik.setFieldValue("date", date)}
            onBlur={formik.handleBlur("date")}
            open={this.state.isDatePickerOpen}
            onClose={() => this.setState({ isDatePickerOpen: false })}
            data-error={hasError}
          />
        </MuiPickersUtilsProvider>
      <img className="icon" src={datePickerIcon} alt="Clock Icon" data-test-id="datePicker" onClick={this.openDatePickerAdd} />
      </StyledComponents>
      </>
    )
  }

  addScreenTime = (formik: FormikProps<FormValues>): JSX.Element => {
    const hasError = !!(formik.touched.time && formik.errors.time);
    return (
      <>
        <StyledTimeInputWrapper className="wrapper time-input-wrapper" data-error={hasError}>
          <StyledIcon src={hasError ? errorClockIcon: clockIcon2} alt="Clock Icon" />
          <TimePicker
            id="time"
            name="time"
            disableClock={true} 
            format="hh:mm a" 
            value={formik.values.time}
            onChange={(time) => formik.setFieldValue("time", time)}
            clearIcon={null} 
            className={`time-input ${this.state.currentLanguage === "ar" ? "rtl" : "ltr"}`}
            aria-label="Select appointment time"
            clockIcon={<img src={clockIcon2} alt="Clock Icon" />}
            data-test-id="timeInput"
          />
        </StyledTimeInputWrapper>
      </>
    );
  };

  BreadcrumbsCustom = (isEdit: boolean): JSX.Element => {
    return (
      <this.BreadcrumbsCust
        separator={this.getValueBasedOnLanguage(
          this.state.currentLanguage === "ar" ? <NavigateNextIcon /> : <NavigateBeforeIcon />,
          this.state.currentLanguage === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />
        )}
        style={{
          color: "#000000",
          height: "9px",
          width: "5px",
          whiteSpace: "nowrap",
          textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
        }}
      >
        <a
          href="#"
          style={{
            ...this.useStyles.navLinks,
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
          }}
        >
          {I18n.t("home")}
        </a>
        <a
          style={{
            ...this.useStyles.navLinks,
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            cursor: "pointer"
          }}
          data-test-id="breadcrumbBtn"
          onClick={this.handleNavigation}
        >
          {I18n.t("appointmentmanagementText")}
        </a>
        <a
          href="#"
          style={{
            ...this.useStyles.navLinks,
            fontWeight: 700,
            color: "#3f51b5",
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
          }}
        >
          {isEdit ? I18n.t("EditAppointmentText") : I18n.t("createNewAppoinmentHeadingText")}
        </a>
      </this.BreadcrumbsCust>
    );
  };

  addCustomer = (formik: FormikProps<FormValues>): JSX.Element => {
    const isRTL = this.state.currentLanguage === "ar";
    const hasError = !!(formik.touched.customer && formik.errors.customer);
    
    return (
      <StyledDropdown>
        <div className="dropdown-container">
          <div
            className="dropdown-button"
            data-rtl={isRTL}
            data-error={hasError}
            data-test-id="customerDropdownButton"
            id="hoverField"
          >
            <span className="icon-container" data-rtl={isRTL}>
              <img
                src={hasError ? errorDropdownPersonIcon : dropdownPersonIcon}
                className="person-icon"
                data-rtl={isRTL}
              />
            </span>

            <Select
              value={formik.values.customer || ""}
              dir={isRTL ? "rtl" : "ltr"}
              displayEmpty
              onChange={(event) => formik.setFieldValue("customer", event.target.value)}
              className="dropdown-text"
              data-rtl={isRTL}
              data-test-id="customerDropdown"
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: isRTL ? "left" : "right" },
                PaperProps: {
                  style: {
                    borderRadius: "8px",
                    position: "absolute",
                    width: "284px",
                    padding: "0",
                    backgroundColor: "#FFFFFF",
                    zIndex: 10,
                    maxHeight: "200px",
                    overflowY: "auto",
                    minWidth: "284px",
                    scrollbarWidth: "thin",
                    scrollbarColor: " #527FE2 #f1f1f1",
                    textAlign: isRTL ? "right" : "left",
                    direction: isRTL ? "rtl" : "ltr",
                  },
                },
                classes: { paper: "custom-menu-paper" },
                getContentAnchorEl: null,
                style: {
                  inset: this.getValueBasedOnLanguage("10px 0px 0 0px", "10px 20px 30px -15px"),
                }
              }}
              renderValue={(selected) => {
                if (!selected) return <span style={{ color: "#aaa" }}>{I18n.t("CustomerName")}</span>;
                const selectedPerson = this.state.allCustomerDetails.find((person:any) => person.id == selected);
                return selectedPerson ? selectedPerson.attributes.full_name : selected;
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    src={dropdownArrow}
                    alt="Dropdown Icon"
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      left: isRTL ? "10px" : "auto",
                      right: isRTL ? "auto" : "10px",
                    }}
                  />
                </InputAdornment>
              }
            >
              {this.state.allCustomerDetails?.map((person:any) => (
                <MenuItem key={person.id} value={person.id} style={{
                  padding: "10px",
                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                  direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                }}>
                  {person.attributes.full_name}
                </MenuItem>
              ))}
            </Select>
          </div>
          {hasError && <div className="error-text">{formik.errors.customer}</div>}
        </div>
      </StyledDropdown>
    );
  }

  personMeet = (formik: FormikProps<FormValues>): JSX.Element => {
    const isRTL = this.state.currentLanguage === "ar";
    const hasError = !!(formik.touched.personToMeet && formik.errors.personToMeet);
    
    return (
      <StyledDropdown>
      <div className="dropdown-container">
        <div
          className="dropdown-button"
          data-rtl={isRTL}
          data-error={hasError}
          data-test-id="customerDropdownButton"
          id="hoverField"
        >
          <span className="icon-container" data-rtl={isRTL}>
            <img
              src={hasError ? errorDropdownPersonIcon : dropdownPersonIcon}
              className="person-icon"
              data-rtl={isRTL}
            />
          </span>

          <Select
            value={formik.values.personToMeet || ""}
            dir={isRTL ? "rtl" : "ltr"}
            displayEmpty
            onChange={(event) => formik.setFieldValue("personToMeet", event.target.value)}
            className="dropdown-text"
            data-rtl={isRTL}
            data-test-id="personDropdown"
            MenuProps={{
              anchorOrigin: { vertical: "bottom", horizontal: isRTL ? "left" : "right" },
              PaperProps: {
                style: {
                  borderRadius: "8px",
                  position: "absolute",
                  width: "284px",
                  padding: "0",
                  backgroundColor: "#FFFFFF",
                  zIndex: 10,
                  maxHeight: "200px",
                  minWidth: "284px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#527FE2 #f1f1f1",
                  textAlign: isRTL ? "right" : "left",
                  direction: isRTL ? "rtl" : "ltr",
                  overflowY: "auto",
                },
              },
              classes: { paper: "custom-menu-paper" },
              getContentAnchorEl: null,
              style: {
                inset: this.getValueBasedOnLanguage("10px 0px 0 0px", "10px 20px 30px -15px"),
              },
            }}
            renderValue={(selected) => {
              if (!selected) return <span style={{ color: "#aaa" }}>{I18n.t("AddApoinmentPersontomeet")}</span>;
              const selectedPerson = this.state.allEmployeeDetails.find((person:any) => person.id === selected);
              return selectedPerson ? selectedPerson.name : selected;
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src={dropdownArrow}
                  alt="Dropdown Icon"
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    left: isRTL ? "10px" : "auto",
                    right: isRTL ? "auto" : "10px",
                  }}
                />
              </InputAdornment>
            }
          >
            {this.state.allEmployeeDetails?.map((person:any) => (
              <MenuItem key={person.id} value={person.id} style={{
                padding: "10px",
                textAlign: this.getValueBasedOnLanguage("right", "left"),
                direction: this.getValueBasedOnLanguage("rtl", "ltr"),
              }}>
                {person.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {hasError && <div className="error-text">{formik.errors.personToMeet}</div>}
      </div>
      </StyledDropdown>
    );
  }

  muiBox = (formik: FormikProps<FormValues>): JSX.Element => {
    const isRTL = this.state.currentLanguage === "ar";
    const urlParts = window.location.pathname.split("/");
    const isEdit = urlParts.length > 2 && !isNaN(Number(urlParts[2]));
    
    return (
      <StyledMuiBox>
        <Box
          className="main-box"
          dir={isRTL ? "rtl" : "ltr"}
        >
          <div
            className="container"
            data-rtl={isRTL}
          >
            <div className="button-container">
              <button
                type="submit"
                className="submit-button"
                data-test-id="modalopen"
                data-submitting={formik.isSubmitting}
                disabled={formik.isSubmitting}
                style={{
                  ...this.useStyles.ButtonStyles,
                }}
              >
                {I18n.t("Save")}
              </button>
  
              <ModalStyles
                open={this.state.openSuccessModal}
                onClose={this.handleClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
              >
                <Box
                  className="modal-content"
                  dir={isRTL ? "rtl" : "ltr"}
                >
                  <img src={modalSuccessIcon} alt="Success Icon" />
                  <Typography 
                    id="success-modal-description" 
                    variant="h6" 
                    className="success-title"
                  >
                    {isEdit ? I18n.t("EditApoinmentSuccessmsg"): I18n.t("AddApoinmentSuccessmsg")}
                  </Typography>
                  <button 
                    onClick={this.handleClose} 
                    className="cancel-button"
                    data-test-id="success-modal-close-button"
                    style={{
                      ...this.useStyles.Cancelbtn,
                    }}
                  >
                    {I18n.t("AddAppoinmentcancelBtnText")}
                  </button>
                </Box>
              </ModalStyles>
  
              <ModalStyles
                open={this.state.openErrorModal}
                onClose={this.handleClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
              >
                <Box
                  className="modal-content"
                  dir={isRTL ? "rtl" : "ltr"}
                >
                  <img src={modalErrorIcon} alt="Error Icon" />
                  <Typography 
                    id="error-modal-description" 
                    variant="h6" 
                    className="error-title"
                  >
                    {I18n.t("AddApoinmentErrormsg")}
                  </Typography>
                  <div className="error-buttons-container">
                    <button
                      onClick={this.handleTryAgain}
                      className="try-again-button"
                      data-test-id="error-modal-try-again-button"
                    >
                      {I18n.t("AddAppoinmentTryAgain")}
                    </button>
                    <button
                      onClick={this.handleClose}
                      className="error-cancel-button"
                      data-test-id="error-modal-cancel-button"
                    >
                      {I18n.t("AddAppoinmentcancelBtnText")}
                    </button>
                  </div>
                </Box>
              </ModalStyles>
            </div>
          </div>
        </Box>
      </StyledMuiBox>
    );
  }

  addScreenAttendee = (formik: FormikProps<FormValues>): JSX.Element => {
    const isRTL = this.state.currentLanguage === "ar";
    const isEmpty = !formik.values.attendee;
    const hasError = !!(formik.touched.attendee && formik.errors.attendee);
    
    return (
      <StyledAttendeeInput className="wrapper">
        <div
          className="attendee-container"
          data-rtl={isRTL}
        >
          <img src={hasError ? errorAttendeesIcon : attendeesIcon2} alt="Attendees Icon" />
          <label
            htmlFor="attendee"
            className="attendee-label"
            data-rtl={isRTL}
          />
          <input
            type="text"
            id="attendee"
            name="attendee"
            value={formik.values.attendee}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-test-id="attendeesInput"
            className="attendee-input"
            data-rtl={isRTL}
            data-empty={isEmpty}
            data-error={hasError}
            placeholder={I18n.t("AddApoinmentAttendes")}
            aria-label="Add attendees"
          />
        </div>
      </StyledAttendeeInput>
    );
  }

  addNotes = (formik: FormikProps<FormValues>): JSX.Element => {
    const isRTL = this.state.currentLanguage === "ar";
    const isEmpty = !formik.values.notes;
    const hasError = !!(formik.touched.notes && formik.errors.notes);
    
    return (
      <StyledNotesInput className="wrapper">
        <div
          className="notes-container"
          data-rtl={isRTL}
        >
          <img src={hasError ? errorDatePickerIcon : datePickerIcon} alt="Date Icon" />
          <label
            className="notes-label"
            data-rtl={isRTL}
            htmlFor="notes"
          />
          <input
            id="notes"
            name="notes"
            value={formik.values.notes}
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-test-id="notesInput"
            className="notes-input"
            data-rtl={isRTL}
            data-empty={isEmpty}
            data-error={hasError}
            aria-label="Notes"
            placeholder={I18n.t("AddApoinmentNotes")}
          />
        </div>
      </StyledNotesInput>
    );
  };

  renderInputField = (field: keyof FormValues, idx: number, formik: FormikProps<FormValues>): JSX.Element => {
    const fieldComponents: Record<string, () => JSX.Element> = {
      time: () => this.addScreenTime(formik),
      notes: () => this.addNotes(formik),
      attendee: () => this.addScreenAttendee(formik),
      date: () => this.addScreenDate(formik),
    };

    return (
      <div
        style={{
          height: field === "date" ? "55px" : "auto",
          marginBottom: "23px",
          position: "relative"
        }}
        key={idx}
      >
        <div
          style={{
            flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            fontWeight: 800,
            fontFamily: "Poppins",
            border: "none"
          }}
          id="clockIconHide"
          className="hoverField5"
        >
          {fieldComponents[field] ? fieldComponents[field]() : null}
        </div>
        {formik.touched[field] && formik.errors[field] && (
          <div style={{...this.useStyles.errorText, 
            textAlign: (this.state.currentLanguage === "ar" ? "right" : "left") as React.CSSProperties["textAlign"],
          }}>
            {formik.errors[field]}</div>
        )}
      </div>
    );
  };

  render() {
    // Customizable Area Start
    const urlParts = window.location.pathname.split("/");
    const isEdit = urlParts.length > 2 && !isNaN(Number(urlParts[2]));

    const initialValues: FormValues = {
      date: this.state.selectedDate,
      time: this.state.time,
      attendee: this.state.attendee?.toString(),
      notes: this.state.notes,
      customer: this.state.selectedCustomer,
      personToMeet: this.state.selectedPerson,
    };

    return (
      <div style={{ display: 'flex', backgroundColor: '#F1F5FC', flexDirection: this.state.currentLanguage === "ar" ? 'row-reverse' : "row", overflow: "auto", height: "100vh" }} className="add-app-page">
        <AppNavigation />
        <div className={this.state.currentLanguage === "ar" ? "arabic-text" : "default-text"} style={{ padding: "1rem", flex: 1, marginRight: this.state.currentLanguage === 'ar' ? "325px" : undefined }}>
          <ThemeProvider theme={theme}>
            <div style={{ padding: "1rem 0" }} >
              <Box
                style={{
                  width: "96%",
                  alignSelf: "center",
                  margin: "3rem 0",
                  direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
                }}
              >
                {this.BreadcrumbsCustom(isEdit)}
              </Box>

              <this.CardBox
                id="card-box-id"
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ padding: "1rem 0", width: "96%", alignSelf: "center" }}>
                  <Typography
                    noWrap
                    variant="h6"
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      marginBottom: "20px",
                      fontSize: "20px",
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                  {isEdit ? I18n.t("AppointmentDetailsText") : I18n.t("createNewAppoinmentHeadingText") }
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleFormSubmit}
                    enableReinitialize={true}
                    data-test-id="formikComponent"
                    >
                    {formik => (
                      <form onSubmit={formik.handleSubmit}>
                        {this.addCustomer(formik)}
                        {this.personMeet(formik)}
                        {["date", "time", "attendee", "notes"].map((field, idx) => (
                          this.renderInputField(field as keyof FormValues, idx, formik)
                        ))}
                        {this.muiBox(formik)}
                      </form>
                    )}
                  </Formik>
                </div>
              </this.CardBox>
            </div>
          </ThemeProvider>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalStyles = styled(Modal) ({
'& .modal-content': {
    padding: '16px 24px',
    background: '#fff',
    borderRadius: '8px',
    width: '400px',
    textAlign: 'center',
    top: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '& .success-title': {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '10px',
  },
  '& .cancel-button': {
  },
  '& .error-title': {
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: '16px',
    fontSize: '14px',
  },
  '& .error-buttons-container': {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .try-again-button': {
    backgroundColor: '#527FE2',
    color: '#FFFFFF',
    padding: '10px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    width: '400px',
    border: 'none',
    borderRadius: '8px',
    marginBottom: '10px',
  },
  '& .error-cancel-button': {
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    color: '#527FE2',
    padding: '10px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    width: '400px',
    border: '1px solid #527FE2',
  },
})

const StyledDropdown = styled('div')({
  "& ::before, :after": {
    display: "none"
  },
  "& .MuiSelect-select:focus": {
    background: "#fff"
  },

  '& .dropdown-container': {
    marginBottom: '23px',
    position: 'relative',
    width: '100%',
  },
  '& .dropdown-button': {
    width: 'auto',
    borderRadius: '8px',
    height: '32px',
    display: 'flex',
    fontSize: '16px',
    color: '#94A3B8',
    fontFamily: 'Poppins',
    padding: '10px 8px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
  },
  '& .dropdown-button[data-rtl="true"]': {
    flexDirection: 'row-reverse',
  },
  '& .dropdown-button[data-error="true"]': {
    border: '1px solid #FF4D4F !important',
  },
  '& .icon-container': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .icon-container[data-rtl="false"]': {
    left: '10px',
  },
  '& .icon-container[data-rtl="true"]': {
    right: '10px',
  },
  '& .person-icon[data-rtl="false"]': {
    marginRight: '10px',
  },
  '& .person-icon[data-rtl="true"]': {
    marginLeft: '10px',
  },
  '& .dropdown-text': {
    flex: 1,
    fontSize: '16px',
    "& svg": {
      display: "none"
    },
    "& .MuiSelect-selectMenu": {
      paddingRight: 0
    }
  },
  '& .dropdown-text[data-rtl="false"]': {
    textAlign: 'left',
    marginLeft: '20px',
  },
  '& .dropdown-text[data-rtl="true"]': {
    textAlign: 'right',
    marginRight: '30px',
  },
  '& .dropdown-text[data-selected="true"]': {
    color: 'black',
  },
  '& .dropdown-text[data-selected="false"]': {
    color: '#94A3B8',
  },
  '& .arrow-icon': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .arrow-icon[data-rtl="false"]': {
    right: '20px',
  },
  '& .arrow-icon[data-rtl="true"]': {
    left: '20px',
  },
  '& .error-text': {
    color: '#FF4D4F',
    fontSize: '12px',
    marginTop: '1px',
    position: "absolute", 
    width: "100%", 
    textAlign: getValueBasedOnLanguage("right", "left")
  },
  '& .dropdown-list': {
    borderRadius: '8px',
    position: 'absolute',
    width: '284px',
    padding: '0',
    top: '110%',
    backgroundColor: '#FFFFFF',
    zIndex: 10,
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  '& .dropdown-list[data-rtl="false"]': {
    right: '0',
  },
  '& .dropdown-list[data-rtl="true"]': {
    left: '0',
  },
  '& .dropdown-item': {
    padding: '10px 16px',
    cursor: 'pointer',
  },
  '& .dropdown-item:hover': {
    backgroundColor: '#DBEAFE',
  },
});

const StyledMuiBox = styled('div')({
  '& .main-box': {
  },
  '& .container': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .container[data-rtl="true"]': {
    flexDirection: 'row-reverse',
  },
  '& .container[data-rtl="false"]': {
    flexDirection: 'row',
  },
  '& .button-container': {
    margin: '2rem 0 3rem 0',
  },
  '& .submit-button': {
    cursor: 'pointer',
  },
  '& .submit-button[data-submitting="true"]': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
});
const StyledAttendeeInput = styled('div')({
  '& .attendee-container': {
    width: '100%',
    display: 'flex',
  },
  '&.wrapper': {
    border: "1px solid #CBD5E1", 
    borderRadius: '8px',
    padding: "10px",
    height: "30px", 
    width: "100%"
  },
 '&.wrapper:has(.attendee-input[data-error="true"])': {
    border: '1px solid #FF4D4F',
    borderRadius: '8px',
  },
  '& .attendee-container[data-rtl="false"]': {
    flexDirection: 'row',
  },
  '& .attendee-container[data-rtl="true"]': {
    flexDirection: 'row-reverse',
  },
  '& .attendee-label': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .attendee-label[data-rtl="false"]': {
    marginRight: '10px',
    marginLeft: '0',
  },
  '& .attendee-label[data-rtl="true"]': {
    marginRight: '0',
    marginLeft: '10px',
  },
  '& .attendee-input': {
    flex: 1,
    fontSize: '16px',
    fontFamily: 'Poppins',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    width: '100%',
  },
  '& .attendee-input[data-rtl="false"]': {
    textAlign: 'left',
  },
  '& .attendee-input[data-rtl="true"]': {
    textAlign: 'right',
  },
  '& .attendee-input[data-empty="true"]': {
    color: '#94A3B8',
  },
  '& .attendee-input[data-empty="false"]': {
    color: 'black',
  },
});

const StyledNotesInput = styled('div')({
  '&.wrapper': {
    border: "1px solid #CBD5E1", 
    borderRadius: '8px',
    padding: "10px",
    height: "30px", 
    width: "100%"
  },
 '&.wrapper:has(.notes-input[data-error="true"])': {
    border: '1px solid #FF4D4F',
    borderRadius: '8px',
  },
  '& .notes-container': {
    display: 'flex',
    width: '100%',
  },
  '& .notes-container[data-rtl="false"]': {
    flexDirection: 'row',
  },
  '& .notes-container[data-rtl="true"]': {
    flexDirection: 'row-reverse',
  },
  '& .notes-label': {
    alignItems: 'center',
    display: 'flex',
  },
  '& .notes-label[data-rtl="false"]': {
    marginRight: '10px',
    marginLeft: '0',
  },
  '& .notes-label[data-rtl="true"]': {
    marginRight: '0',
    marginLeft: '10px',
  },
  '& .notes-input': {
    fontSize: '16px',
    flex: 1,
    border: 'none',
    fontFamily: 'Poppins',
    background: 'transparent',
    width: '100%',
    outline: 'none',
  },
  '& .notes-input[data-rtl="false"]': {
    textAlign: 'left',
  },
  '& .notes-input[data-rtl="true"]': {
    textAlign: 'right',
  },
  '& .notes-input[data-empty="true"]': {
    color: '#94A3B8',
  },
  '& .notes-input[data-empty="false"]': {
    color: 'black',
  },
});

const StyledComponents = styled("div") ({
  '&.wrapper': {
    border: "1px solid #CBD5E1", 
    borderRadius: '8px',
    padding: "10px",
    height: "30px", 
    width: "100%", 
    display: "flex"
  },
 '&.wrapper:has(.date-picker[data-error="true"])': {
    border: '1px solid #FF4D4F',
    borderRadius: '8px',
  },

  "&.notes-input": {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  "&.rtl": {
    flexDirection: "row-reverse",
  },
  "& .date-picker-wrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .date-picker": {
    marginTop: "5px",
    paddingTop: "2px",
    fontFamily: "Poppins",
    width: "100%", 
    "& input": {
      textAlign: getValueBasedOnLanguage("right", "left"),
      marginRight: getValueBasedOnLanguage("5px", "0")
    }
  },
  "& .date-picker[data-empty='true'] .MuiInputBase-input": {
    color: "#ccc",
  },
  "& .icon": {
    margin: getValueBasedOnLanguage("5px 0px 5px 10px", "5px 5px 5px 0px"),
    padding: "0px 5px 0px 0px",
    cursor: "pointer",
  },
 
})

const StyledTimeInputWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",
  width: "100%",
  backgroundColor: "white",
  '&.wrapper': {
    border: "1px solid #CBD5E1", 
    borderRadius: '8px',
    padding: "10px",
    height: "30px", 
    width: "100%", 
    display: "flex"
  },
  '&.wrapper[data-error="true"]': {
    border: '1px solid #FF4D4F',
    borderRadius: '8px',
    boxShadow: "none"
  },
  "& .icon": {
    margin: getValueBasedOnLanguage("5px 0px 5px 10px", "5px 5px 5px 0px"),
    padding: "0px 5px 0px 0px",
    cursor: "pointer",
  },
  "& .time-input-wrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .react-time-picker__wrapper": {
    border: "none !important"
  },
  "& .react-time-picker__inputGroup": {
    fontWeight: 400, 
    fontFamily: "Poppins"
  },
  "& .react-time-picker__button:enabled": {
    "&:hover": {
      background: "#fff"
    }
  },
  "& .time-label": {
    display: "flex",
    alignItems: "center",
  },
  "& .time-label.rtl": {
    marginRight: "0",
  },
  "& .time-label.ltr": {
    marginLeft: "0",
  },
  "& .time-input": {
    flex: 1,
    fontSize: "16px",
    color: "#000",
    border: "none !important",
    outline: "none",
    background: "transparent",
    appearance: "none",
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    fontFamily: "Poppins",
    width: "100%"
  },
  "& .time-input.rtl": {
    textAlign: "right",
  },
  "& .time-input.ltr": {
    textAlign: "left",
  },
});

const StyledIcon = styled("img")({
  width: "20px",
  height: "20px",
  marginRight: "8px",
  opacity: 0.7,
});

// Customizable Area End
