Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "bx_block_catalogue/catalogues";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePatch = "PATCH";
exports.apiMethodTypeDelete = "DELETE";
// Customizable Area End