import React from "react";
// Customizable Area Start
import { Box, Button, Card,  Grid, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { getValueBasedOnLanguage as ShowArabicCSS, InputField as StyledInput, TextAreaField} from "../../../components/src/constant"
import { InputTypesFields } from "../../../components/src/types"
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
// Customizable Area End

import AddServiceController, { Props } from "./AddserviceController.web";
import { checked, unchecked } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddService extends AddServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b", direction: ShowArabicCSS("rtl", "ltr") }}
      >
        {value}
      </Typography>

    );
  }

  renderDescriptionTextAreaSection = (inputFields : InputTypesFields) => {
    const { name, placeholder,value,errorKey, errorMsg } = inputFields
    return (
      <Box>
        <TextAreaField
          data-test-id={name}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={4}
          onChange={this.handleInputChange}
          value={value}
          name={name}
          error={errorKey}
          style={{
            textAlign: ShowArabicCSS("right", "left"),
            direction: ShowArabicCSS("rtl", "ltr"),
          }}
        />
        {errorKey && this.getErrorMessageNonFormik(errorMsg)}
      </Box>
    )
  }

  renderInputSection = (fields: InputTypesFields, disabled: boolean = false) => {
    const { name, placeholder, value, errorKey, errorMsg } = fields
    return (
      <Box>
        <StyledInput
          data-test-id={name}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          name={name}
          error={errorKey}
          disabled={disabled}
          onChange={this.handleInputChange}
          style={{
            direction: ShowArabicCSS("rtl", "ltr"),
            textAlign: ShowArabicCSS("right", "left"),
          }}
        />
        {errorKey && this.getErrorMessageNonFormik(errorMsg)}
      </Box>
    )
  }

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex', flexDirection: ShowArabicCSS('row-reverse', "row")}}>
        <AppNavigation />
        <div className={ShowArabicCSS("arabic-text", "default-text")} style={{flex:1, minHeight: "calc(100vh - 100px)", backgroundColor:"#F1F5FC", padding:'50px 0 50px 20px', maxHeight:'100%', marginRight: ShowArabicCSS(325, 0)}}>
          <ThemeProvider theme={theme}>
            <Box style={{ paddingRight: "20px" }}>
              <DynamicBreadcrumbs 
                breadcrumbItems={
                  [I18n.t("home"),
                   I18n.t("serviceManagement"), 
                   this.getLayoutValues(I18n.t("addNewServiceText"), I18n.t("editServiceText"))
                  ]} 
                  navigation={this.props.navigation} 
                  pathToNavigate="Catalogue"
                />
            </Box>
            <div style={{ maxWidth: "100vw", display: "flex", justifyContent: "center",padding:"0 0 0 0" }}>
              <Box style={{backgroundColor:"#527FE2",padding:"24px 8px 24px 8px",width:"100%",borderRadius:"0 0 0 24px",}}>
                <Typography style={{
                  fontFamily: ShowArabicCSS("Noto Sans Arabic", "Poppins") ,
                  fontWeight: 700,
                  fontSize: "24px",
                  color: "ffffff",
                  textAlign: "center"
                }}>
                  { this.getLayoutValues(I18n.t("addNewServiceText"), I18n.t("editServiceText"))}
              </Typography>
              </Box>
          
            </div>
            <Box style={{ maxWidth: "100vw", display: "flex",flexDirection:"column",gap:"1.5rem", justifyContent: "center",padding:"2.5rem 1.5rem 1.5rem 0" }}>

              <Card style={{ maxWidth: "100vw", boxSizing:'border-box', display: "flex",flexDirection:"column",gap:"1.5rem", 
              justifyContent: "center",padding:"1.5rem 1.5rem" ,borderRadius:"12px",border:"solid 1px #E2E8F0"}}>
                <Grid item sm={12} xs={12} md={12}  >
                  {this.renderInputSection({
                    name: "service_name_en",
                    value: this.state.serviceFormFields.service_name_en,
                    errorKey: this.state.serviceErrorFields.service_name_en,
                    placeholder: I18n.t("serviceNameEnglish"),
                    errorMsg: I18n.t("addServiceNameEnglishErrorText")
                  })}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >

                  {this.renderInputSection({
                    name: "service_name_ar",
                    value: this.state.serviceFormFields.service_name_ar,
                    errorKey: this.state.serviceErrorFields.service_name_ar,
                    placeholder: I18n.t("serviceNameArabic"),
                    errorMsg: I18n.t("addServiceNameArabicErrorText")
                  })}
                </Grid>
                
                <Grid item sm={12} xs={12} md={12}  >
                  {this.renderDescriptionTextAreaSection({
                    name: "service_desc_en",
                    value: this.state.serviceFormFields.service_desc_en,
                    errorKey: this.state.serviceErrorFields.service_desc_en,
                    placeholder: I18n.t("Description (EN)*"),
                    errorMsg: I18n.t("serviceDescEnErrorMsg")
                  })}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  {this.renderDescriptionTextAreaSection({
                    name: "service_desc_ar",
                    value: this.state.serviceFormFields.service_desc_ar,
                    errorKey: this.state.serviceErrorFields.service_desc_ar,
                    placeholder: I18n.t("Description (AR)*"),
                    errorMsg: I18n.t("serviceDescArErrorMsg")
                  })}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  {this.renderInputSection({
                    name: "service_price",
                    value: this.state.serviceFormFields.service_price,
                    errorKey: this.state.serviceErrorFields.service_price,
                    placeholder: I18n.t("addServicePrice"),
                    errorMsg: I18n.t("addServicePriceErrorText")
                  })}
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Box data-test-id="checkbox" style={{ display: "flex", flexDirection: ShowArabicCSS("row-reverse", "row") ,alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={this.handleCHeckPrice}>
                    <img style={{ width: "20px", height: "20px" }} src={this.state.PickupChecked ? checked : unchecked} alt="checkbox" />
                    <Typography style={{ fontFamily: ShowArabicCSS("Noto Sans Arabic", "Poppins"), fontSize: "1rem", color: "#000000" }}>{I18n.t("servicePickupFeesText")}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12}   >
                  {this.renderInputSection({
                    name: "service_pick_up_fees",
                    value: this.state.serviceFormFields.service_pick_up_fees,
                    errorKey: this.state.serviceErrorFields.service_pick_up_fees,
                    placeholder: I18n.t("servicePickupFees"),
                    errorMsg: I18n.t("addServiceDelieveryErrorText")
                  }, !this.state.PickupChecked)}
                </Grid>
            
              </Card>
            </Box>

            <Box style={{display:"flex",justifyContent:"center",margin:"2rem"}}> 
            <AddServiceBtn data-test-id="addService" onClick={this.saveServiceData}>
              {this.getLayoutValues(I18n.t("addServiceBtn"), I18n.t("Save"))}
            </AddServiceBtn>
            </Box>
          </ThemeProvider>
        </div>

        {/* Success Modal */}
        <SuccessModal
          open={this.state.successModal}
          handleClose={this.closeSuccessModal}
          msg_en={this.getLayoutValues(I18n.t("serviceAddApiMsg"), I18n.t("serviceEditApiMsg"))}
          msg_ar={this.getLayoutValues(I18n.t("serviceAddApiMsg"), I18n.t("serviceEditApiMsg"))}
          data-test-id="successModal"
        />

        {/* Error Modal */}
        <ErrorModal
          data-test-id="errorModal"
          open={this.state.errorModal}
          handleClose={this.closeErrorModal}
        />
      </div>
      

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start


const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white",
    backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",
    padding: "10px 16px 10px 16px",
  },
});

  
  
  

// Customizable Area End
