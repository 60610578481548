export const greyTicket = require("../assets/greyTicket.svg");
export const tickets = require("../assets/tickets.svg");
export const pdfIcon = require("../assets/pdfIcon1.png");
export const user = require("../assets/image.png");
export const userIcon = require("../assets/user.png");
export const profileimg = require("../assets/profileimg.png");
export const bro_2x = require("../assets/bro_2x.png");
export const send_icon = require("../assets/send.png");
export const noData = require("../assets/no_data.png");
export const checkedIcon = require("../assets/checkedIcon.svg");
export const unCheckedIcon = require("../assets/unCheckedIcon.svg");
export const unCheckedTicketIcon = require("../assets/unCheckedTicketIcon.svg")