import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Card,
  Breadcrumbs
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { FilterList, SearchOutlined, ChatBubbleOutline } from "@material-ui/icons";
import { I18n } from "../../../components/src/languageJson/i18n";
import { tickets, noData, unCheckedTicketIcon } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from '@material-ui/lab/Pagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Link } from "react-router-dom";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { getValueBasedOnLanguage as showCss } from "../../../components/src/constant";
import FilterDialog from "../../../components/src/FilterDialog.web";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import { FilterHttpBodyProps } from "../../../components/src/types";
import { getDate, getMonth, getYear, getTime } from "../../../components/src/DateUtils.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const subOptionsTitle = [`${I18n.t("all")}`, `${I18n.t("service")}`, `${I18n.t("suggestion")}`, `${I18n.t("complain")}`];
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   optionsTitle = [
    { title: I18n.t("tickets"),icon:  <img src={tickets} height={32} width={32} />, unCheckedIcon : <img src={unCheckedTicketIcon} height={32} width={32}/>  },
    { title: I18n.t("warranties"), icon: <CheckCircleIcon height={32} width={32}/>, unCheckedIcon : <CheckCircleIcon height={32} width={32}/> },
    { title: I18n.t("chat"), icon: <ChatBubbleOutline height={32} width={32}/>, unCheckedIcon: <ChatBubbleOutline height={32} width={32}/> },
    { title: I18n.t("reviews"), icon: <CalendarViewDayIcon height={32} width={32}/>, unCheckedIcon : <CalendarViewDayIcon height={32} width={32}/>},
  ];

  useStyles = {
    statusProgess:{
      height:"22px",
      minWidth:"99px",
      fontSize:"14px",
      fontWeight: 700,
      fontFamily: '"Poppins", "sans-serif"',
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#527FE2",
      backgroundColor:"#BFDBFE",
      padding:'2px 8px 2px 8px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusPendding:{
      height:"22px",
      minWidth:"99px",
      fontSize:"14px",
      fontWeight: 700,
      fontFamily: '"Poppins", "sans-serif"',
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#D97706",
      backgroundColor:"#FEF3C7",
      padding:'2px 8px 2px 8px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusFinished:{
      height:"22px",
      minWidth:"99px",
      fontSize:"14px",
      fontWeight: 700,
      fontFamily: '"Poppins", "sans-serif"',
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#059669",
      backgroundColor:"#D1FAE5",
      padding:'2px 8px 2px 8px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusReviewing:{
      height:"22px",
      minWidth:"99px",
      fontSize:"14px",
      fontWeight: 700,
      fontFamily: '"Poppins", "sans-serif"',
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#6A1B9A",
      backgroundColor:"#E1BEE7",
      padding:'2px 8px 2px 8px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableRow: {
      margin: "5px 0 5px 0",
      display: "flex",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      width:"100%",
      cursor:"pointer",
      height:'auto'
    },
    tableHead: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFFFFF",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      whiteSpace:"nowrap" as "nowrap",
    },
    tableBody: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableBodyEnglishFont : {
      fontFamily:  "Poppins",
    },
    tableBodyArabicFont : {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    tableBodyStatus:{
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      display:"flex",
      gap:20,
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    subOption:{ 
      fontSize: "16px", 
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    options:{ 
      fontSize: "14px", 
      lineHeight: "24px", 
      fontWeight: 700, 
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
    },
    navLinks:{
      fontWeight:400,
      fontSize:"14px",
      fontFamily:this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      color:"#000000",
      textDecoration:"none",
    },
  }

  adornmentStyle = {
    order: this.getValueBasedOnLanguage(1, 0),
    marginRight: this.getValueBasedOnLanguage(8, 0),
    marginLeft: this.getValueBasedOnLanguage(0, 8),
  };

  PaginationBox = styled(Box)({
      width:'100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '30px',
      direction: showCss("rtl", "ltr"),
      marginTop: '10px',
      position: 'relative',
      '@media (max-width: 600px)': {
          flexDirection: 'column',
          },
      '& .setCount':{
        position: 'absolute',
        right: showCss("auto", "0"), 
        top:'50%', 
        color: '#64748B',
        marginRight:'20px',
        transform: 'translateY(-50%)',
        direction: "ltr",
        '@media (max-width: 600px)': {
          position: 'static',
          marginTop: '10px',
          transform: 'none',
          textAlign: 'center'
          }
      },
      "& .MuiPagination-ul li:last-child": {
        transform: showCss("rotate(180deg)", "rotate(0deg)")
      },
      "& .MuiPagination-ul li:first-child": {
        transform: showCss("rotate(180deg)", "rotate(0deg)")
      },
      "& .MuiPaginationItem-page": {
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Poppins",
        textDecoration: "underline",
        color: "black",
      },
      "& .MuiPaginationItem-page.Mui-selected": {
        fontWeight: 700,
        fontSize: "12px",
        color: "#527FE2",
        textDecoration: "none",
        fontFamily: "Poppins",
        background: "white"
      },
    "& .rightSideText": {
      right: "70px"
    },
    "& .leftSideText": {
      left: "70px"
    },
  });

  RequestsList = styled(Box)({
    padding:'0 20px 0 20px', 
    display: "block", 
    marginTop: "1rem", 
    overflow:"auto", 
    whiteSpace: "nowrap",
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    '&::-webkit-scrollbar': {
      width: '6px',
      height:'6px',
      borderRadius: '10px', 
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#527FE2',
      borderRadius: '10px', 
      border: '2px solid #527FE2', 
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor:'#f1f1f1',
      borderRadius: '10px',
      width:'6px'
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent', 
    }, 
    '& .setPagination':{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '10px',
    position: 'relative',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        },
    '& .setCount':{
      position: 'absolute',
      right: '0', 
      top:'50%', 
      color: '#64748B',
      transform: 'translateY(-50%)',
      '@media (max-width: 600px)': {
        position: 'static',
        marginTop: '10px',
        transform: 'none',
        textAlign: 'center'
        }
    }
  },
  })
  
  CardBox = styled(Card)({
    overflowY: "auto", 
    maxHeight: "704px",
    backgroundColor: "fffffff",
    border: "1 px solid #CBD5E1",
    width: "100%", 
    margin: "auto", 
    borderRadius: "1.5rem",
    display:"flex",
    justifyContent:"space-between",
    flexDirection:"column",
    position: "relative",
    "@media (max-width:560px)": {
      overflow: "scroll",
      display: "flex"
    },

  });

  TableCellHead = styled(TableCell)({
    fontWeight: 700,
    border: "none", 
    backgroundColor: "#527FE2", 
    color: "ffffff",
    fontFamily: "Poppins",
    fontSize: "1rem", padding: "8px 24px",
    height: "64px",
    display:"flex",
    alignItems:"center",
    minWidth:'100px',
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
  });

  TableCellBody = styled(TableCell)({
    border: "none", 
    height: '64px', 
    fontFamily: "Poppins", 
    fontSize: "16px", 
    padding: "8px 24px",
    display:"flex",
    alignItems:"center",
    flex:1,
    minWidth:"100px",
    whiteSpace:"nowrap"
  });

  InputField = styled(TextField)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    width: "100%",
    backgroundColor: "#FFFFFF",
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    borderRadius: this.getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      opacity: 1,
    },
    "& .MuiInputBase-root": {
      height: "44px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0 8px",
      height: "100%",
      boxSizing: "border-box",
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: this.getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    },
    "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#527FE2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
          borderColor: "#94A3B8", 
        },
        "&:hover fieldset": {
          borderColor: "#527FE2", 
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#527FE2", 
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
    },
    "@media (max-width:990px)": {
      minWidth: "unset",
      maxWidth: "unset",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  });

  AddServiceBtn = styled(Button)({
    color: "white",
    borderRadius: "8px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontWeight: 700,
    fontSize: "16px",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    width: "306px",
    lineHeight: "24px",
    height: "44px",
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    "&:hover": {
      color: "white", backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
      fontSize: "18px", padding: "10px 16px 10px 16px",
    },
  });

  boxStyle = (index: any) => ({
    borderRadius: "6px",
    border: this.state.selected === index ? "0.4px solid #2D8CFF" : "0.4px solid #64748B",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    backgroundColor: this.state.selected === index ? "#2D8CFF" : "#F1F5FC",
    color: this.state.selected === index ? "#FFFFFF" : "#64748B",
    cursor: "pointer",
    alignItems: "center",
  });
  
  BoxFirst = styled(Box)({
    paddingLeft: this.getValueBasedOnLanguage("", "20px"),
    paddingRight: this.getValueBasedOnLanguage("20px", ""),  
    height: "56px",
    width:'100%',
    maxWidth:'205px',
    minWidth:'145px',
    display: "flex",
    gap: "14px",
    "@media (max-width: 1650px)":{
      maxWidth:'180px',
      minWidth:'145px',
    },
    "@media (max-width: 430px)":{
      width: "80px",
      gap: "4px",
      flexWrap:"wrap",
      padding:5,
      textAlign:"center",
      justifyContent:"center",
      paddingLeft:0,
      paddingRight:0,
    },
    "& .checkedIconStyle" :{
      borderRadius: "6px",
      border: "0.4px solid #2D8CFF" ,
      backgroundColor: "#2D8CFF" ,
      color: "#FFFFFF",
    },
    "& .unCheckedIconStyle" : {
      borderRadius: "6px",
      border: "0.4px solid #64748B",
      backgroundColor: "#F1F5FC",
      color: "#64748B",
    }
  })

  boxSubStyle = (value: string) => ({
    height: "34px",
    minWidth: 35,
    borderRadius: 50,
    border: this.state.selectedCatogoryFilter === value ? "1px solid #A5B4FC" : "",
    display: "flex",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    backgroundColor: this.state.selectedCatogoryFilter === value ? "#DBEAFE" : "",
    color: this.state.selectedCatogoryFilter === value ? "#527FE2" : "#0F172A",
    cursor: "pointer",
    gap: "40px",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 12px",
  });

  boxSub = styled(Box)({
    display: "flex", 
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), 
    gap: "14px", 
    width:"100%",
    justifyContent: "center",
    "@media (max-width: 430px)":{
      gap: "4px"
    },
    "@media (max-width: 380px)":{
      gap: "2px"
    },
  });

  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex', 
    flexWrap: 'nowrap', 
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });

  statusCheck = (statusCheck: string) => {
    switch (statusCheck) {
      case "Pending":
        return this.useStyles.statusPendding;
      case "In_Progress":
        return this.useStyles.statusProgess;
      case "Finished":
        return this.useStyles.statusFinished;
      case "Reviewing":
        return this.useStyles.statusReviewing;
      default:
        return {};
    }
  };

  renderChipBox = (title : string, key : keyof FilterHttpBodyProps, arrayValues: string[]) => {
    const filtersValues = key === "status" ? title.replace(" ", "_") : title
    return (
      <Box className="chipBox">
         <Typography className="chipHeaderText">{this.handleChipTitle(title,key)}</Typography>
         <CloseIcon  className="crossIcon" data-test-id={"crossIcon"} onClick={() => this.removeFilters(filtersValues, key, arrayValues)} /> 
      </Box>
    )
  }

  renderFiltersChipSection = () => {
    const { request_type, created_at, department_id, assigned_to, status} = this.state.appliedFilters

    const requestTypeArr = this.makeArrayofStr(request_type)
    const requestStatusArr = this.makeArrayofStr(status)
    const requestDepartmentArr = this.makeArrayofStr(department_id)
    const requestAssigneeArr = this.makeArrayofStr(assigned_to)
    const requestCreatedArr = this.makeArrayofStr(created_at)

    return (
      <AppliedFilterBox>
        {requestTypeArr.map(typeVal => this.renderChipBox(typeVal, "request_type", requestTypeArr))}
        {requestCreatedArr.map(typeVal => this.renderChipBox(typeVal, "created_at",requestCreatedArr))}
        {requestDepartmentArr.map(val => this.renderChipBox(val,"department_id",requestDepartmentArr)) }
        {requestAssigneeArr.map(val => this.renderChipBox(val, "assigned_to",requestAssigneeArr)) }
        {requestStatusArr.map(statusVal => this.renderChipBox(statusVal.replace("_", " "), "status", requestStatusArr))}
      </AppliedFilterBox>
    )
  }

  renderTicketsHeaderBox = () => {
    return (
      this.optionsTitle.map((option, index) => (
        <this.BoxFirst key={index}
          style={this.boxStyle(index)}
          className={this.state.selected === index ? "checkedIconStyle" : "unCheckedIconStyle"}
          onClick={() => this.handleOptionSelect(index)}>
          {this.state.selected === index ? option.icon : option.unCheckedIcon}
          <Typography style={this.useStyles.options}>
            {option.title}
          </Typography>
        </this.BoxFirst>
      ))
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    const ticketData = this.state.ticketData.filter((row: any) => {
      const selectedSubOptionValue = subOptionsTitle[this.state.selectedSubOption]?.toLowerCase() || '';
      if (!selectedSubOptionValue || selectedSubOptionValue === '' || selectedSubOptionValue === I18n.t("allLowerCase")) {
        return this.state.ticketData.includes(row);  
      }
      return row.attributes.request_type.toLowerCase().includes(selectedSubOptionValue);
    });

    return (
      <div style={{ display: 'flex', flexDirection: this.getValueBasedOnLanguage("row-reverse", "row")}}>
        <AppNavigation />
        <div className={this.getValueBasedOnLanguage("arabic-text", "default-text")} style={{flex:1, overflow: 'hidden', marginRight:this.getValueBasedOnLanguage("325px", undefined) }}>
          <ThemeProvider theme={theme}>
            <div style={{backgroundColor:'#F1F5FC', padding:'50px 20px'}}>
              <Box style={{
                display:"flex",
                justifyContent:'center',
              }}>
                <Box style={{alignSelf:"center",width:"10%"}}>
                  <this.BreadcrumbsCust 
                    separator={this.getValueBasedOnLanguage(<NavigateBeforeIcon />, <NavigateNextIcon />)}
                    style={{color:"#000000",height:"9px",width:"5px",whiteSpace:"nowrap"}}
                    >
                    <Typography style={this.useStyles.navLinks}>{I18n.t("home")}</Typography>
                    <Typography style={{...this.useStyles.navLinks, fontWeight: 700, color: '#3f51b5'}}>{I18n.t("tickets")}</Typography>
                  </this.BreadcrumbsCust>
                </Box>
                <this.boxSub> 
                   {this.renderTicketsHeaderBox()}
                </this.boxSub>
              </Box>
              <div style={{ marginTop: 40, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Box style={{ display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center",  alignItems:"center" }}>
                  <this.boxSub>
                    {this.catogoriesFiltersOptions.map((option, index) => (
                      <Box key={index}
                        style={this.boxSubStyle(option.value)}
                        data-test-id="catogoryFilters"
                        onClick={() => this.handleCatogoriesFilters(option.value)}>
                        <Typography style={{...this.useStyles.subOption, fontWeight: option.value === this.state.selectedCatogoryFilter ? 700 : 500}}>
                          {option.label}
                        </Typography>
                      </Box>
                    ))}
                  </this.boxSub>
                </Box>

                <div style={{ display: "flex", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), justifyContent: "center", alignSelf: "center",width: "80%", padding: "1rem 1.5rem", borderRadius: "0.5rem", }}>
                  <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row") }}>

                    <this.InputField
                      type="search"
                      id="search"
                      data-test-id="searchinput"
                      onChange={this.handleSearchInput}
                      value={this.state.searchInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={this.adornmentStyle}
                          >
                            <SearchOutlined style={{ color: "#94A3B8" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={I18n.t("search")}
                      variant="outlined"
                    />
                  </div>
                  <Button
                    variant="outlined"
                    onClick={this.openFilterModal}
                    data-test-id="filterModalBtn"
                    style={{
                      fontSize: '18px', fontWeight: 700, textTransform: "none", backgroundColor: "#527FE2", borderRadius: this.getValueBasedOnLanguage("25px 0 0 25px", "0 25px 25px 0"),
                      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
                    }}>
                    <FilterList style={{ color: "ffffff" }} />
                  </Button>
                </div>

                <Box>
                    {this.renderFiltersChipSection()}
                </Box>
              </div>

              <this.CardBox >
                <this.RequestsList className="table">
                  <Table style={{minWidth: "1200px", width: "100%", overflow:"scroll"}} >
                    <TableHead style={{ position: "sticky", top: 0, zIndex: 10}}>
                      <TableRow style={this.useStyles.tableRow} >
                        <this.TableCellHead style={{ width: "100%", borderRadius: this.getValueBasedOnLanguage("0 0 24px 0", "0 0 0 24px"), fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins") }}>
                          {I18n.t("clientID")}
                        </this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("customer")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("category")}</this.TableCellHead>
                        <this.TableCellHead style={{...this.useStyles.tableHead, justifyContent:'center'}}>{I18n.t("creationDate")}</this.TableCellHead>
                        <this.TableCellHead style={{...this.useStyles.tableHead, whiteSpace:'normal', textAlign:this.getValueBasedOnLanguage("right", "left")}}>{I18n.t("responseTime")}</this.TableCellHead>
                        <this.TableCellHead style={{...this.useStyles.tableHead, whiteSpace:'normal', textAlign:this.getValueBasedOnLanguage("right", "left")}}>{I18n.t("clientResponseTime")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("department")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("assignee")}</this.TableCellHead>
                        <this.TableCellHead style={{...this.useStyles.tableHead, paddingLeft:this.getValueBasedOnLanguage('50px', '24px')}}>{I18n.t("status")}</this.TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ position: "relative"}}>
                      {
                        ticketData.length > 0 ? (
                          ticketData.map((row: any, index: any) => (

                            <TableRow style={this.useStyles.tableRow} key={index} onClick={() => this.handleRowClick(row.id)}>
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyEnglishFont}}>
                                # {row.attributes.sender_id}
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyEnglishFont, whiteSpace:'normal'}}>
                                <Typography style={{ width : "100%", textAlign:this.getValueBasedOnLanguage('right', 'left')}}>{row.attributes.sender_full_name}</Typography>
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyArabicFont}}>
                                {this.formateCatogoryText(row.attributes.request_type)}
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyArabicFont,justifyContent:'center'}}>
                              <div>
                                  <div>
                                    {`${getDate(row.attributes.created_at)} ${getMonth(row.attributes.created_at, showCss("ar", "en"))} ${getYear(row.attributes.created_at)}`}
                                  </div>
                                  <div>
                                    {getTime(row.attributes.created_at, showCss("ar", "en"))}
                                  </div>
                              </div>
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyArabicFont}}>
                                {row.attributes.merchant_response_time}
                              </this.TableCellBody>

                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyArabicFont}}>
                                {row.attributes.customer_response_time}
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyArabicFont, whiteSpace:'normal', textAlign:this.getValueBasedOnLanguage('right', 'left')}}>
                              {row.attributes.assigned_to_department}
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBody, ...this.useStyles.tableBodyEnglishFont, whiteSpace:'normal'}}>
                                <Typography style={{ width : "100%", textAlign:this.getValueBasedOnLanguage('right', 'left')}}>{row.attributes.assigned_to}</Typography>
                              </this.TableCellBody>
    
                              <this.TableCellBody style={{...this.useStyles.tableBodyStatus, ...this.useStyles.tableBodyArabicFont, paddingLeft:this.getValueBasedOnLanguage('50px', '24px')}}>
                                <Typography style={this.statusCheck(row.attributes.status)}>
                                  {this.formateStatusText(row.attributes.status)}
                                </Typography>
                                <MoreVertIcon />
                              </this.TableCellBody>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow style={{ height: "200px" }}>
                          <TableCell colSpan={8} style={{ textAlign: "center", border: "none" }}>
                            <img
                              src={noData}
                              alt="No Data"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                            <Typography style={{
                              color: "#000000",
                              fontWeight:700,
                              fontSize:'18px',
                              fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
                              }}>
                              {I18n.t("noTickets")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        )
                      
                      }

                    </TableBody>
                  </Table>
                
                </this.RequestsList>
                <Box style={{display:"flex",justifyContent:"center", margin:'10px 0px'}}>
                  {
                    ticketData.length != 0 && (
                      <this.PaginationBox className="setPagination">
                        <Pagination
                          count={Math.ceil(this.state.setCount / 12)}
                          boundaryCount={1}
                          siblingCount={1}
                          data-test-id='pagination_id'
                          page={this.state.page} 
                          onChange={this.handlePageChange} 
                        />
                        <div style={{ textAlign: showCss("start", 'end') }}>
                          <Typography className={`setCount ${showCss("leftSideText", "rightSideText")}`}>
                            {`${(this.state.page - 1) * 12 + 1}-${Math.min(this.state.page * 12, this.state.setCount)} of ${this.state.setCount} ${I18n.t("resultsLength")}`}
                          </Typography>
                        </div>
                      </this.PaginationBox>
                    )
                  }
                </Box>

              </this.CardBox>
              <Box data-test-id="goToAddService"
                style={{ display: "flex", justifyContent: "center", margin: "3rem" }}>
                <this.AddServiceBtn>{I18n.t("mangeTicketSettings")}</this.AddServiceBtn>
              </Box>

               {/* Filter Dialog  */}
               <FilterDialog
                  open={this.state.filterModal}
                  handleClose={this.closeFilterModal}
                  data-test-id="filterModalComponent"
                  currentPage="requestManagement"
                  handleApplyFilters={(values) => this.applyFilters(values)}
                  reqManagementCatogoriesData={this.reqCatogoriesData}
                  reqManagemntStatusData={this.reqStatusData}
                  reqManagemntDepartmentData={this.state.departmentOptionArray}
                  reqManagemntAssigneData={this.state.employeeOptionsArray}
                  reqAppliedFiltersState={this.state.appliedFilters}
                />
            </div>
          </ThemeProvider>
        </div>
      </div>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 20,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
};

const AppliedFilterBox = styled(Box)({
    display: "flex", 
    flexDirection: showCss("row-reverse", "row"), 
    justifyContent: "center", 
    alignItems: "center",
    gap: "15px",
    marginBottom: "24px",
    "& .chipBox" : {
      display: "flex",
      flexDirection: showCss("row-reverse", "row"),
      gap: 5,
      alignItems: "center",
      background: "#DBEAFE",
      border: "1px solid #BFDBFE",
      borderRadius : "50px",
      padding: "10px 12px"
    },
    "& .chipHeaderText": {
      fontFamily: showCss("Noto Sans Arabic", "Poppins"),
      fontWeight: 700,
      fontSize: "16px",
      color : "#527FE2",
      textTransform: "capitalize"
    },
    "& .crossIcon": {
      color : "#527FE2",
      height: "20px",
      width: "20px",
      cursor: "pointer"
    }
})

// Customizable Area End
