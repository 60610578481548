import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Breadcrumbs,
  Typography,
  InputAdornment,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  SelectProps,
  Avatar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { DeleteOutline, SearchOutlined } from "@material-ui/icons";
import { cancelIcon, checkMark, editButton, filterImage, noData } from "./assets";
import { Pagination } from "@material-ui/lab";
import { I18n } from "../../../components/src/languageJson/i18n";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppNavigation from '../../../components/src/AppNavigation.web';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { getValueBasedOnLanguage } from "../../../components/src/constant"
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  adornmentStyle = {
    order: this.getValueBasedOnLanguage(1, 0),
    marginRight: this.getValueBasedOnLanguage(8, 0),
    marginLeft: this.getValueBasedOnLanguage(0, 8),
  };

  TableRowMain = styled(TableRow)({
    background: "#527FE2",
    '& .MuiTableCell-root': {
      fontSize: '16px',
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    }
  })

  SearchBox = styled(Box)({
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
    '& .searchInput': {
      '& .MuiInputBase-root': {
        background: 'white',
        textAlign: this.getValueBasedOnLanguage("right", "left"),
        fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
        borderRadius: this.getValueBasedOnLanguage('0 25px 25px 0', "25px 0 0 25px"),
        '& .MuiOutlinedInput-input': {
          padding: '10px 0px',
          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
          fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
          '@media (max-width: 600px)': {
            width: '100%'
          }
        },
      }
    },
    '& .filterBtn': {
      position: 'absolute',
      top: '0px',
      right: this.getValueBasedOnLanguage("auto", "-43px"),
      left: this.getValueBasedOnLanguage("-43px", "auto"),
      fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
      padding: '0px 10px',
      background: '#527FE2',
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    }
  });

  BreadcrumbContainer = styled(Box)({
    display: "flex",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    paddingBottom: '35px',
    fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    justifyContent: this.getValueBasedOnLanguage("flex-end", "flex-start"),
  })

  StyledTypography = styled(Typography)({
    fontSize: "16px",
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
  })

  FilterDialog = styled(Dialog)({
    position: 'relative',
    '& .setApplyBtn': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: '20px'
    },
    '& .applyBtn': {
      padding: '10px 30px',
      background: '#527FE2',
      color: 'white',
      fontWeight: 700,
      borderRadius: '8px',
      border: 'none'
    },
    '& .mainFilter': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '1px solid #dddad6'
    },
    '& .innerFilter': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px'
    },
    '& .filterText': {
      fontWeight: 700,
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    },
    '& .clearText': {
      fontWeight: 400,
      color: '#527FE2',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
      '&.clicked': {
        transform: 'scale(0.95)', 
      },
    },
    '& .MuiDialog-container': {
      position: 'absolute',
      right: this.getValueBasedOnLanguage('auto', '0'),
      left: this.getValueBasedOnLanguage('0', 'auto'),
      fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
      '& .MuiPaper-root': {
        width: '444px',
        height: '100%',
        maxHeight: '100%',
        boxShadow: 'none',
        margin: '0',
        '@media (max-width: 500px)': {
          width: '350px',
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#527FE2",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "#527FE2",
      },
    },
  });

  TableMain = styled(TableContainer)({
    '& table': {
      '& tr': {
        height: "auto"
      }
    },
    '& .MuiTable-root': {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            textAlign: this.getValueBasedOnLanguage("right", "left"),
            direction: this.getValueBasedOnLanguage("rtl", "ltr"),
            fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
          }
        }
      },
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-stickyHeader': {
            backgroundColor: '#527FE2',
            color: 'white',
            fontWeight: 700
          }
        }
      }
    }
  });

  SelectMain = styled(Select)({
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      backgroundColor: 'transparent',
    },

    "& .MuiSelect-icon": {
      right: this.getValueBasedOnLanguage("auto", "0"),
      left: this.getValueBasedOnLanguage("7", "auto"),
      fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    }
  })

  MenuItemSetChange = styled(MenuItem)({
    padding: '10px',
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontFamily: this.getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    '&:hover': {
      backgroundColor: '#DBEAFE',
    },
  })
  
  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }

  renderEmployeeTableContent = () => {
    if (this.state.isLoading) {
      return null; // Or a loading spinner/component if necessary
    }
  
    if (this.state.setEmpData.length <= 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <NoData className="testingClass">
              <img src={noData} alt="no_data" />
              <Typography className="noEmpText">
                {this.state.noEmpResultMsg}
              </Typography>
            </NoData>
          </TableCell>
        </TableRow>
      );
    }
  
    if (this.state.setEmpData && Array.isArray(this.state.setEmpData)) {
      return this.state.setEmpData.map((item: any, index: number) => (
        <TableRowBody className="checkhereclass" key={index}>
          <TableCell>
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <div>
                <Avatar alt="emp_img" src={item.attributes.image} />
              </div>
              <div>
                <Typography style={{fontFamily: "Poppins"}}>{item.attributes.name}</Typography>
              </div>
            </div>
          </TableCell>
          <TableCell style={{ width: "16.6%", fontFamily: "Poppins" }}>
            #{item.attributes.employee_id}
          </TableCell>
          <TableCell style={{ width: "16.6%" }}>
            {item.attributes.department}
          </TableCell>
          <TableCell style={{ width: "16.6%" }}>
            {item.attributes.designation}
          </TableCell>
          <TableCell style={{ width: "16.6%",}}>
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <div>
                <Avatar
                  alt="emp_img"
                  src={item.attributes.reporting_to_image}
                />
              </div>
              <div>
                <Typography style={{fontFamily: "Poppins"}}>
                  {item.attributes.reporting_to ?? "--"}
                </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", gap: "15px" }}>
              <DeleteOutline
                data-test-id="delete_emp"
                onClick={() =>
                  this.handleOpenDelete(
                    item.attributes.id,
                    item.attributes.name
                  )
                }
                style={{ color: "red" }}
              />
              <img
                data-test-id="edit_emp"
                onClick={() => this.handleNavigation1(item.attributes.id)}
                src={editButton}
                alt="edit_button"
              />
            </div>
          </TableCell>
        </TableRowBody>
      ));
    }
  
    return null; // Fallback in case no conditions match
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainBox style={{flexDirection: this.getValueBasedOnLanguage('row-reverse', "row"), display: 'flex'}}>
        <AppNavigation />
        <StyledBox className={this.getValueBasedOnLanguage("arabic-text", "default-text")}
          style={{
            marginRight:this.getValueBasedOnLanguage("325px", undefined), 
            flex:1, 
            backgroundColor:"#F1F5FC", 
            padding:'50px 20px', 
            paddingBottom:'0', 
            minHeight:'100vh',
            boxSizing:'border-box',
          }}
        >
          <DynamicBreadcrumbs breadcrumbItems={[ I18n.t("home"), I18n.t("peopleManagement") ]} />
        <div className="search_wrapper" style={{ textAlign: 'center', display: "flex", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), justifyContent: "center", alignSelf: "center",width: "100%", padding: "1.5rem 0", borderRadius: "0.5rem", marginTop: "1rem",}}>
        <this.SearchBox className="addclass" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48%", gap: "1rem", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row") }}>
          <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "100%",flexDirection: getValueBasedOnLanguage("row-reverse","row")  }}>
          <SearchField
            variant="outlined"
            placeholder={I18n.t("search")}
            data-test-id="search_id"
            className="search-bar searchInput"
            value={this.state.searchValue} // Bind the state to the TextField
            onChange={this.handleChangeSearch} 
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={this.adornmentStyle}>
                  <SearchOutlined  style={{color:"#94A3B8"}}/>
                </InputAdornment>
              ),
            }}
            type="search"
            id="search"
            style={{ width: "100%",backgroundColor:"#FFFFFF", borderRadius: getValueBasedOnLanguage("0px 25px 25px 0px", "25px 0 0 25px") }}
          />
          </div>
          <div onClick={this.handleClickOpen} className="filterBtn" style={{
            fontSize: '18px', fontWeight: 700, textTransform: "none", backgroundColor: "#527FE2", borderRadius: this.getValueBasedOnLanguage("25px 0 0 25px", "0 25px 25px 0"),
            fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
          }}><img src={filterImage} alt="filter_img" /></div>
        </this.SearchBox>
        </div>

        <MainTableDiv style={{ height: this.state.setEmpData.length <= 0 ? "500px": "", maxHeight: "688px"}}>
          <this.TableMain className="mainoftable" dir={this.getValueBasedOnLanguage("rtl", "ltr")}>
            <Table style={{ tableLayout: 'fixed', width: '100%', direction: this.getValueBasedOnLanguage("rtl", "ltr") }} stickyHeader aria-label="sticky table">
              <TableHead className="sticky-header" >
                <this.TableRowMain>
                  <TableCell style={{ borderBottomLeftRadius: this.getValueBasedOnLanguage("0px", "20px"), width: '200px' }}>{I18n.t("employeeName")}</TableCell>
                  <TableCell >{I18n.t("employeeID")}</TableCell>
                  <TableCell>{I18n.t("department")}</TableCell>
                  <TableCell>{I18n.t("position")}</TableCell>
                  <TableCell>{I18n.t("reportingTo")}</TableCell>
                  <TableCell></TableCell>
                </this.TableRowMain>
              </TableHead>
              <TableBody className="scrollable-body" dir={getValueBasedOnLanguage('rtl', "ltr")}>
                {this.renderEmployeeTableContent()}
              </TableBody>
            </Table>
          </this.TableMain>

          {
          this.state.setEmpData.length != 0 && (
            <div className="setPagination">
              <Pagination
                count={Math.ceil(this.state.setCount / 12)}
                boundaryCount={1}
                siblingCount={1}
                data-test-id='pagination_id'
                    page={this.state.page} // Bind the current page state
                    onChange={this.handlePageChange} // Handle page change event
              />
              <div className="pagination-count"  style={{ textAlign: getValueBasedOnLanguage("start", 'end') }}>
                <Typography className={`setCount ${getValueBasedOnLanguage("leftAlignText", "rightAlignText")}`}>
                  {`${Math.min((this.state.page - 1) * 12 + 1, this.state.setCount)}-${Math.min(this.state.page * 12, this.state.setCount)} ${I18n.t("of")} ${this.state.setCount} ${I18n.t("results")}`}
                </Typography>
              </div>
            </div>
          )
          }
        </MainTableDiv>

        <AddButton>
          <Button onClick={this.handleNavigation} className="addBtn">{I18n.t("AddNewEmp")}</Button>
        </AddButton>

        <this.FilterDialog dir={this.getValueBasedOnLanguage("rtl", "ltr")} className="setDynamic" onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen} >
          <div className="mainFilter">
            <div>
              <Typography className="filterText">{I18n.t("Filters")}</Typography>
            </div>
            <div className="innerFilter">
              <div>
                 <Typography data-test-id='clearTextTestId' className={`clearText ${this.state.isClearClicked ? "clicked" : ""}`} 
                  onClick={this.handleClearAll}
                    >{I18n.t("ClearAll")}</Typography>
              </div>
              <div onClick={this.handleClose}>
                <img src={cancelIcon} alt="cancel_icon" />
              </div>
            </div>
          </div>
          <br />

          <AccordionBox className="setAccroedion">
              <Accordion key={1} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">{I18n.t("department")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <FormControl variant="outlined" fullWidth>
                  <this.SelectMain
                    style={{ borderRadius: '10px' }}
                    label="Select here"
                    name="department"
                    data-test-id="department_id"
                    displayEmpty
                    value={this.state.setDepartment}
                    onChange={(event) => this.handleChange("department", event)}
                    MenuProps={{
                     PaperProps: {
                        className: "custom-menu-paper",
                        style: {
                          'borderRadius': '10px', 
                          'direction':  this.getValueBasedOnLanguage('rtl', 'ltr'),
                        },
                      },
                      style: {
                        inset: '60px 0 0'
                      }
                    }}
                    renderValue={(value) => {
                      const selectedDepartment = this.state.setDepartmentData?.find(
                        (item: any) => item.id === value
                      );
                      return (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "currentColor",
                            opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                          }}
                        >
                          {selectedDepartment ? selectedDepartment.attributes.name : I18n.t("department")}
                        </Box>
                      );
                    }}
                    input={<OutlinedInput />}
                  >
                    {this.state.setDepartmentData &&
                      this.state.setDepartmentData.map((item: any) => (
                        <this.MenuItemSetChange key={item.id} value={item.id}>
                          {item.attributes.name}
                        </this.MenuItemSetChange>
                      ))}
                  </this.SelectMain>
                </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">{I18n.t("position")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <this.SelectMain
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="position"
                      data-test-id='position_id'
                      displayEmpty
                      value={this.state.setPosition}
                      onChange={(eventChange) => this.handleChange("position",eventChange)}
                      MenuProps={{
                        PaperProps: {
                          className: "custom-menu-paper",
                          style: {
                            'borderRadius': '10px', 
                            'direction':  this.getValueBasedOnLanguage('rtl', 'ltr'),
                          },
                        },
                        style: {
                          inset: '60px 0 0'
                        }
                      }}
                      renderValue={(value) => {
                        const selectedDepartment = this.state.setPositionData?.find(
                          (item: any) => item.id === value
                        );
                        return (
                          <Box
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              color: "currentColor",
                              opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                            }}
                          >
                            {selectedDepartment ? selectedDepartment.name : I18n.t("position")}
                          </Box>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      {
                      this.state.setPositionData && this.state.setPositionData.map((item: any, index: number) =>
                      (
                        <this.MenuItemSetChange key={item.id} value={item.id} > {item.name} </this.MenuItemSetChange>
                      )
                      )
                    }
                    </this.SelectMain>
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={3} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">{I18n.t("reportingTo")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <this.SelectMain
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="reporting_To"
                      data-test-id='reporting_id'
                      value={this.state.setReporting}
                      onChange={(eventChange) => this.handleChange("reporting",eventChange)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          className: "custom-menu-paper",
                          style: {
                            'borderRadius': '10px', 
                            'direction':  this.getValueBasedOnLanguage('rtl', 'ltr'),
                          },
                        },
                        style: {
                          inset: '60px 0 0'
                        }
                      }}
                      renderValue={(value) => {
                        const selectedDepartment = this.state.setReportingData?.find(
                          (item: any) => item.id === value
                        );
                        return (<Box
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              color: "currentColor",
                              opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                            }}
                          >
                            {selectedDepartment ? selectedDepartment.attributes.name : I18n.t("reportingTo")}
                          </Box>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      {
                        this.state.setReportingData && this.state.setReportingData.map((item: any, index: number) =>
                        (
                          <this.MenuItemSetChange className="testingClass" style={{
                            borderBottom: this.conditionalRenderObject(index === this.state.setReportingData.length - 1, "none", "1px solid #d4d2cd"),
                          }} value={item?.id} key={index} >
                            {item?.attributes?.name}
                            </this.MenuItemSetChange>
                        )
                        )
                      }
                    </this.SelectMain>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
          </AccordionBox>

          <div className="setApplyBtn">
            <button onClick={this.applyFilter} className="applyBtn">{I18n.t("Apply")}</button>
          </div>

          </this.FilterDialog>

          <DeleteEmpDialog
            open={this.state.deleteEmpModal}
            onClose={this.handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div dir={this.getValueBasedOnLanguage("rtl", "ltr")}>
              <div style={{ textAlign: 'center' }}>
              <DeleteOutline style={{ color: 'red', width: '80px', height: '80px' }} />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', }}>
                <Typography className="setEmpText">{I18n.t("DeleteEmpMsg")}</Typography>
                <Typography className="innerText">{I18n.t("DeleteEmpDesc")}</Typography>
              </div>
              <div className="flexBtn">
                <button className="deleteEmpButton" onClick={this.deleteEmployeeCall}>{I18n.t('Delete')}</button>
                <button onClick={this.handleCloseDelete}className="cancleButton">{I18n.t('Cancel')}</button>
              </div>
            </div>
          </DeleteEmpDialog>

          <SuccessDialog
            open={this.state.successDialog}
            onClose={this.successDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div>
              <div style={{ textAlign: 'center' }}>
                <img src={checkMark} alt="check_mark" />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', }}>
                <Typography className="setEmpText">{I18n.t("EmpDeletedSuccess")}</Typography>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button className="viewEmpButton" onClick={this.successDelete}>{I18n.t("Cancel")}</button>
              </div>
            </div>
          </SuccessDialog>
        </StyledBox>
        <ErrorModal 
          open={this.state.errorModal !== ""}
          handleClose={this.closeErrorModal}
          msg_ar={this.getErrorMessage()}
          msg_en={this.getErrorMessage()}
          data-test-id="deleteServiceErrorModal"
        />
      </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#527FE2",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#527FE2",
    },
  },
  "& .rightAlignText": {
    right: "70px"
  },
  "& .leftAlignText": {
    left: "70px"
  },
});

const StyledBox = styled(Box)({
  background: '#F1F5FC',
  padding: '50px 20px',
});

const AccordionBox = styled(Box)({
  width: '100%',
  '& .accText':{
    fontWeight: 700,
    color:'#64748B',
  },
  '& .MuiPaper-root':{
    '& .MuiButtonBase-root':{
      padding: '0px 20px !important',
      '& .MuiIconButton-label':{
        color: 'black'
      }
    },
    '& .MuiCollapse-root':{
      '& .MuiCollapse-wrapper':{
        '& .MuiCollapse-wrapperInner':{
          '& .MuiAccordionDetails-root':{
            padding: '0px 28px', 
            "&>div": {
              background: '#F8FAFC',
            }
          }
        }
      }
    }
  }
});

export const SearchField = styled(TextField)({
  fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
  width: "100%",
  backgroundColor: "#FFFFFF",
  textAlign: getValueBasedOnLanguage("right", "left"),
  borderRadius: getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
  '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      opacity: 1,
  },
  "& .MuiInputBase-root": {
      height: "44px",
  },
  "& .MuiOutlinedInput-input": {
      padding: "0 8px",
      height: "100%",
      boxSizing: "border-box",
      direction: getValueBasedOnLanguage("rtl", "ltr")
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: " #527FE2",
      borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
  },
  "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
  },
  "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#6EE7B7",
  },
  "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
          borderColor: "#527FE2",
      },
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px)": {
      minWidth: "unset",
      maxWidth: "unset",
  },
  "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
  },
});

const TableRowBody = styled(TableRow)({
  '& .MuiTableCell-root':{
    fontSize: '16px',
    '& .MuiTypography-root':{
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    fontSize: '16px',
    }
  },
})
const MainTableDiv = styled(Box)({
  marginTop: "20px",
  padding: "20px",
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  borderRadius: "20px",
  background: "white",
  display: "flex",
  flexDirection: "column",
  "& .tableContainer": {
    flexGrow: 1,
    overflowY: "auto", 
    maxHeight: "500px",
  },
  "@media (max-width:560px)": {
    overflow: "scroll",
    display: "flex",
    marginTop: "20px",
  },

  "& .MuiTableHead-root": {
    position: "sticky",
    top: 0,
    zIndex: 2,
    "& .MuiTableCell-root": {
      color: "white",
      fontWeight: 700,
    },
  },

  "& .MuiTableBody-root": {
    overflowY: "auto",
    maxHeight: "400px",
  },
 

  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
      fontSize: "16px",
    },
  },

  '& .setPagination':{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '10px',
    position: 'relative',
    direction: getValueBasedOnLanguage("rtl", "ltr"),
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        },
    '& .MuiPaginationItem-root': {
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    },
    '& .setCount':{
      position: 'absolute',
      direction: "ltr",
      right: getValueBasedOnLanguage('auto', '0px'), 
      left: getValueBasedOnLanguage('50px', 'auto'), 
      fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
      top:'50%', 
      color: '#64748B',
      transform: 'translateY(-50%)',
      '@media (max-width: 600px)': {
        position: 'static',
        marginTop: '10px',
        transform: 'none',
        textAlign: 'center'
        }
    },
    '& .MuiPagination-ul li:first-child, & .MuiPagination-ul li:last-child': {
      transform: getValueBasedOnLanguage("rotate(180deg)", "rotate(0deg)")
    },
    "& .MuiPaginationItem-page": {
      textDecoration: "underline",
      fontWeight: 400,
      fontSize: "12px",
      fontFamily: "Poppins",
      color: "black",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      fontWeight: 700,
      fontSize: "12px",
      color: "#527FE2",
      textDecoration: "none",
      background: "white",
      fontFamily: "Poppins",
    }
  },

  "& ::-webkit-scrollbar": {
    width: "12px",
    borderRadius: "10px",
  },
  "& ::-webkit-scrollbar-thumb": {
    backgroundColor: "#527FE2",
    borderRadius: "10px",
    border: "3px solid transparent",
    backgroundClip: "padding-box",
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
  },
  "& ::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },

});

const AddButton = styled(Box)({
  marginTop: '30px',
  textAlign: 'center',
  '& .addBtn': {
    padding: '10px 80px',
    border: 'none',
    background: '#527FE2',
    borderRadius: '8px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom:'60px',
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
  }
});

const NoData = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  '& .noEmpText':{
    fontWeight: 700,
    textAlign: 'center'
  }
});

const DeleteEmpDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
  },
  '& .innerText':{
    fontWeight:400,
    color: '#94A3B8',
    marginTop: '10px',
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
  },
  '& .flexBtn':{
    display: 'flex',
    gap: '10px',
    alignItems: 'end',
    justifyContent: 'end'
  },
  '& .deleteEmpButton':{
    padding: getValueBasedOnLanguage('12px 20px', "10px 20px"),
    marginTop:'20px',
    fontSize: getValueBasedOnLanguage('18px', "1rem"),
    color: '#fff',
    background: 'red',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    width: '120px',
  },
  '& .cancleButton': {
    // width: '100%',
    padding: getValueBasedOnLanguage('12px 20px', "10px 20px"),
    marginTop:'20px',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: getValueBasedOnLanguage('18px', "1rem"),
    fontFamily: getValueBasedOnLanguage('Noto Sans Arabic', "Poppins"),
    width: '120px',
  }
});

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700
  },
  '& .viewEmpButton': {
    // width: '100%',
    padding: '10px 160px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }
});
// Customizable Area End
