import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
import { toast, Slide } from "react-toastify";
import { getValueBasedOnLanguage } from "../../../components/src/constant"
import { I18n } from "../../../components/src/languageJson/i18n";
// Customizable Area End

export const configJSON = require("./config.js");

interface Appointment {
  colIndex: number;
  rowIndex: number;
  title: string;
  time: string;
  date: string;
  attendees: string;
  attendees1: string;
}

export interface Props {
  navigation:any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
  currentLanguage: string;
  currentDate: Date;
  activeIcon: 'home' | 'settings' | null;
  selectedAppointment: Appointment | null;
  selectedAppointmentPosition: { top: string; left: string } | null;
  openDeleteModal: boolean;
  currentData:any,
  calendarCurrentDate: Date;
  events: Event[];
    showModal: boolean;
    selectedDateTime: Date | null;
    eventData: { title: string; start: string; end: string };
    dayOffset: number;
    currentMonth:any;
    appointmentData: any[],
    anchorEl: null | HTMLElement, 
    open: any,
    appointments:any,
    selectedMonth:any,
    openSuccessModal:any,
    popoverData:any,
    setShowModal:boolean,
    openErrorModal:any,
    eventDetails:any,
  // Customizable Area End
}

export interface SS {
  id: any;
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAppointmentDetailsApiCallId: string ="";
  deleteAppointmentApiCallId: string ="";
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      currentDate: new Date(),
      activeIcon: 'home',
      selectedAppointment: null,
      currentData:null,
      selectedAppointmentPosition: null,
      openDeleteModal: false,
      calendarCurrentDate: new Date(),
      events: [],
      showModal: false,
      selectedDateTime: null,
      eventData: { title: "", start: "", end: "" },
      dayOffset: 0,
      currentMonth:new Date().getMonth(),
      appointmentData: [],
      anchorEl: null,
      openSuccessModal:"",
      open: {},
      appointments:[],
      selectedMonth:'',
      setShowModal:false,
      openErrorModal:"",
      eventDetails:"",
      popoverData:{},
      // Customizable Area End
    };

    // Customizable Area Start
    this.navigateTo = this.navigateTo.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.fetchAppointmentDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (isRestAPIResponseMessage && this.getAppointmentDetailsApiCallId === apiCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson && !responseJson.errors) {
        const filteredData = responseJson.data.data
          .map((entry: any) => this.processAppointmentEntry(entry))
          .filter(Boolean); 
  
        this.setState({ appointments: filteredData });
      } else {
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (apiCallId === this.deleteAppointmentApiCallId) {
      if (responseJson?.success === true) {
        this.setState({
          openSuccessModal: true,
          openDeleteModal: false
        });
      } else {
        this.setState({ openErrorModal: true });
      }
    }
  }
  
  processAppointmentEntry(entry: { attributes: { booking_date: any; start_time: any; end_time: any; notes: any; merchant_photo_url: any; attendees: any; id: any; service_provider_employee_name: any; service_provider_id:number,service_provider_employee_id:number,customer_name:string}; }) {
    try {
      const {
        booking_date,
        start_time,
        end_time,
        notes,
        merchant_photo_url,
        attendees,
        id,
        service_provider_employee_name,
        service_provider_id,
        service_provider_employee_id,
        customer_name
      } = entry.attributes;
      return {
        start: this.convertToISO(booking_date, start_time),
        end: this.convertToISO(booking_date, end_time),
        title: notes,
        color: merchant_photo_url,
        id: id,
        allow: attendees,
        groupId: service_provider_employee_name,
        personName:service_provider_id,
        serviceId:service_provider_employee_id,
        customerName :customer_name,

        personToMeetName: service_provider_employee_name, 
        personToMeetId: service_provider_employee_id, 
        customerId: service_provider_id
      };
    } catch (error) {
      return null;
    }
  }

  
  convertToISO(date: any, time: string) {
    const match = time.match(/(\d+):(\d+)\s(AM|PM)/);
    if (match) {
    
  
    const [hourStr, minute, period] = match.slice(1);
    const hour = parseInt(hourStr, 10);
    const adjustedHour = this.adjustHour(hour, period);
  
    return `${date}T${String(adjustedHour).padStart(2, "0")}:${minute}:00`;
    }
  }
  adjustHour(hour: number, period: string) {
    if (period === "PM" && hour !== 12) return hour + 12;
    if (period === "AM" && hour === 12) return 0;
    return hour;
  }
  

  handleClick = (event: React.MouseEvent<HTMLButtonElement>,start:any) => {
    this.setState({anchorEl:event.currentTarget,popoverData:start});
  };

   handleClose = () => {
   this.setState({anchorEl:null})
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  getWeekDates(date: Date): Date[] {
    const startOfWeek = date.getDate() - date.getDay();
    const dates: Date[] = [];
    for (let i = -1; i <= 3; i++) {
      const nextDate = new Date(date);
      nextDate.setDate(startOfWeek + i);
      dates.push(nextDate);
    }
    return dates;
  }
  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };
  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  };

  generateTimes() {
    const start = 10;
    const end = 20;
    const times = [];

    for (let hour = start; hour <= end; hour++) {
      const formattedTime = `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? 'AM' : 'PM'}`;
      times.push(formattedTime);
    }
    return times;
  }


  closeAppointmentModal = () => {
    this.setState({ selectedAppointment: null });
  };
  navigateTo = (dateTime: Date) => {
    const currentTime = new Date();
    currentTime.setMinutes(0, 0, 0);

    if (dateTime.getTime() < currentTime.getTime()) {
      toast.error((I18n.t("timeError")), {
        position: "top-center",
        transition: Slide,
        hideProgressBar: true,
        style: {
          minHeight: '30px',
          maxHeight: "50px",
          borderRadius: "5px",
          backgroundColor: '#faeceb',
          color: '#e74c3c',
          borderLeft: '5px solid #e74c3c',
          textAlign: getValueBasedOnLanguage('right', 'left'),
          direction: getValueBasedOnLanguage('rtl', 'ltr'),
        },
      })
    } else {
      this.props.navigation.navigate('AddAppointmentweb')
      localStorage.setItem("dateTime",dateTime.toString());
    }
  }
  navigateToEdit = () => {
    const popOverDataJson = JSON.stringify(this.state.popoverData)
    localStorage.setItem("eventData",popOverDataJson);
    this.props.navigation.navigate("AddAppointmentweb", {id : this.state.popoverData.id })
  };
  getFormattedDate = (currentDate: Date, dayOffset: number, index: number) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + dayOffset + index);
    return date.toLocaleDateString("en-IN", { weekday: "short", day: "2-digit" }).toUpperCase();
};
saveEvent = () => {
    const { eventData, selectedDateTime} = this.state;
    if (eventData.title && eventData.start && eventData.end && selectedDateTime) {
        const start = new Date(selectedDateTime);
        const end = new Date(selectedDateTime);

        const [startHour, startMinute] = eventData.start.split(":").map(Number);
        const [endHour, endMinute] = eventData.end.split(":").map(Number);

        start.setHours(startHour, startMinute, 0);
        end.setHours(endHour, endMinute, 0);

        this.setState({
            showModal: false,
            eventData: { title: "", start: "", end: "" },
        });
    }
};

handleNextDays = () => {
  const nextDate = new Date(this.state.currentDate);
  nextDate.setDate(this.state.currentDate.getDate() + 5);
  const currentMonth = this.state.currentDate.getMonth();
  const nextMonth = nextDate.getMonth();
console.log(this.state.currentDate,'currentMonth')
  if (new Date().getMonth() !== currentMonth) {
    this.setState({
      currentDate: nextDate, 
      currentMonth: nextMonth, 
    });
  } else {
    this.setState({
      currentDate: nextDate,
    });
  }

};
handlePreviousDays = () => {
  const prevDate = new Date(this.state.currentDate);
  prevDate.setDate(this.state.currentDate.getDate() - 5);
  this.setState({ currentDate: prevDate });
};
handleCloseModal = () => {
  this.setState({
    anchorEl:null,
    openDeleteModal: false,
    openSuccessModal: false,
    openErrorModal: false,
  })
  this.fetchAppointmentDetails();
};
handleTryAgain = () => {
  this.setState({ openErrorModal: false });
};

handleMonthChange = (e: any) => {
  const { value } = e.target;

  if (!value) {
    const currentDate = new Date();
    this.setState({
      currentDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      dayOffset: 0,
    });
  } else {
    const [year, month] = value.split("-").map(Number);
    this.setState({
      currentDate: new Date(year, month - 1, 1),
      dayOffset: 0,
    });
  }
};
handleDeletion = () => {
  const userToken = localStorage.getItem("token");

  const header = {
    'Content-Type': configJSON.appointmentApiContentType,
    token: userToken,
  };

  const id = this.state.popoverData.id;
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

  this.deleteAppointmentApiCallId = requestMessage.messageId;

  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteAppointmentEndpoint}/${id}`);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAppointmentAPiMethod);

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
fetchAppointmentDetails = () => {
  const userToken = localStorage.getItem("token");
  const header = {
    'Content-Type': configJSON.appointmentApiContentType,
    token: userToken,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAppointmentDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.appointmentListAPiEndPoint}` 
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) 
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAppointmentListAPiMethod 
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

  // Customizable Area End
}
