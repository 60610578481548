import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { ApiParams } from "./types"

import { styled, TextField, FormControl,MenuItem,Select, Box, MenuProps } from "@material-ui/core";
 
export const apiCalling = ({ header, endPoint, method, body } : ApiParams) => {
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }

export const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }
}

export const arabicRegex = /^(?=[\u0600-\u06FF]*[^\u0600-\u06FF\s])?[\u0600-\u06FF\s!@#$%^&*()_+={}\[\]:;"'<>,.?/~`-]+$/;
export const englishRegex = /^(?=[A-Za-z]*[^A-Za-z\s])?[A-Za-z\s!@#$%^&*()_+={}\[\]:;"'<>,.?/~`-]+$/;

export const  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    const selectedLanguage = localStorage.getItem("GET_SELECTED_LANGUAGE")
    return selectedLanguage === "ar" ? firstValue : secondValue;
};

export const applySameColorOnSelectText = (value : string) => {
    const styleObject = {
        color : value === "" ? "#94A3B8" : "#0F172A"
    }

    return styleObject
}

export const parseTimeString = (timeString: string) => {
    if (timeString) {

        const timeRegex = /(\d{1,2}):(\d{2})\s(AM|PM)/g;
        const matches = [...timeString.matchAll(timeRegex)];

        if (matches.length === 2) {
            const [startMatch, endMatch] = matches;

            const startTime = {
                hours: startMatch[1],
                minutes: startMatch[2],
                period: startMatch[3],
            };

            const endTime = {
                hours: endMatch[1],
                minutes: endMatch[2],
                period: endMatch[3],
            };

            return { startTime, endTime };
        }

    }

    return null;
};

export const InputField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "56px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0 8px",
        height: "100%",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: " #527FE2",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px",
    },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: "uppercase",
        color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171",
    },

    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#527FE2",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#6EE7B7",
        },
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#94A3B8", 
        opacity: 1,
    },
    "@media (max-width:990px)": {
        minWidth: "unset",
        maxWidth: "unset",
    },
});

export const SelectField = styled(TextField)({
    "& .MuiOutlinedInput-input": {
        height: "100%",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: " #527FE2",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
    },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: "uppercase",
        color: "#334155",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171",
    },

    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#527FE2",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#6EE7B7",
        },
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "@media (max-width:990px)": {
        minWidth: "unset",
        maxWidth: "unset",
    },
});

export const TextAreaField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "86px",
    },
    "& .MuiOutlinedInput-input": {
        height: "100%",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: " #527FE2",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px",
    },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: "uppercase",
        color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171",
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#527FE2",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#6EE7B7",
        },
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#94A3B8",
        opacity: 1,
    },
    "@media (max-width:990px)": {
        minWidth: "unset",
        maxWidth: "unset",
    },
});

export const SearchField = styled(TextField)({
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    width: "100%",
    backgroundColor: "#FFFFFF",
    textAlign: getValueBasedOnLanguage("right", "left"),
    borderRadius: getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    '& .MuiInputBase-input::placeholder': {
        color: '#94A3B8',
        opacity: 1,
    },
    "& .MuiInputBase-root": {
        height: "44px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0 8px",
        height: "100%",
        boxSizing: "border-box",
        direction: getValueBasedOnLanguage("rtl", "ltr")
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: " #527FE2",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: "uppercase",
        color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171",
    },
    "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "#6EE7B7",
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#527FE2",
        },
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "@media (max-width:990px)": {
        minWidth: "unset",
        maxWidth: "unset",
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
    },
});

export const CustomScrollbarStyle = {
    '&::-webkit-scrollbar': {
        width: '6px',
        height:'6px',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#527FE2',
        borderRadius: '10px',
        border: '2px solid transparent',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        width: '6px'
    },
    '&::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent',
    },
}

export const SelectMain = styled(Select)({
    borderRadius: '10px !important',
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    textAlign: getValueBasedOnLanguage('right !important', 'left !important'),
    direction: getValueBasedOnLanguage('rtl !important', 'ltr !important'), // Set direction to RTL for Arabic,
    '& .MuiSelect-outlined': {
      backgroundColor: 'transparent',
    },
  });  

  export const StyledFormControl = styled(FormControl)({
      "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
              borderColor: "#CBD5E1",
          },
          "&:hover fieldset": {
              borderColor: "#527FE2",
          },
          "&.Mui-focused fieldset": {
              borderColor: "#527FE2",
          },
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
          paddingRight: "10px"
      }
});

export const MenuItemSet = styled(MenuItem)({
    padding: '10px',
    '&:hover': {
        backgroundColor: '#DBEAFE',
    },
})


export const getCommonMenuProps: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: 220,
            overflowY: "auto",
            borderRadius: "8px",
            ...CustomScrollbarStyle,
            textAlign: getValueBasedOnLanguage("right", "left"), // Right-align text for Arabic
            direction: getValueBasedOnLanguage("rtl", "ltr"), // Ensure RTL support
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    getContentAnchorEl: null,
};

export const CommonBoxStyles : React.CSSProperties= {
    display: 'flex',
    flexWrap: 'wrap',
    opacity: 1,
    background: 'transparent',
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    textAlign: getValueBasedOnLanguage("right", "left"),
    direction: getValueBasedOnLanguage("rtl", "ltr")
}