import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { getValueBasedOnLanguage } from "./constant";
import { styled } from "@material-ui/core";
import "react-phone-number-input/style.css";
import countries from "i18n-iso-countries";
import ar from "i18n-iso-countries/langs/ar.json";
import en from "i18n-iso-countries/langs/en.json";
import { MultiLanguagePhoneInputProps } from "./types";

// Register country translations
countries.registerLocale(en);
countries.registerLocale(ar);

const MultiLanguagePhoneInput: React.FC<MultiLanguagePhoneInputProps> = ({
    inputValue,
    errorProps,
    onChange,
    onBlur,
    className,
    style,
    placeholder
}) => {
  const currentLanguage = getValueBasedOnLanguage("ar", "en")
  const [localizedLabels, setLocalizedLabels] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    // Generate localized country labels dynamically
    const countryNames = Object.fromEntries(
      Object.keys(countries.getAlpha2Codes()).map((code) => [
        code,
        countries.getName(code, currentLanguage) || code,
      ])
    );
    setLocalizedLabels(countryNames);
  }, [currentLanguage]);

    return (
        <PhoneStyle
            data-test-id="phoneTestID"
            className={className}
            placeholder={placeholder}
            style={style}
            value={inputValue}
            onChange={onChange}
            onBlur={onBlur}
            defaultCountry="AE"
            labels={localizedLabels}
            international
            countryCallingCodeEditable={false}
            borderColor={
                errorProps ? "#FCA5A5" : "#CBD5E1"
            }
            currentLanguage={currentLanguage}
        />
    );
};

export default MultiLanguagePhoneInput;


const PhoneStyle = styled(PhoneInput)(({ borderColor, currentLanguage }: any) => ({
    border: `1px solid ${borderColor || "#CBD5E1"}`,
    borderRadius: 8,
    height: 59,
    marginBottom: "24px",
    zIndex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft:getValueBasedOnLanguage("0", "10px"),
    "&:hover": {
        borderColor: "#527FE2",
    },
    "&:focus-within": {
        borderColor: "#527FE2",
    },
    "&:has(+ p)": {
        marginBottom: "4px",
    },
    "& input": {
        border: "none",
    },
    "& input:focus": {
        border: "none",
        outline: "none",
    },
    "& .PhoneInputCountry": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .PhoneInputInput": {
        color: "#334155",
        fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
        direction: getValueBasedOnLanguage("rtl", "ltr"),
        fontSize: "16px",
        fontWeight: 400,
        background: "transparent",

        lineHeight: "24px",
        textAlign: getValueBasedOnLanguage("right", "left")
    },
    "& input:focus-visible": {
        border: "none",
        outline: "none",
    },
    ...(currentLanguage === "ar" && {
        direction: "rtl",
    }),
}));
