import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { backCoverImg, ProfileImg } from "./assets"
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import {I18n} from "../../../components/src/languageJson/i18n"


export interface Dropdown {
  label: string;
  value: string;
  str_key : string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentLanguage: string,
  firstName: any;
  lastName: any;
  email: any;
  isFieldValueChanged: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  errorMessagePhoneNumber:string;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  branches:any;
  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  errors:any;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  uplodaProfile:React.RefObject<HTMLInputElement>;
  uploadImg:File |null;
  uploadCover:React.RefObject<HTMLInputElement>;
  coverImg:File |null;
  NationalId:string;
  VatRegNo:string;
  NationalAddress:string;
  BusinessName:string;
  profileData:any;
  businessDescription:string;
  fileInput1:React.RefObject<HTMLInputElement>
  fileInput2:React.RefObject<HTMLInputElement>
  fileInput3:React.RefObject<HTMLInputElement>
  fileInput4:React.RefObject<HTMLInputElement>
  fileInput:React.RefObject<HTMLInputElement>
  otherFileInput:React.RefObject<HTMLInputElement>
  authorizedIDcardFile:null |File,
      authorizedCertificateFile:null |File,
      TradingLicenseFile:null |File,
      VATtaxidentifireFile:null |File,
      ibanCertificateFile:null |File,
      InputFileOther:null |File,
      categoryData:any,
      industryCat:string,
      selectedCategory:any,
      showMOdaltime:boolean;
      modalBranchIndex: any,
  branchNames:any;
  branchLocations:any;
  branchContactNos:any;
  branchOperatingHoursNew:any;
  branchSelection:string;
  otherpdfFile:File | null;
  loading:boolean;
  branchName:string;
  branchCode:any;
  branchLOcation:string;
  branchContactNo:string;
  startTimeam:string;
  endTimeam:string;
  successError:boolean;
  dialogOpen:boolean;
  deleteBranchId:string;
  updatedBranchesIds: Set<number>
  initialBranchCount:number
  coverImgSrc: string,
  profileImgSrc: string,
  fileValidationStatus: {
    TradingLicense: boolean;
    VATtaxidentifire: boolean;
    authorizedCertificate: boolean;
    authorizedIDcard: boolean;
    ibanCertificate: boolean;
  };
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userProfileUpdateApiCallId: string="";
  getCategoriesApiCallId:string=""
  updateCoveApiCalId:string=""
  userAttr: any;
  branches:any;
  errors:any;
  branchCode:any;
  editUserApiCallId: string = "";
  postBranchAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handleCheckboxChange = this.handlePeriodChangeEnd.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.handleMinuteChange = this.handleMinuteChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleHourChangeEnd = this.handleHourChangeEnd.bind(this);
    this.handleMinuteChangeEnd = this.handleMinuteChangeEnd.bind(this);
    this.handlePeriodChangeEnd = this.handlePeriodChangeEnd.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      errors:{},
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      fileValidationStatus: {
        TradingLicense: true,
        VATtaxidentifire: true,
        authorizedCertificate: true,
        authorizedIDcard: true,
        ibanCertificate: true,
      },
      isFieldValueChanged: {
        TradingLicense: false,
        VATtaxidentifire: false,
        authorizedCertificate: false,
        authorizedIDcard: false,
        ibanCertificate: false,
      },
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      errorMessagePhoneNumber:"",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      branchContactNo:"",
      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      uplodaProfile: React.createRef(),
      uploadCover:React.createRef(),
      fileInput1: React.createRef(),
      fileInput2: React.createRef(),
      fileInput3: React.createRef(),
      fileInput4: React.createRef(),
      fileInput:React.createRef(),
      otherFileInput:React.createRef(),
      authorizedIDcardFile:null,
      authorizedCertificateFile:null,
      TradingLicenseFile:null,
      VATtaxidentifireFile:null,
      ibanCertificateFile:null,
      InputFileOther:null,
      modalBranchIndex: null,
      uploadImg:null,
      coverImg:null,
      NationalId:'',
      industryCat:"",
  VatRegNo:'',
  NationalAddress:'',
  BusinessName:'',
  businessDescription:"",
  categoryData:[],
  selectedCategory:"",
  showMOdaltime:false,
  profileData:{
    "id": "",
    "type": "",
    "attributes": {
        "business_name": "",
        "business_description": "",
        "category": "",
        "support_email": "",
        "address": "",
        "vat_registration_number": "",
        "national_id": "",
        "photo_image": {url:"",name:""},
        "banner_image_image": {url:"",name:""},
        "trading_license_image": [{url:"",name:""}],
        "vat_tax_identifier_image": [{url:"",name:""}],
        "authorization_certificate_image": [{url:"",name:""}],
        "authorized_id_card_image": [{url:"",name:""}],
        "iban_certificate_image": [{url:"",name:""}],
        "other_document_image": {url:"",name:""},
        "branches": [
            {
                "id": "",
                "branch_name": "",
                "branch_code": "",
                "branchOperatingHours": "",
                "location_link": "",
                "mobile_number": "",
                "startTime":{hours:"",minutes:"",period:"AM"},
                "endTime":{hours:"",minutes:"",period:"AM"},
            }
        ]
    }
    },
  branches: [
      {
        id: Date.now(),
        branchName: "",
        branchCode:"",
        branchLocation: "",
        branchContactNo: "",
        branchOperatingHours: "",
        selectedDays: [],
        startTime:{hours:"",minutes:"",period:"AM"},
        endTime:{hours:"",minutes:"",period:"AM"},
      },
    ],
    branchNames: [{ id: 1, value: "" }],
    branchLocations:[{ id: 1, value: "" }],
    branchContactNos: [{ id: 1, value: "" }],
    branchOperatingHoursNew: [{ id: 1, value: "" }],
    branchSelection:"",
    otherpdfFile:null,
    loading:false,
    branchName:"",
    branchCode:"",
    branchLOcation:"",
    startTimeam:"AM",
    endTimeam:"AM",
    successError:false,
    dialogOpen:false,
    deleteBranchId:"",
    updatedBranchesIds: new Set<number>(),
    initialBranchCount:0, 
    coverImgSrc: "",
    profileImgSrc: ""
};

    this.arrayholder = [];

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


   
  async componentDidMount() {
    // Customizable Area Start
  super.componentDidMount();
  let tokenvalue = await getStorageData('token')
  this.getUserProfile(tokenvalue)
  this.GetCategory()
  
// Customizable Area End

}
  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.userProfileGetApiCallId) {
        this.handleUserProfileGetApi(responseJson);
      }
      if (apiRequestCallId === this.userProfileUpdateApiCallId) {
        this.handleUserProfileUpdateApi(responseJson);
      }

      if (apiRequestCallId == this.getCategoriesApiCallId) {
        this.handleGetCategoriesApi(responseJson);
      }

  }

    
    // Customizable Area End
  }

   // Customizable Area Start

   getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  };  

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  findCategory(responseJson: any, categoryData: any) {
    if (!categoryData) {
      return 'undef';
    }
  
    const selectedCategoryName = responseJson?.data?.attributes?.category;
    if (!selectedCategoryName) {
      return 'undef';
    }
  
    const selectedCategory = categoryData.find(
      (category: any) => category?.attributes?.name === selectedCategoryName
    );
  
    return selectedCategory || 'undef';
  }  

  mergeBranches(responseBranches: any[], localBranches: any[]) {
    const validResponseBranches = Array.isArray(responseBranches) ? responseBranches : [];
    const validLocalBranches = Array.isArray(localBranches) ? localBranches : [];

    return (validResponseBranches.length ? validResponseBranches : validLocalBranches).map((branches: any, index) => {
        const responseBranch = validResponseBranches[index] || {};
        const localBranch = validLocalBranches[index] || {};

        return {
            ...branches,
            branchName: responseBranch.branch_name || localBranch.branchName,
            branchCode: responseBranch.branch_code || localBranch.branchCode,
            branchLocation: responseBranch.location_link || localBranch.branchLocation,
            branchContactNo: responseBranch.mobile_number || localBranch.branchContactNo,
            branchOperatingHours: responseBranch.business_hours || localBranch.branchOperatingHours,
            startTime: localBranch.startTime || { hours: "", minutes: "", period: "AM" },
            endTime: localBranch.endTime || { hours: "", minutes: "", period: "AM" },
            selectedDays: localBranch.selectedDays || [],
        };
    });
  }

   handleUserProfileGetApi(responseJson: any) {
    if (responseJson && !responseJson.errors && responseJson.data) {
      const responseBranches = responseJson.data.attributes?.branches || [];
      const coverImage = responseJson.data.attributes?.banner_image_image
      const profileImage = responseJson.data.attributes?.photo_image
      const localBranches = this.state.branches;
      const updatedBranches = this.mergeBranches(responseBranches, localBranches);
      this.setState(prevState => ({
        coverImgSrc: this.isValidImage(coverImage) ??  backCoverImg, 
        profileImgSrc:  this.isValidImage(profileImage) ??  ProfileImg, 
        profileData: {
          ...responseJson.data, 
          attributes: {
            ...responseJson.data.attributes, 
            branches: updatedBranches,
          }
        }
      }));
      this.setState({branches: updatedBranches})
      const selectedCategory = this.findCategory(responseJson, this.state.categoryData)  
      this.setState({initialBranchCount: responseJson.data.attributes?.branches?.length || 0})
      if (selectedCategory && selectedCategory.attributes) {
        this.setState({ industryCat: selectedCategory.attributes.id });
      } else {
        this.setState({ industryCat: "" });
      }
      this.setState({
        selectedCategory
      })
      const selectedBranch = responseJson.data.attributes?.branch_selection || null; 
      this.setState({ branchSelection: selectedBranch });
      const attributes = responseJson.data.attributes;
      this.setState({
        fileValidationStatus: {
          TradingLicense: !!attributes.trading_license_image?.[0]?.url,
          VATtaxidentifire: !!attributes.vat_tax_identifier_image?.[0]?.url,
          authorizedCertificate: !!attributes.authorization_certificate_image?.[0]?.url,
          authorizedIDcard: !!attributes.authorized_id_card_image?.[0]?.url,
          ibanCertificate: !!attributes.iban_certificate_image?.[0]?.url,
        },
      });
    }
  
  }

  isValidImage = (imageData:any) => {
    if (!imageData || imageData === "null" || imageData === "undefined") {
      return null;
    }
    
    if (Array.isArray(imageData) && imageData.length > 0 && imageData[0].url) {
      return imageData[0].url;
    }
  
    return null;
  };
  
  handleUserProfileUpdateApi(responseJson: any) {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ profileData: responseJson.data});
      this.setState({ profileData: responseJson.data, dialogOpen: true });
    } else {
      this.setState({
        successError: responseJson.errors
      });
      this.setState({
        successError: responseJson.errors,dialogOpen: true
      });
    }
  }
  
  handleGetCategoriesApi(responseJson: any) {    
    if (responseJson && responseJson.data) {
      this.setState({ categoryData: responseJson.data });
      
      const initialValue = responseJson.data.find((option: any) => 
        option?.attributes?.name.trim().toLowerCase() === "electronics"
      );
  
      this.setState({ selectedCategory: initialValue });
    }
  }
  

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  GetCategory = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserProfile = async (tokenvalue: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileGetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/show"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

updateUserProfile = () => {

    const header = {
      token: localStorage.getItem("token"),
    };

    const formData:any = new FormData();
    if (this.state.uploadImg !== null && this.state.uploadImg !== undefined) {
      formData.append("profile[photo]", this.state.uploadImg);
    }
    formData.append("profile[business_name]", this.state.profileData.attributes.business_name);
    formData.append("profile[business_description]", this.state.profileData.attributes.business_description);
    formData.append("profile[category]", Number(this.state.industryCat));
    formData.append("profile[branch_selection]", this.state.profileData.attributes.branch_selection);
    this.state.TradingLicenseFile && formData.append("profile[trading_license]", this.state.TradingLicenseFile, this.state.TradingLicenseFile.name);
    this.state.VATtaxidentifireFile && formData.append("profile[vat_tax_identifier]",  this.state.VATtaxidentifireFile, this.state.VATtaxidentifireFile?.name);
    this.state.authorizedCertificateFile && formData.append("profile[authorization_certificate]", this.state.authorizedCertificateFile, this.state.authorizedCertificateFile?.name);
    this.state.authorizedIDcardFile && formData.append("profile[authorized_id_card]", this.state.authorizedIDcardFile, this.state.authorizedIDcardFile?.name);
    this.state.ibanCertificateFile && formData.append("profile[iban_certificate]",  this.state.ibanCertificateFile, this.state.ibanCertificateFile?.name);
    formData.append("profile[address]", this.state.profileData.attributes.address);
    this.state.profileData.attributes.support_email && formData.append("profile[support_email]", this.state.profileData.attributes.support_email);
    formData.append("profile[national_id]", this.state.profileData.attributes.national_id);
    formData.append("profile[vat_registration_number]", this.state.profileData.attributes.vat_registration_number);
    this.state.InputFileOther && formData.append("profile[other_document]",  this.state.InputFileOther);
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileUpdateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/update_merchant"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  updateCoverPhoto = () => {
    

    const header = {
      token: localStorage.getItem("token"),
    };

    const formData:any = new FormData();
    formData.append("profile[banner_image]", this.state.coverImg);
   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCoveApiCalId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/update_cover_image"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFileChangeProfile4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file && file.size > 5 * 1024 * 1024){
      toast.error("Photo Image size exceeds the 5MB limit. Please choose a smaller file")
      return
    }
    if(file ){
    const objectUrl = URL.createObjectURL(file);
    this.setState({uploadImg:file, profileImgSrc: objectUrl })
  }
  };

  
  handleFileProfileick4 = () => {
    this.state.uplodaProfile.current?.click();
  };

  handleFileCoverPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file && file.size > 5 * 1024 * 1024){
      toast.error("Photo Image size exceeds the 5MB limit. Please choose a smaller file")
      return
    }
    if(file ){
      const objectUrl = URL.createObjectURL(file);
      this.setState({coverImg:file, coverImgSrc: objectUrl},()=>this.updateCoverPhoto())
    }
  };

  
  handleFileCoverClick = () => {
    this.state.uploadCover.current?.click();
  };
  onValueChange = (name: string, value: string |null) => {
    const errorKeyMapping: Record<string, string> = {
      business_name: "BusinessName",
      vat_registration_number: "VatRegNo",
      national_id: "NationalId",
      address: "NationalAddress",
      support_email: "email",
      business_description: "businessDescription"
    };

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };
  
      const errorKey = errorKeyMapping[name];
  
      if (value && errorKey && updatedErrors[errorKey]) {
        delete updatedErrors[errorKey];
      }
  
      return {
        ...prevState,
        profileData: {
          ...prevState.profileData,
          attributes: {
            ...prevState.profileData.attributes,
            [name]: value,
          },
        },
        errors: updatedErrors, 
      };
    });
  }

  onValueChangeNew = (index: number, field: string, event: any) => {
    this.setState((prevState) => {
      const updatedProfileBranches = [...(prevState.profileData.attributes.branches || [])];
      const value = event?.target?.value ?? event;
  
      updatedProfileBranches[index] = {
        ...updatedProfileBranches[index] || {}, 
        [field]: value,
      };
  
      const updatedBranchIdsSet = new Set<number>(prevState.updatedBranchesIds || []);
      const branchId = updatedProfileBranches[index]?.id;
      if (typeof branchId === "number") {
        updatedBranchIdsSet.add(branchId);
      }
  
      const updatedBranchesState = [...(prevState.branches || [])];
      updatedBranchesState[index] = {
        ...updatedBranchesState[index] || {},
        branchName: updatedProfileBranches[index]?.branch_name || "",
        branchCode: updatedProfileBranches[index]?.branch_code || "",
        branchLocation: updatedProfileBranches[index]?.location_link || "",
        branchContactNo: updatedProfileBranches[index]?.mobile_number || "",
        branchOperatingHours: updatedProfileBranches[index]?.business_hours || "",
      };
  
      const updatedErrors = { ...prevState.errors };
      delete updatedErrors[`branches[${index}].${field}`];
  
      return {
        profileData: {
          ...prevState.profileData,
          attributes: {
            ...prevState.profileData.attributes,
            branches: updatedProfileBranches,
          },
        },
        branches: updatedBranchesState,
        updatedBranchesIds: updatedBranchIdsSet,
        errors: updatedErrors,
      };
    });
  };


onEditTask = () => {
  this.setState({ loading: true });
  const header = {
    token: localStorage.getItem("tokenCustom"),
  };
  
  const formData:any = new FormData();
  formData.append("profile[business_name]", this.state.BusinessName);
  formData.append("profile[business_description]", this.state.businessDescription);
  formData.append("profile[category]", this.state.industryCat);
  formData.append("profile[trading_license]", this.state.TradingLicenseFile);
  formData.append("profile[vat_tax_identifier]", this.state.VATtaxidentifireFile);
  formData.append("profile[authorization_certificate]", this.state.authorizedCertificateFile);
  formData.append("profile[authorized_id_card]", this.state.authorizedIDcardFile);
  formData.append("profile[iban_certificate]", this.state.ibanCertificateFile);
  formData.append("profile[address]", this.state.NationalAddress);
  formData.append("profile[support_email]", this.state.email);
  formData.append("profile[national_id]", this.state.NationalId);
  formData.append("profile[vat_registration_number]", this.state.VatRegNo);
  formData.append("profile[other_document]", this.state.otherpdfFile);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.editUserApiEndPoint}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editUserApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

onPostBranch = () => {  
  const header = {
    token: localStorage.getItem("token"),
    "Content-Type": "application/json"
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  const newBranches = this.state.branches.filter((branch: any) => branch.newBranch);

  if (newBranches.length === 0) {
    return; 
  }

  const data = newBranches.map((item: any) => ({
    branch_name: item.branchName,
      branch_location: item.branchLocation,
      branch_contact_number: item.branchContactNo,
      branch_operating_hours: item.branchOperatingHours,
      branch_code: item.branchCode,
  }));

  const dataSend = { branches: data };

  this.postBranchAPIcallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(dataSend)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_profile/profile/branches`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

FormuserSchema = () => {
  return Yup.object().shape({
    NationalId: Yup.string().matches(/^\d+$/, I18n.t("nationalIdError"))
    .required(I18n.t("nationalIdError")).nullable(),
    VatRegNo: Yup.string().required(I18n.t("vatRegError")),
    NationalAddress: Yup.string().required(I18n.t("nationalAddError")),
    BusinessName: Yup.string().required(I18n.t("businessNameError")),
    industryCat:Yup.string().required(I18n.t("inductryCateError")),
    branchSelection:Yup.string().required(I18n.t("branchSelectionError")),
    email: Yup.string().email("*Invalid email format. Please enter a valid email address.").nullable().notRequired(),
    businessDescription:Yup.string().required(I18n.t("branchDescriptionError")),
    TradingLicense: Yup.mixed()
    .required(I18n.t("tradeLicenseError"))
    .test(
      "file-size",
      I18n.t("tradeLicenseError"),
      () => this.state.fileValidationStatus.TradingLicense
    )
    .nullable(),
    VATtaxidentifire: Yup.mixed()
    .required(I18n.t("vatTaxIdentifierError"))
    .test(
      "file-size",
      I18n.t("vatTaxIdentifierError"),
      () => this.state.fileValidationStatus.VATtaxidentifire
    )
    .nullable(),
    authorizedCertificate: Yup.mixed()
    .required(I18n.t("authCertificateError"))
    .test(
      "file-size",
      I18n.t("authCertificateError"),
      () => this.state.fileValidationStatus.authorizedCertificate
    )
    .nullable(),
    authorizedIDcard: Yup.mixed()
    .required(I18n.t("authIdCardError"))
    .test(
      "file-size",
      I18n.t("authIdCardError"),
      () => this.state.fileValidationStatus.authorizedIDcard
    )
    .nullable(),
    ibanCertificate: Yup.mixed()
    .required(I18n.t("ibanCertificateError"))
    .test(
      "file-size",
      I18n.t("ibanCertificateError"),
      () => this.state.fileValidationStatus.ibanCertificate
    )
    .nullable(),
    branches: Yup.array().of(
      Yup.object().shape({
        branch_name: Yup.string().required(I18n.t("branchNameError")),
        branch_code: Yup.string().required(I18n.t("branchCodeError")),
        location_link: Yup.string().required(I18n.t("branchLocationError")),
        mobile_number: Yup.string()
          .test('is-valid-phone', I18n.t("branchContactError"), function (value) {
            return isValidPhoneNumber(value || '');
          })
          .required(I18n.t("branchContactError")),
        business_hours: Yup.string().required(I18n.t("branchOperatingError")),
       
      })
    ).min(1, I18n.t("atLeastOneBranchReqError"))
  });
};

  filesValidation = (arrayVal: any[], file: File | null) => {
    const findValue = arrayVal.find((val: any) => val.url !== "")
    if (findValue) {
      const fileUrl = findValue.url
      return fileUrl
    } else {
      return file
    }
  }

  validateForm = async () => {
    const schema = this.FormuserSchema();
    const { iban_certificate_image, authorized_id_card_image, vat_tax_identifier_image, trading_license_image , authorization_certificate_image } = this.state.profileData.attributes
    schema
      .validate({
        email: this.state.profileData.attributes?.support_email,
        NationalId: this.state.profileData.attributes?.national_id, 
        VatRegNo: this.state.profileData.attributes?.vat_registration_number, 
        NationalAddress: this.state.profileData.attributes?.address,
        BusinessName: this.state.profileData.attributes?.business_name, 
        industryCat: this.state.industryCat, 
        businessDescription:this.state.profileData.attributes?.business_description,
        ibanCertificate: this.filesValidation(iban_certificate_image,this.state.ibanCertificateFile), 
        authorizedIDcard: this.filesValidation(authorized_id_card_image,this.state.authorizedIDcardFile) , 
        VATtaxidentifire: this.filesValidation(vat_tax_identifier_image, this.state.VATtaxidentifireFile), 
        TradingLicense: this.filesValidation(trading_license_image, this.state.TradingLicenseFile), 
        authorizedCertificate: this.filesValidation(authorization_certificate_image, this.state.authorizedCertificateFile), 
        branchSelection: this.state.branchSelection,
        branchName: this.state.profileData.attributes?.branches?.branch_name,
        branchCode:this.state.profileData.attributes?.branches?.branch_code,
        branchLOcation: this.state.profileData.attributes?.branches?.location_link, 
        branchContactNo: this.state.profileData.attributes?.branches?.mobile_number,
        branchOperatingHours: this.state.profileData.attributes.branches?.business_hours, 
        branches: this.state.profileData.attributes?.branches,
      }, { abortEarly: false })
      .then(() => {
        this.updateUserProfile();
        this.onPostBranch()
        this.deleteBranchFromServer(this.state.deleteBranchId)
        this.updateBranchDetails();

      })
      .catch((validationError) => {
        this.setState({
          successError: validationError,dialogOpen: true
        });
        const newErrors: Record<string, string> = {};
        validationError.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        
        this.setState({
          errors: newErrors,
        });
      });
  };

  updateBranchDetails = () => {
    const header = {
      token: localStorage.getItem("token"), 
      "Content-Type": "application/json",
    };
  
    const updatedBranches = this.state.profileData.attributes.branches.filter(
      (branch: { id: number }) => this.state.updatedBranchesIds.has(branch.id)
    );
  
    if (updatedBranches.length === 0) {
      return;
    }
  
    const payload = {
      branches: updatedBranches.map(
        (branch: {
          id: number;
          branch_name: string;
          branch_code: string;
          business_hours: string;
          location_link: string;
          mobile_number: string;
          
        }) => ({
          id: branch.id,
          branch_name: branch.branch_name, 
          branch_code: branch.branch_code, 
          branch_operating_hours: branch.business_hours, 
          branch_location: branch.location_link, 
          branch_contact_number: branch.mobile_number, 
          
        })
      ),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/profile/branches`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH" 
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    this.setState({ updatedBranchesIds: new Set<number>() });
  };
  

handleSubmit = (e: any) => {
    e.preventDefault()
    this.validateForm();

  }

  handleNumberPhone = (e: any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.setState({ phoneNumber: e, errorMessagePhoneNumber: "" });
    } else {
      this.setState({
        phoneNumber: e,
        errorMessagePhoneNumber: "Invalid Phone Number",
      });
    }
  };



  handleFileInputClick = () => {
    this.state.fileInput.current?.click();
  };
  handleFileInputClick1 = () => {
    this.state.fileInput1.current?.click();
  };
  handleFileInputClick2 = () => {
    this.state.fileInput2.current?.click();
  };
  handleFileInputClick3 = () => {
    this.state.fileInput3.current?.click();
  };
  handleFileInputClick4 = () => {
    this.state.fileInput4.current?.click();
  };
  handleOtherFileInputClick = () => {
    this.state.otherFileInput.current?.click();
  };

handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files && event.target.files[0];
  if (file) {
      this.setState((prevState) => ({
          profileData: {
              ...prevState.profileData,
              attributes: {
                  ...prevState.profileData.attributes,
                  iban_certificate_image: [{ name: file.name, size:file.size, url: "" }]
              }
          }
      }));
      this.setState({ 
        ibanCertificateFile: file,
        fileValidationStatus: {
          ...this.state.fileValidationStatus,
          ibanCertificate: file.size <= 5 * 1024 * 1024,
        },
      });
      if (file.size <= 5 * 1024 * 1024) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            ibanCertificate: null, 
          },
        }));
      }
  }
};

  OtherhandleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
        this.setState((prevState) => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    other_document_image: [{ name: fileObj.name, url: "" }]
                }
            }
        }));
        this.setState({ InputFileOther: fileObj });
    }
};

handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files && event.target.files[0];
  if (file) {
      this.setState((prevState) => ({
          profileData: {
              ...prevState.profileData,
              attributes: {
                  ...prevState.profileData.attributes,
                  authorized_id_card_image: [{ name: file.name, size:file.size, url: "" }]
              }
          }
      }));
      this.setState({ 
        authorizedIDcardFile: file,
        fileValidationStatus: {
          ...this.state.fileValidationStatus,
          authorizedIDcard: file.size <= 5 * 1024 * 1024,
        }, 
      });
      if (file.size <= 5 * 1024 * 1024) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            authorizedIDcard: null, 
          },
        }));
      }
  }
};

typeChange(status1:any) {
  switch (status1) {
    case 'success':
      return 'bg-success'; 
    case 'warning':
      return 'bg-warning'; 
    case 'error':
      return 'bg-error';
    default:
      return 'bg-default';
  }
}

handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files && event.target.files[0];
  if (file) {
      this.setState((prevState) => ({
          profileData: {
              ...prevState.profileData,
              attributes: {
                  ...prevState.profileData.attributes,
                  authorization_certificate_image: [{ name: file.name, size:file.size, url: "" }]
              }
          }
      }));
      this.setState({ 
        authorizedCertificateFile: file,
        fileValidationStatus: {
          ...this.state.fileValidationStatus,
          authorizedCertificate: file.size <= 5 * 1024 * 1024,
        },  
      });
      if (file.size <= 5 * 1024 * 1024) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            authorizedCertificate: null, 
          },
        }));
      }
  }
};

handleFileChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files && event.target.files[0];
  if (file) {
      this.setState((prevState) => ({
          profileData: {
              ...prevState.profileData,
              attributes: {
                  ...prevState.profileData.attributes,
                  vat_tax_identifier_image: [{ name: file.name, size:file.size, url: "" }]
              }
          }
      }));
      this.setState({ 
        VATtaxidentifireFile: file,
        fileValidationStatus: {
          ...this.state.fileValidationStatus,
          VATtaxidentifire: file.size <= 5 * 1024 * 1024,
        },
       });
      if (file.size <= 5 * 1024 * 1024) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            VATtaxidentifire: null, 
          },
        }));
      }
  }
};

handleFileChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files && event.target.files[0];
  if (file) {
    this.setState((prevState) => ({
          profileData: {
              ...prevState.profileData,
              attributes: {
                  ...prevState.profileData.attributes,
                  trading_license_image: [{ name: file.name, size:file.size, url: "" }]
              }
          }
      }));
      this.setState({ 
        TradingLicenseFile: file, 
        fileValidationStatus: {
        ...this.state.fileValidationStatus,
        TradingLicense: file.size <= 5 * 1024 * 1024,
        } 
      });
      if (file.size <= 5 * 1024 * 1024) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            TradingLicense: null, 
          },
        }));
      }


  }
};

handleCategoryChange = (newValue: any) => {     
  
  if (newValue) {
    this.setState({ selectedCategory: newValue });
    this.setState({industryCat: newValue})
    this.setState({
      profileData: {
        ...this.state.profileData,
        attributes: {
          ...this.state.profileData.attributes,
          category: newValue.name,
        }
      }
    });
    this.onValueChange("category", newValue || '');
  } else {
    this.setState({ selectedCategory: null });
  }
};

  handleShowTime = (index: number) => {
    this.setState({ showMOdaltime: true, modalBranchIndex: index });
  };
  handleHideshowTime = () => {
    this.setState({ showMOdaltime: false, modalBranchIndex: null });
  };


  addBranch = () => {    
    const newBranch = {
      id: Date.now(),
      branchName: "",
      branchCode:"",
      branchLocation: "",
      branchContactNo: "",
      branchOperatingHours: "",
      selectedDays: [],
      startTime:{hours:"",minutes:"",period:"AM"},
      endTime:{hours:"",minutes:"",period:"AM"},
      newBranch: true
    };

    this.setState({ branches: [...this.state.branches, newBranch] });

    this.setState(prevState => ({
      profileData: {
          ...prevState.profileData,
          attributes: {
              ...prevState.profileData.attributes,
              branches:[...prevState.profileData.attributes.branches,
              {
                branch_name: newBranch.branchName,
                branch_code: newBranch.branchCode,
                location_link: newBranch.branchLocation,
                business_hours: newBranch.branchOperatingHours,
                mobile_number: newBranch.branchContactNo,
              }
              ]
          }
      }
    }))

  };

  deleteBranchFromServer = (branchDelId: string) => {
    const header = {
      token: localStorage.getItem("token"), 
      "Content-Type": "application/json"
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const endpoint = `bx_block_profile/profile/branch/${branchDelId}`;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE" 
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  

  deleteBranch = (delbtnindex: number) => {   
    const getBranches = this.state.profileData.attributes.branches.filter((_:any, index:any)=>(
      index !== delbtnindex
    ))

    const deletedBranch = this.state.profileData.attributes.branches.filter((_:any, index:any)=>(
      index == delbtnindex
    ))

    this.setState({deleteBranchId: deletedBranch[0].id})
    this.setState(prevState => ({
      profileData: {
        ...prevState.profileData,
        attributes: {
          ...prevState.profileData.attributes,
          branches: getBranches,
        },
      },
    }));
    
    
  };

  handlePeriodChange(event:any, branchId:any) {
    const updatedHour = event.target?.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches?.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch?.startTime,
                period: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }
  handleMinuteChange (minutesValue : any, branchId:any) {
    const updatedHour = minutesValue;
    
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch?.startTime,
                minutes: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  handleHourChange(value: any, branchId: any) {
    const updatedHour = value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...(branch.startTime || { hours: ""}),
                hours: updatedHour,
              },
            }
          : branch
      )}},
    }));

  }

  handlePeriodChangeEnd(event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches?.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch?.endTime,
                period: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  handleMinuteChangeEnd (event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch?.endTime,
                minutes: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  handleHourChangeEnd(event: any, branchId: any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...(branch.endTime || { hours: ""}),
                hours: updatedHour,
              },
            }
          : branch
      )}},
    }));
    
  }

  formatTime = (flag:string, time:any) => {
    const hours = time?.hours?.toString().padStart(2, '0') || "00";
    const minutes = time?.minutes ? `:${time.minutes.toString().padStart(2, '0')}` : ":00";
    const period = time?.period || (flag === '0' ? "AM" : "PM");
    return `${hours}${minutes} ${period}`;
  };

  onHandleSubmitTime =(branchId:string, updatedBusinessHours : string) => {
    const { modalBranchIndex } = this.state;
    const localBranch = this.state.profileData.attributes.branches[branchId]
    this.setState((prevState) => {
        const updatedBranches = [...prevState.profileData.attributes.branches];
        
        const start = this.formatTime('0', localBranch?.startTime);
        const end = this.formatTime('1', localBranch?.endTime);
  
        const businessHours = `From ${start} To ${end}`;
  
        updatedBranches[modalBranchIndex] = {
            ...updatedBranches[modalBranchIndex],
            business_hours: updatedBusinessHours,
        };
  
        const updatedBranchIdsSet = new Set<number>(prevState.updatedBranchesIds || []);
        const branchId = updatedBranches[modalBranchIndex]?.id;
  
        if (typeof branchId === "number") {
          updatedBranchIdsSet.add(branchId);
        }
  
        return {
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    branches: updatedBranches,
                },
            },
            updatedBranchesIds: updatedBranchIdsSet,
            showMOdaltime: false,
        };
    });
  }

  handleCheckboxChange = (index:number, day:string, isChecked:any) => {
    this.setState((prevState) => {
      const updatedBranches = [...prevState.branches];
      let branch = updatedBranches[index];
  
      if (branch) {
        branch.selectedDays = branch.selectedDays || [];
  
        if (isChecked) {
          branch.selectedDays = [...branch.selectedDays, day];
        } else {
          branch.selectedDays = branch.selectedDays.filter((selectedDay: string) => selectedDay !== day);
        }
  
        updatedBranches[index] = branch;
      }
  
      return { branches: updatedBranches };
    });
  };

  branchValueReset=()=>{
    if(this.state.branchSelection=="0")
      {
        this.setState({branches:  [
          {
            id: Date.now(),
            branchName: "",
            branchLocation: "",
            branchContactNo: "",
            branchOperatingHours: "",
            selectedDays: [],
            startTime:{hours:"",minutes:"",period:"AM"},
            endTime:{hours:"",minutes:"",period:"AM"},
          },
        ],})
      }
  }

  navigateEducationalUserProfile = () => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EducationalUserProfile"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMessage)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.branchSelection !=this.state.branchSelection){
      this.branchValueReset()
    };
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false,
      successError: false,
    })
  }

  nationalError(field: string) {
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  vatRegNo (field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  nationalAddress(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  supportEmail(field:string){
    return this.state.errors[field]
    ? this.getErrorMessageNonFormik(this.state.errors[field])
    : null;
  }

  businessName(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  tradingLicense(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  vattaxidentifire(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  authorizedCertificate(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  authorizedIDcard(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  ibanCertificate(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  industryCat(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  businessDescription(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  branchSelection(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  branchNameError(fieldPath: string) {
    const error = this.state.errors[fieldPath];    
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchCodeError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchLocationLinkError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchContanctNoError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchOperatingHoursError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  getCoverImageSrc() {
    return this.state.coverImg
      ? URL.createObjectURL(this.state.coverImg)
      : (this.state.profileData.attributes?.banner_image_image as { url: string; name: string }[] | null)?.[0]?.url
  }


  getUploadImageSrc() {
    return this.state.uploadImg != null
      ? URL.createObjectURL(this.state.uploadImg)
      : (this.state.profileData.attributes?.photo_image as { url: string; name: string }[] | null)?.[0]?.url
  }

  handleBranchSelectionChange(newValue: any) {
    this.onValueChange("branch_selection", newValue);
    this.setState({ branchSelection: newValue });

  }


  handleStatusChangeD(status:any) {
    switch (status) {
      case 'success':
        return 'bg-success'; 
      case 'warning':
        return 'bg-warning'; 
      case 'error':
        return 'bg-error'; 
      case 'green':
        return "go";
      case 'orange':
        return "stay";
      default:
        return 'bg-default';
    }
  }
   
  // Customizable Area End

}
