import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
  IconButton
} from "@material-ui/core";
import { backGroundImg, back, otpForgot, helpTitle, arBackButton } from "./assets";
import OtpInput from 'react-otp-input';
import { I18n } from "../../../components/src/languageJson/i18n";
import LanguageIcon from "@material-ui/icons/Language";
import { getLanguageStyles } from "../../../components/src/LanguageStyles.web";
import LanguageSwitcher from "../../../components/src/LanguageSwitcher.web";
import MultiLanguagePhoneInput from "../../../components/src/MultiLanguagePhoneInput.web";

const ErrorText = styled(Typography)({
  marginTop: "2px",
  fontSize: "14px",
  color: "rgb(249, 75, 75)",
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
});

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});

const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  minHeight: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "145px", left: "-68px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
});

const BoxWidth = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding:"20px",
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
});

const BoxWidth100 = styled(Box)({
  width: "100%",
  "@media (min-width:410px) and (max-width:600px)": {
    width: "80%"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "75%"
  }
});


const GridContainer = styled(Grid)({
  width: "80vw",
  height: "100vh",
  '@media (max-width:1020px)': {
    justifyContent: "center",
  },

});


// Customizable Area End


import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";



export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  ErrorMsg = styled(Box)({
    borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
    borderRight: this.getValueBasedOnLanguage("4px solid red", ""),
    padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px",
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
  });

    styles: { [key: string]: React.CSSProperties } = {
    ...getLanguageStyles(this.getValueBasedOnLanguage),
    otpBox:{
      display:"flex",
      flexDirection:"column",
      gap:"20px"
    },
    heading: {
      fontSize: "48px ",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 700,
      lineHeight: "56px ",
      marginBottom: "24px ",
      marginTop: "24px",
      cursor: "pointer",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    headingForgot: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px ",
      color: "#000000",
    },
    backArroe: {
      maxWidth: "3.5rem", cursor: "pointer", marginLeft: "5rem", padding: "1rem"
    },
    apiForgotSuccess: {
      borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
      borderRight: this.getValueBasedOnLanguage("4px solid red", ""),
      padding: "12px",
      backgroundColor: "#D1FAE5",
      marginBottom: "24px",
    },
    apiForgotFalied: {
      borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
      borderRight: this.getValueBasedOnLanguage("4px solid red", ""),
      padding: "12px",
      backgroundColor: "#FEE2E2",
      marginBottom: "24px",
    },
    buttonStyle: {
      background: "#527FE2",
      height: "64px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
      color: "white",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "20px ",
      fontWeight: 600,
      lineHeight: "24px",
      cursor: "pointer",
      textTransform: "none",
    },
    accountStyleBold: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px ",
      cursor: "pointer",
      lineHeight: "24px",
      color: "#527FE2",
    },
    modal: {
      position:
        'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: 350,
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: 24,
  
    },
    otpButton: {
      background:"#527FE2",
      width: "100%",
      height: "61px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
      color: "white",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px ",
      fontWeight: 600,
      lineHeight: "24px",
      cursor: "pointer",
      whiteSpace:"nowrap",
      textTransform: "capitalize",
    },
    resendText: {
      marginTop: '40px',
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "18px ",
      lineHeight: "26px",
    },
    ResendStyle: {
      fontWeight: 700,
      color: "#527FE2",
      cursor: 'pointer',
    },
  
    otpError: {
      fontWeight: '400',
      color: '#DC2626',
      width: '100%',
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center'
    },
    paper: {
      width: "100%",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: "30px",
      padding: "30px"
  
    },
    inputBox: {
      display: 'flex',
      justifyContent: 'center',
      gap: "24px"
    },
    otpInputt: {
      width: 73,
      height: 73,
      textAlign: 'center',
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      direction:this.getValueBasedOnLanguage("rtl", "ltr")
    },
    submitButton: {
      width: '488px',
      backgroundColor: "#527FE2",
      textTransform: "capitalize",
      borderRadius: "8px",
      color: "#FFFFFF",
      fontSize: "20px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      lineHeight: "26px",
      fontWeight: 600
  
    },
    resendLink: {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: "18px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      color: "#0F172A",
      lineHeight: "26px"
    },
    resended: {
      cursor: 'pointer',
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      color: "#527FE2",
      lineHeight: "26px",
      textDecoration: "none",
    },
  };

  webStyle = {
    boxTitle: {
      display: "flex",
      justifyContent: "center", 
      height: "100%"
    },
    subBox: {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "64px", 
      width: "100%", 
      margin:  this.getValueBasedOnLanguage("auto 110px auto 0", "auto"),
      textAlign: "center" as "center"
    },  
    helpTitle: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "58px", 
      lineHeight: "24px", 
      fontWeight: 600, 
      color: "#FFFFFF",
      whiteSpace: "nowrap"
    },
    helpSubTitle: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "30px",
      lineHeight: "48px", 
      fontWeight: 400, 
      color: "#FFFFFF", 
    },
    imgTit: {
      height: "74px",
      width: "183px", textAlign: "center" as "center"
    },
    sign: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "30px ",
      alignItems: "center",
      fontWeight: 700,
      justifyContent: "center",
      lineHeight: "40px",
      color: "#000000",
      marginTop: "24px",
      marginBottom: "24px",
    },
  }

  SubmitBtn = styled(Button)({
    background: "#527FE2",
    height: "61px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "#FFF",
    marginTop: "48px",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontSize: "20px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    width: "100%",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
  });

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: 'flex', width: '100vw' }}>
        <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGroundImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>
        {this.state.currentLanguage === "en" && 
          <BackBox>
            <img style={{ height: "100", width: "100%" }} src={back} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoLogin} />
          </BackBox>
        }
          <GridContainer container>
            <LeftGrid  item
              lg={6} md={4}
            >
              <Box style={this.webStyle.boxTitle}>
                <Box style={this.webStyle.subBox}>
                  <Box 
                   style={{
                    display: "flex", alignItems: "center", 
                    gap: "16px", flexWrap: "wrap" as "wrap",
                    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
                   }}
                   >
                    <Typography style={{...this.webStyle.helpTitle,
                       fontSize: '64px',
                    }}>
                      {I18n.t("welcometo")}
                    </Typography>
                    <img src={helpTitle} style={this.webStyle.imgTit} />
                  </Box>
                  <Typography style={{  ...this.webStyle.helpSubTitle,
                     fontSize: '32px',
                     textAlign:this.getValueBasedOnLanguage("right", "left")
                  }}> {I18n.t("loginTitle")}
                  </Typography>
                </Box>
              </Box>
            </LeftGrid>
            <Grid  item
              lg={6} md={8}
              sm={12}  xs={12}
              style={{
                display: "flex", 
                width: "100%",
                 height: "100%",
                justifyContent: "center",
              }}
            >
              {!this.state.otpModal ? (
                <Box 
                  style={{ 
                    maxWidth: "100%", 
                    overflow: "hidden" ,
                    marginTop: "100px",
                  }}>
                  <Box
                    style={{ height: "131px", backgroundColor: "#000000", borderTopLeftRadius: "45px", borderTopRightRadius: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box style={{ height: "74px", width: "183px" }}>
                      <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                    </Box>

                  </Box>
                  <BoxWidth647>
                    <BoxWidth>
                      <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px"}}>
                        <Typography style={this.styles.heading}> {I18n.t("forgotPasswordTitle")}</Typography> 
                        <Typography style={this.styles.headingForgot}> {I18n.t("forgotSubtitle")}</Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <BoxWidth100>

                          <MultiLanguagePhoneInput
                            placeholder={I18n.t("phoneNumber")}
                            data-test-id="phoneTestID"
                            inputValue={this.state.phoneNumber}
                            onChange={this.handlePhoneNumber}
                            errorProps={this.state.apiResponseMessage ? true : false}
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                              paddingLeft: this.getValueBasedOnLanguage("0", "10px"),
                            }}
                          />
                          {
                            this.state.apiResponseMessage && (
                              <ErrorText
                                className="error-text"
                                style={{
                                  marginBottom:"20px",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                                >
                                  {this.state.apiResponseMessage}
                                </ErrorText>
                              )
                          }
                          <this.SubmitBtn
                            variant="contained"
                            data-test-id="nextBtnTestId"
                            onClick={this.handleNumberError}
                          >
                            {I18n.t("submit")}
                          </this.SubmitBtn>

                          <LanguageSwitcher
                            currentLanguage={this.state.currentLanguage}
                            changeLanguage={this.changeLanguage}
                            getValueBasedOnLanguage={this.getValueBasedOnLanguage}
                          />
                        </BoxWidth100>
                      </Box>
                    </BoxWidth>
                  </BoxWidth647>
                </Box>
              ) : (
                <BoxPaper>
                  <Paper elevation={3} style={this.styles.paper}>
                    <Box style={{ height: "122px", width: "160px" }}>
                      <img src={otpForgot} style={{ height: "100%", width: "100%" }} />
                    </Box>
                    <Box style={this.styles.otpBox}>
                      <Typography
                       gutterBottom
                        style={{
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: "30px",
                          fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          color: "#0F172A",
                          lineHeight: "40px",
                        }}
                      >
                        {I18n.t("enterOtpCode")}
                      </Typography>
                      <OtpInput
                        value={this.state.forgotOTPvalue}
                        inputType="tel"
                        onChange={this.handleOtpChange}
                        containerStyle={{ outline: "none", gap: "24px" }}
                        data-test-id="otpinput"
                        numInputs={4}
                        renderInput={(props) => <input {...props} placeholder="-"
                          // style={{ ...props.style, borderColor: this.state.otpError ? 'red' : '#CBD5E1',}}
                          disabled />}
                        inputStyle={this.styles.otpInputt}
                      />
                    </Box>
                    <Box style={{width:"100%"}}>
                      <Button
                        variant="contained"
                        style={this.styles.otpButton}
                        onClick={this.handleVerifyOTP}
                        data-test-id="nextBtnTestIdotp"
                      // onClick={() => this.handleAllValidation()}
                      >
                        {I18n.t("submit")}
                      </Button>
                    </Box>
                    <Box>
                      <Typography variant="body2" 
                        style={{
                          textAlign: 'center',
                          fontWeight: 500,
                          fontSize: "18px",
                          fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          color: "#0F172A",
                          lineHeight: "26px",
                          display:"flex",
                          gap:'5px'
                        }}
                        >
                        <span onClick={this.handleSendOTP} style={this.styles.resended}>{I18n.t("resend")}</span>
                        {I18n.t("code")} 
                      </Typography>
                    </Box>
                  </Paper>
                </BoxPaper>
              )}
            </Grid>
          </GridContainer>
          {this.state.currentLanguage === "ar" && 
          <BackBox style={{left:'50px', top:'110px'}}>
            <img style={{ height: "100", width: "100%" }} 
              src={arBackButton} alt="backButton..." data-test-id="NavigatetoLogin" 
              onClick={this.NavigatetoLogin} 
            />
          </BackBox>
          }
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const InputField = styled(TextField)({
  minWidth: "485px",
  marginBottom: "24px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },  
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:  "#CBD5E1", 
    borderWidth: "1px" ,
    borderRadius:  "8px" ,
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor:"#CBD5E1" ,
    borderWidth:"1px" ,
  },
  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
  "@media (max-width:990px)": {
    minWidth:"unset",
    maxWidth:"unset",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
});

const ImageGrid = styled(Box)({

  "@media (max-width:1050px )": {
    display: "none",
  },
});

const BoxPaper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  height: '600px',
  width: "600px",
  textAlign: "center",
  margin:"auto",
  "@media (max-width:1280px)":{
    height: '520px',
    width: "500px",
  },
  "@media (max-width:410px)":{
    width: "280px",
    height: '500px',
  },
})

const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },

  fontFamily: "Poppins",
  fontSize: "56px ",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  marginBottom: "9px ",
  color: "#0F172A",


});



const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },

  display: "flex",
  justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ",
  fontSize: "22px ",
  fontWeight: 400,
  color: "#0F172A",
  marginBottom: "64px",


});

// Customizable Area End
