import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import moment from "moment";
import { truthyValue } from "../../../components/src/constant";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isClearClicked:boolean;
  isApplyClicked:boolean;
  currentLanguage : string;
  serchInput:string;
  isLoading: boolean;
  filterModalOpen: boolean;
  selectedDate: any;
  selectedUpdateDate: any;
  startFlag: boolean;
  updateFlag: boolean;
  noServiceMsg: string;
  successDialog: boolean;
  deleteId: number;
  successUpdateDialog: boolean;
  isModalOpen: boolean,
  isEditSuccessModalOpen:boolean,
  isEditFailModalOpen:boolean,
  selectedServiceId:string,
  serviceDetails: {
    service: string,
    price: string,
    creationDate: string,
  },
  page: number;
  setCount:number;
  errorModal : boolean,
  messageInfo : {
    msg_en : string,
    msg_ar : string
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  deleteServiceApiCallId:string="";
  searchServiceApiCallId:string="";
  getProductApiCallId: any;
  updateServiceApiCallId:string='';
  timeout: any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isClearClicked:false,
      isApplyClicked:false,
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
      arrayHolder: [],
      token: "",
      serchInput:"",
      isLoading: true,
      filterModalOpen: false,
      selectedDate: new Date(),
      selectedUpdateDate: new Date(),
      startFlag: false,
      updateFlag: false,
      noServiceMsg: "",
      successDialog: false,
      deleteId: 0,
      successUpdateDialog: false,
      isModalOpen: false,
      isEditSuccessModalOpen:false,
      isEditFailModalOpen:false,
      selectedServiceId:'',
      serviceDetails: {
        service: '',
        price: '',
        creationDate: '',
      },
      page: 1,
      setCount: 0,
      errorModal: false,
      messageInfo : {
        msg_ar : "",
        msg_en : ""
      }
        // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
      // Customizable Area Start
    super.componentDidMount();
    let tokenvalue = await getStorageData('token')
    this.getListRequest();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
  // Customizable Area End

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
   // Customizable Area Start

  goToAddService=()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddService'
    );

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(msg);

    
  }

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  toDateFromMoment(value: any) {
    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
  }
  getListRequest = async () => {

    let tokenvalue = await getStorageData('token');
    const queryParam = this.state.serchInput ? `query=${this.state.serchInput}` : '';
    const perPage = `page=${this.state.page}&per_page=12`;
    const startDate = this.state.startFlag &&`created_at=${this.toDateFromMoment(this.state.selectedDate)}`;
    const updateDate = this.state.updateFlag && `updated_at=${this.toDateFromMoment(this.state.selectedUpdateDate)}`;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}?${perPage}&${startDate}&${updateDate}&${queryParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteService = async (id: number) => {
    let tokenvalue = await getStorageData('token')
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editServicePatch = async() => {
    let tokenvalue = await getStorageData('token')
    const header = {
      token:tokenvalue,
    };
    const formData:any = new FormData();
    formData.append("name", this.state.serviceDetails.service);
    formData.append("price", this.state.serviceDetails.price);
    formData.append("created_at", this.state.serviceDetails.creationDate);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateServiceApiCallId = requestMessage.messageId;
    
    const endPoint = `bx_block_catalogue/catalogues/${this.state.selectedServiceId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  };

  handleSearchInput=(event:any)=>{

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ serchInput:event.target.value, 
        page:this.state.page 
      }, () => {
        this.getListRequest();
      });
    }, 500);
    this.setState({ serchInput: event.target.value, 
      page:this.state.page
     });

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      this.getServiceResp(apiRequestCallId,responseJson,errorReponse)
      this.deleteServiceResp(apiRequestCallId,responseJson) 
    }

    
    // Customizable Area End
  }

  getServiceResp = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.getProductApiCallId) {
       this.handleGetServiceAPIResponse(responseJson,errorReponse)
    }

    if (apiRequestCallId === this.updateServiceApiCallId) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({isEditSuccessModalOpen:true})
        this.getListRequest()
      } else {
        this.setState({isEditFailModalOpen:true,})
      }
    }
  }

  deleteServiceResp = (apiRequestCallId:any,responseJson:any) => {
    if (apiRequestCallId === this.deleteServiceApiCallId) {
      if (responseJson && responseJson.message) {
        this.getListRequest();
        this.setState({ successDialog: false, successUpdateDialog: true})
      } else {
        this.setState({ successDialog: false})
        this.openErrorModal()
      }
    }
  }

  handleGetServiceAPIResponse = (responseJson : any,errorReponse: any ) => {
    if (responseJson && !responseJson.errors && responseJson.data ) {
      this.setState({ arrayHolder: responseJson.data, isLoading: false, filterModalOpen:false, setCount: responseJson?.total_count});
      runEngine.debugLog("arrayHolder", this.state.arrayHolder);
    } else if( responseJson && responseJson.message && responseJson.message_ar) {
       const msgEn = truthyValue(responseJson.message)
       const msgAr = truthyValue(responseJson.message)
       this.setState({ messageInfo : { msg_ar: msgAr, msg_en : msgEn}, isLoading: false, arrayHolder: []})
    } else if( responseJson && responseJson.message && responseJson.message.en) {
      const messageEn = truthyValue(responseJson.message.en)
      const messageAr = truthyValue(responseJson.message.ar)
      this.setState({ messageInfo : { msg_ar: messageAr, msg_en : messageEn}, isLoading: false, arrayHolder: []})
   }
    else {
      this.setState({ arrayHolder: [], noServiceMsg: responseJson.message && responseJson.message.en ? responseJson.message.en :responseJson.message , isLoading: false})
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleClose = () => {
    this.setState({ filterModalOpen: false})
  };

  openFilter = () => {
    this.setState({ filterModalOpen: true })
  }

  handleDateChange = (date:any) => {
    this.setState({ selectedDate: date, startFlag: true });
  };

  handleUpdateDateChange = (date:any) => {
    this.setState({ selectedUpdateDate: date, updateFlag: true });
  };

  applyFilter = () => {
    this.getListRequest();
  }

  clearFilter = () => {
    this.setState({startFlag: false, updateFlag: false, selectedDate: new Date(), selectedUpdateDate: new Date()})
  }

  successDeleteOpen = (id:number) => {
    this.setState({ successDialog: true, deleteId: id})
  }

  successDelete = () => {
    this.setState({ successDialog: false, })
  }

  deleteEmployeeCall = () => {
    this.DeleteService(this.state.deleteId);
  }

  editService = (id:number) => {
    this.props.navigation.navigate("AddService", { id : id})
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleSave = (updatedDetails:any) => {
    this.setState({ 
      serviceDetails: updatedDetails,
    }, () => {
      this.editServicePatch()
    });
  };

  handleEditSuccessClose = () => {
    this.setState({ isEditSuccessModalOpen: false });
  };

  handleEditFailClose = () => {
    this.setState({ isEditFailModalOpen: false });
  };

  handleUpdateClose = () => {
    this.setState({ successUpdateDialog: false});
  };
  
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value})
      this.setState({ page: value }, () => {
        this.getListRequest();
      });
    this.setState({ page:value });
  };

  openErrorModal = () => {
    this.setState({ errorModal : true})
  }

  closeErrorModal = () => {
    this.setState({ errorModal : false})
  }

  // Customizable Area End
}
